import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, Tag, TrendingUp, LineChart, Bot, BadgeCheck, DollarSign, Percent, ArrowRight, BarChart2 } from 'lucide-react';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const PricingMetrics = () => (
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-12">
    {[
      { label: 'Margin Improvement', value: '+32%', icon: TrendingUp },
      { label: 'Revenue Growth', value: '+47%', icon: DollarSign },
      { label: 'Price Optimization', value: '93%', icon: Tag },
      { label: 'ROI Increase', value: '+58%', icon: LineChart }
    ].map((metric, i) => (
      <motion.div
        key={i}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: i * 0.1 }}
        className="relative group"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-all duration-300" />
        <div className="relative bg-gray-800/50 border border-gray-700/50 rounded-xl p-4">
          <metric.icon className="w-5 h-5 text-blue-400 mb-2" />
          <div className="text-2xl font-bold text-white">{metric.value}</div>
          <div className="text-sm text-gray-400">{metric.label}</div>
        </div>
      </motion.div>
    ))}
  </div>
);

const AnimatedGraph = () => (
  <motion.div 
    className="relative h-48 overflow-hidden"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.5 }}
  >
    {/* Price trend line */}
    <motion.div
      initial={{ height: '0%' }}
      animate={{ height: '100%' }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="absolute inset-0 flex items-end"
    >
      <svg className="w-full" viewBox="0 0 100 40">
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="#3B82F6" />
            <stop offset="100%" stopColor="#8B5CF6" />
          </linearGradient>
        </defs>
        <motion.path
          d="M0,35 Q25,32 50,25 T100,15"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="2"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2, ease: "easeInOut" }}
        />
      </svg>
    </motion.div>
  </motion.div>
);

const OptimizePricing = () => {
  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const strategies = [
    {
      title: "Dynamic Pricing AI",
      subtitle: "Real-time price optimization",
      icon: Tag,
      color: "from-blue-500 to-cyan-500",
      gradient: "from-blue-500/20 to-cyan-500/20",
      border: "border-blue-500/20",
      metrics: [
        { label: "Price Accuracy", value: "98%" },
        { label: "Market Response", value: "<2min" }
      ],
      features: [
        "Competitive analysis",
        "Demand forecasting",
        "Price elasticity modeling"
      ]
    },
    {
      title: "Smart Promotions",
      subtitle: "AI-driven campaign optimization",
      icon: Percent,
      color: "from-purple-500 to-pink-500",
      gradient: "from-purple-500/20 to-pink-500/20",
      border: "border-purple-500/20",
      metrics: [
        { label: "Campaign ROI", value: "+63%" },
        { label: "Conversion Rate", value: "+45%" }
      ],
      features: [
        "Promotion effectiveness",
        "Customer segmentation",
        "Timing optimization"
      ]
    },
    {
      title: "Margin Optimizer",
      subtitle: "Profit maximization engine",
      icon: TrendingUp,
      color: "from-emerald-500 to-teal-500",
      gradient: "from-emerald-500/20 to-teal-500/20",
      border: "border-emerald-500/20",
      metrics: [
        { label: "Margin Growth", value: "+28%" },
        { label: "Cost Reduction", value: "-15%" }
      ],
      features: [
        "Cost analysis",
        "Margin predictions",
        "Inventory optimization"
      ]
    }
  ];

  return (
    <section className="relative overflow-hidden py-20">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
      </div>

      <div className="relative container mx-auto px-4">
        {/* Header Section */}
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <motion.div 
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-blue-500/10 border border-blue-500/20 mb-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Bot className="w-5 h-5 text-blue-400" />
            <span className="text-blue-400 text-sm">AI-Powered Pricing</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Smart Pricing &
            </span>
            <br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Revenue Optimization
            </span>
          </h2>
        </motion.div>

        <PricingMetrics />

        {/* Success Story Card */}
        <motion.div 
          className="mb-16 bg-gray-900/50 backdrop-blur-xl border border-gray-800/50 rounded-2xl p-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="flex items-center gap-3 mb-6">
            <BadgeCheck className="w-6 h-6 text-emerald-400" />
            <h3 className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent">
              Success Story
            </h3>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <div className="space-y-4 text-gray-300">
                <p>A leading retailer transformed their pricing strategy with our AI engine:</p>
                <div className="space-y-3">
                  {[
                    { label: "Revenue Growth", value: "+47%", icon: LineChart },
                    { label: "Margin Improvement", value: "+32%", icon: BarChart2 },
                    { label: "Market Share", value: "+28%", icon: TrendingUp }
                  ].map((stat, i) => (
                    <div key={i} className="flex items-center gap-3">
                      <div className="p-2 rounded-lg bg-gray-800/50">
                        <stat.icon className="w-4 h-4 text-emerald-400" />
                      </div>
                      <div className="font-medium text-gray-300">{stat.label}</div>
                      <div className="text-emerald-400 font-bold">{stat.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <AnimatedGraph />
          </div>
        </motion.div>

        {/* Strategies Grid */}
        <div className="grid md:grid-cols-3 gap-6">
          {strategies.map((strategy, index) => (
            <motion.div
              key={strategy.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              <div className={`p-1 rounded-2xl bg-gradient-to-br ${strategy.gradient}`}>
                <div className="bg-gray-900/90 backdrop-blur-xl rounded-xl p-6">
                  <div className={`p-3 rounded-xl bg-gradient-to-br ${strategy.gradient} mb-4 w-fit`}>
                    <strategy.icon className="w-6 h-6 text-white" />
                  </div>

                  <h3 className="text-xl font-bold text-white mb-1">{strategy.title}</h3>
                  <p className="text-gray-400 text-sm mb-4">{strategy.subtitle}</p>

                  <div className="space-y-4 mb-6">
                    {strategy.metrics.map((metric, i) => (
                      <div key={i} className="flex justify-between items-center">
                        <span className="text-gray-400 text-sm">{metric.label}</span>
                        <span className="font-bold text-white">{metric.value}</span>
                      </div>
                    ))}
                  </div>

                  <div className="space-y-2">
                    {strategy.features.map((feature, i) => (
                      <div key={i} className="flex items-center gap-2 text-sm text-gray-400">
                        <div className="w-1 h-1 rounded-full bg-gray-600" />
                        {feature}
                      </div>
                    ))}
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`mt-6 w-full bg-gradient-to-r ${strategy.color} rounded-xl py-2 px-4 text-white font-medium flex items-center justify-center gap-2`}
                  >
                    Learn More
                    <ArrowRight className="w-4 h-4" />
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OptimizePricing;