import React from 'react';
import { motion } from 'framer-motion';
import { Bot, Zap, LineChart } from 'lucide-react';

const steps = [
  {
    icon: Bot,
    title: "Get AI Insights",
    description: "Experience the power of AI analytics tailored for your business",
    gradient: "from-blue-500/20 to-blue-600/20",
    border: "border-blue-500/20",
    iconColor: "text-blue-400",
  },
  {
    icon: Zap,
    title: "Quick Integration",
    description: "5-minute setup with your existing tools and platforms",
    gradient: "from-purple-500/20 to-purple-600/20",
    border: "border-purple-500/20",
    iconColor: "text-purple-400",
  },
  {
    icon: LineChart,
    title: "Track Growth",
    description: "Monitor real-time insights and optimizations",
    gradient: "from-emerald-500/20 to-emerald-600/20",
    border: "border-emerald-500/20",
    iconColor: "text-emerald-400",
  },
];

const GettingStarted = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 }
    }
  };

  return (
    <section className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-900 to-black relative overflow-hidden py-20">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="space-y-16"
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="text-center">
            <motion.div 
              className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 mb-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Bot className="w-4 h-4 text-blue-400" />
              <span className="text-blue-400 text-sm">Start in Minutes</span>
            </motion.div>

            <h2 className="text-5xl md:text-6xl font-bold">
              <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
                Three Steps to
              </span>
              <br />
              <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
                Smarter Analytics
              </span>
            </h2>
          </motion.div>

          {/* Steps Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.02,
                  transition: { type: "spring", stiffness: 400 }
                }}
              >
                <div className={`group relative p-8 bg-gradient-to-br ${step.gradient} backdrop-blur-xl border ${step.border} rounded-2xl h-full`}>
                  <div className="relative z-10 flex flex-col items-center text-center">
                    <div className={`mb-6 p-4 rounded-xl bg-gray-900/50 ${step.iconColor} group-hover:scale-110 transition-transform duration-300`}>
                      <step.icon className="w-8 h-8" />
                    </div>
                    <h3 className="text-2xl font-semibold mb-4 text-white group-hover:text-blue-400 transition-colors">
                      {step.title}
                    </h3>
                    <p className="text-gray-400">{step.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* CTA */}
          <motion.div 
            variants={itemVariants}
            className="text-center"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group relative px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl font-medium text-white inline-flex items-center gap-2 overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 translate-y-[100%] group-hover:translate-y-0 transition-transform" />
              <span className="relative">Get Started</span>
              <motion.div
                animate={{ x: [0, 4, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
                className="relative"
              >
                →
              </motion.div>
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default GettingStarted;