import React, {useCallback, useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';

const KPIContainerSkeleton = () => {
  const loadingStates = [
    {
      title: "Initializing Data Pipeline",
      messages: [
        "Connecting to analytics server...",
        "Preparing data streams...",
        "Validating connection..."
      ]
    },
    {
      title: "Processing Analytics",
      messages: [
        "Analyzing performance metrics...",
        "Computing insights...",
        "Generating recommendations..."
      ]
    },
    {
      title: "Finalizing Visualizations",
      messages: [
        "Building interactive charts...",
        "Optimizing display...",
        "Preparing final render..."
      ]
    }
  ];

  const [currentStateIndex, setCurrentStateIndex] = useState(0);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const updateIndices = useCallback(() => {
    setCurrentMessageIndex(prev => {
      if (prev === 2) {
        setCurrentStateIndex(prevState => {
          const nextState = prevState + 1;
          return nextState < loadingStates.length ? nextState : prevState;
        });
        return 0;
      }
      return prev + 1;
    });
  }, [loadingStates.length]);

  useEffect(() => {
    const messageInterval = setInterval(updateIndices, 2000);
    return () => clearInterval(messageInterval);
  }, [updateIndices]);

  const currentState = loadingStates[currentStateIndex];

  return (
      <motion.div
          className="p-3 border border-gray-600/40 rounded-lg w-full h-full bg-gray-900/50 backdrop-blur-sm"
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.5}}
      >
        <AnimatePresence mode="wait">
          <motion.div
              key={currentStateIndex}
              className="bg-gray-800/80 rounded-lg shadow-lg relative flex flex-col h-80 border border-gray-700/30"
              initial={{opacity: 0, scale: 0.95}}
              animate={{opacity: 1, scale: 1}}
              exit={{opacity: 0, scale: 0.95}}
              transition={{duration: 0.4}}
          >
            {/* Header with muted branding */}
            <div className="p-2 flex items-center justify-between border-b border-gray-700/30">
              <div className="flex items-center gap-2">
                <div className="flex items-center relative overflow-hidden">
                  <span className="text-blue-400/40 font-semibold text-lg">Jaanch</span>
                  <span className="text-gray-400/40 font-semibold text-lg">AI</span>
                  <div className="text-gray-500/30 text-xs ml-2">AI Powered Analytics ©</div>
                  <motion.div
                      className="absolute inset-0 bg-gradient-to-r from-gray-800/0 via-blue-400/10 to-gray-800/0"
                      animate={{x: ['-200%', '200%']}}
                      transition={{
                        repeat: Infinity,
                        duration: 2,
                        ease: 'linear',
                      }}
                  />
                </div>
              </div>
            </div>

            {/* Dynamic loading content */}
            <div className="flex-grow p-8 flex flex-col items-center justify-center">
              <div className="text-center space-y-8 max-w-md mx-auto">
                <motion.h3
                    className="text-gray-400/70 text-xl font-medium"
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.2}}
                >
                  {currentState.title}
                </motion.h3>

                <div className="h-8 relative">
                  <AnimatePresence mode="wait">
                    <motion.p
                        key={currentMessageIndex}
                        className="text-gray-500/50 text-sm absolute w-full text-center"
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -10}}
                        transition={{duration: 0.3}}
                    >
                      {currentState.messages[currentMessageIndex]}
                    </motion.p>
                  </AnimatePresence>
              </div>

                {/* Progress indicators */}
                <div className="space-y-4">
                  <div className="w-64 h-1 bg-gray-700/30 rounded-full mx-auto overflow-hidden">
                  <motion.div
                      className="h-full bg-blue-400/20 rounded-full"
                      animate={{
                        x: ['-100%', '0%'],
                      }}
                      transition={{
                        repeat: Infinity,
                        duration: 2,
                        ease: 'linear',
                      }}
                  />
                </div>

                  <div className="flex justify-center gap-2">
                    {loadingStates.map((_, index) => (
                        <div
                            key={index}
                            className={`w-1.5 h-1.5 rounded-full transition-colors duration-300 ${
                                index === currentStateIndex ? 'bg-blue-400/40' :
                                    index < currentStateIndex ? 'bg-blue-400/20' : 'bg-gray-600/40'
                            }`}
                        />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
  );
};

export default KPIContainerSkeleton;