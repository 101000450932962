import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {verifyAuth} from "../../utils/functions";
import {clearUser} from "../../redux/features/userSlice";
import {clearKpis} from "../../redux/features/kpiSlice";
import GradientSpinner from "../skeletons/GradientSpinner";

const PublicRoute = ({ children }) => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const { isAuthenticated } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { isAuthValid } = await verifyAuth();

        if (!isAuthValid && isAuthenticated) {
          dispatch(clearUser());
          dispatch(clearKpis());
        }

        setIsValid(isAuthValid);
        setIsVerifying(false);
      } catch (error) {
        console.error('Error during auth verification:', error);
        dispatch(clearUser());
        dispatch(clearKpis());
        setIsVerifying(false);
      }
    };

    checkAuth();
  }, [dispatch, isAuthenticated]);

  if (isVerifying) {
    return (
        <div className="fixed inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 flex items-center justify-center">
          <GradientSpinner size="lg"/>
        </div>
    )
  }

  if (isValid) {
    return <Navigate to="/dashboard" replace/>;
  }

  return children;
};

export default PublicRoute;