import {configureStore} from '@reduxjs/toolkit';
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import userReducer from './features/userSlice';
import kpiReducer from './features/kpiSlice';
import dateRangeReducer from './features/dateRangeSlice';

const userPersistConfig = {
    key: 'user',
    storage: storageSession,
    whitelist: ['user', 'isAuthenticated', 'organization']
};

const kpiPersistConfig = {
    key: 'kpi',
    storage: storageSession,
    whitelist: ['kpis', 'selectedKPIs']
};

const dateRangePersistConfig = {
    key: 'dateRange',
    storage: storageSession,
    whitelist: ['from', 'to', 'label', 'isRelative', 'relativeValue']
};

// Create persisted reducers separately
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedKpiReducer = persistReducer(kpiPersistConfig, kpiReducer);
const persistedDateRangeReducer = persistReducer(dateRangePersistConfig, dateRangeReducer);

export const store = configureStore({
    reducer: {
        user: persistedUserReducer,
        kpi: persistedKpiReducer,
        dateRange: persistedDateRangeReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ['kpi.kpis'],
            },
        }),
});

export const persistor = persistStore(store);