import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { 
  FaChartLine, 
  FaBoxes, 
  FaPercentage, 
  FaRobot, 
  FaBrain, 
  FaCode, 
  FaDatabase, 
  FaChartBar 
} from 'react-icons/fa';
import { Sparkles, ArrowRight } from 'lucide-react';

const solutions = [
  { 
    title: "Sales AI", 
    icon: FaChartLine, 
    color: "#3B82F6", 
    desc: "Predictive revenue insights",
    features: ["Revenue forecasting", "Sales optimization", "Customer analytics"]
  },
  { 
    title: "Inventory AI", 
    icon: FaBoxes, 
    color: "#10B981", 
    desc: "Smart stock management",
    features: ["Stock optimization", "Demand forecasting", "Supply chain analytics"]
  },
  { 
    title: "Pricing AI", 
    icon: FaPercentage, 
    color: "#8B5CF6", 
    desc: "Dynamic pricing optimization",
    features: ["Price optimization", "Competitor analysis", "Market trends"]
  },
  { 
    title: "Automation AI", 
    icon: FaRobot, 
    color: "#EF4444", 
    desc: "Workflow automation",
    features: ["Process automation", "Task optimization", "Workflow analytics"]
  },
  { 
    title: "Forecast AI", 
    icon: FaBrain, 
    color: "#F59E0B", 
    desc: "Market trend predictions",
    features: ["Market analysis", "Trend forecasting", "Growth predictions"]
  }
];

const features = [
  {
    icon: FaDatabase,
    title: "Real-time Analytics",
    description: "Get instant insights from your data streams"
  },
  {
    icon: FaChartBar,
    title: "Predictive Analysis",
    description: "Forecast trends and make data-driven decisions"
  },
  {
    icon: FaCode,
    title: "API Integration",
    description: "Seamlessly connect with your existing tools"
  }
];

const HeroBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) controls.start('visible');
    
    if (!isHovered) {
      const interval = setInterval(() => {
        setActiveIndex(prev => (prev + 1) % solutions.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [controls, inView, isHovered]);

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    tap: {
      scale: 0.95
    }
  };

  return (
    <section className="relative min-h-screen overflow-hidden bg-gradient-to-br from-gray-900 to-black">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <div className="container relative z-10 mx-auto px-4 pt-32 pb-20">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          {/* Badge */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="mb-8 inline-flex items-center gap-2 rounded-full bg-gradient-to-r from-blue-500/10 to-purple-500/10 px-4 py-2 backdrop-blur-sm"
          >
            <Sparkles className="h-4 w-4 text-blue-400" />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              AI-Powered Analytics Platform
            </span>
          </motion.div>

          {/* Main Heading */}
          <motion.h1 
            className="mb-6 bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-5xl font-bold leading-tight text-transparent sm:text-6xl md:text-7xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Transform Your
            <span className="block bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
              Business Intelligence
            </span>
          </motion.h1>

          {/* Description */}
          <motion.p 
            className="mx-auto mb-12 max-w-2xl text-lg text-gray-400"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            Make data-driven decisions with our AI-powered analytics platform. 
            Get real-time insights, predictive analysis, and automated reporting.
          </motion.p>

          {/* CTA Buttons */}
          <motion.div 
            className="mb-20 flex flex-col sm:flex-row justify-center gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              className="group relative overflow-hidden rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 px-8 py-3 text-white shadow-lg transition-all hover:shadow-purple-500/25"
            >
              <div className="absolute inset-0 bg-white/10 opacity-0 transition-opacity group-hover:opacity-100" />
              <span className="flex items-center gap-2">
                Get Started Now
                <ArrowRight className="h-4 w-4 transition-transform group-hover:translate-x-1" />
              </span>
            </motion.button>

            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
              className="group rounded-xl border border-gray-700 bg-gray-800/50 px-8 py-3 text-gray-300 backdrop-blur-sm transition-all hover:border-purple-500/50 hover:bg-gray-800"
            >
              Watch Demo
            </motion.button>
          </motion.div>
        </motion.div>

        {/* Solutions Showcase */}
        <motion.div 
          className="relative mx-auto h-96 max-w-3xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* Background glow */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-72 w-72 animate-pulse rounded-full bg-gradient-to-br from-blue-500/20 to-purple-600/20 blur-3xl" />
          </div>

          {/* Solutions circle */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="relative h-64 w-64">
              {solutions.map((solution, index) => (
                <motion.div
                  key={solution.title}
                  className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform transition-all duration-500 ${
                    index === activeIndex ? 'scale-100 opacity-100' : 'scale-90 opacity-0'
                  }`}
                  style={{
                    transform: `rotate(${(index - activeIndex) * 72}deg) translateY(-120px) translateX(-50%)`,
                  }}
                >
                  <div className="flex flex-col items-center">
                    <div 
                      className={`mb-3 rounded-xl bg-gradient-to-br p-4 group cursor-pointer hover:scale-110 transition-transform`} 
                      style={{ backgroundColor: `${solution.color}20` }}
                      onClick={() => setActiveIndex(index)}
                    >
                      <solution.icon className="h-8 w-8" style={{ color: solution.color }} />
                    </div>
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="text-center w-32"
                    >
                      <h3 className="text-lg font-semibold text-white">{solution.title}</h3>
                      <p className="text-sm text-gray-400">{solution.desc}</p>
                    </motion.div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>

        {/* Features Grid */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
        >
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 + index * 0.1 }}
              className="group rounded-2xl bg-gray-800/50 p-6 backdrop-blur-sm hover:bg-gray-800/80"
            >
              <div className="mb-4 inline-block rounded-xl bg-blue-500/10 p-3 text-blue-400 group-hover:bg-blue-500/20">
                <feature.icon className="h-6 w-6" />
              </div>
              <h3 className="mb-2 text-lg font-semibold text-white">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default HeroBanner;