import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {formatLargeNumber} from "../../utils/functions";

const ProductGroupsChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('market_share_percentage');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  const metrics = [
    {
      key: 'market_share_percentage',
      name: 'Market Share %',
      color: '#2563eb',
      format: (value) => `${value.toFixed(2)}%`
    },
    {
      key: 'total_revenue',
      name: 'Total Revenue (Lacs)',
      color: '#16a34a',
      format: (value) => `₹${(value).toFixed(2)}L`
    },
    {
      key: 'avg_price_per_unit',
      name: 'Avg Price/Unit',
      color: '#7c3aed',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'units_sold',
      name: 'Units Sold',
      color: '#ea580c',
      format: (value) => value.toLocaleString()
    }
  ];

  // Process and sort data
  const processedData = [...data]
      .map(group => ({
        ...group,
        total_revenue: group.total_revenue / 100000,
      }))
    .sort((a, b) => b[activeMetric] - a[activeMetric])
    .slice(0, 12);

  // Calculate summary statistics
  const summaryStats = {
    totalRevenue: data.reduce((sum, item) => sum + item.total_revenue, 0),
    totalUnits: data.reduce((sum, item) => sum + item.units_sold, 0),
    totalProducts: data.reduce((sum, item) => sum + item.unique_products, 0)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const groupData = processedData.find(d => d.product_group === label);
      if (!groupData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{groupData.product_group}</p>
          <div className="space-y-1">
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(groupData[metric.key])}
              </p>
            ))}
            <div className="border-t border-gray-200 mt-2 pt-2">
              <p className="text-sm text-gray-600">
                Avg Transaction: ₹{groupData.avg_transaction_value.toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                Sales Count: {groupData.number_of_sales.toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                Unique Products: {groupData.unique_products.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Total Revenue</h3>
          <p className="text-lg font-bold text-blue-600">
            {formatLargeNumber(summaryStats.totalRevenue)}
          </p>
        </div>
        <div className="bg-green-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-green-800">Total Units Sold</h3>
          <p className="text-lg font-bold text-green-600">
            {summaryStats.totalUnits.toLocaleString()}
          </p>
        </div>
        <div className="bg-purple-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-purple-800">Unique Products</h3>
          <p className="text-lg font-bold text-purple-600">
            {summaryStats.totalProducts.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="mb-4 flex flex-wrap gap-2">
        {metrics.map(metric => (
          <button
            key={metric.key}
            onClick={() => setActiveMetric(metric.key)}
            className={`px-3 py-1 rounded-full text-sm ${
              activeMetric === metric.key
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            {metric.name}
          </button>
        ))}
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
        >
          <XAxis
            dataKey="product_group"
            angle={-35}
            textAnchor="end"
            height={100}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={activeMetric}
            name={metrics.find(m => m.key === activeMetric)?.name}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductGroupsChart;