import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Sparkles, TrendingUp, Package, Users, Calendar, AlertTriangle } from 'lucide-react';
import { getAccessToken } from "../../utils/functions";
import { BASE_API_URL } from "../../constants";

const ForecastingSection = ({ organization_id }) => {
    const [forecasts, setForecasts] = useState({
        sales: null,
        inventory: null,
        seasonality: null,
        customers: null,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchForecasts = async () => {
            try {
                const token = getAccessToken();
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                // Fetch all forecasts in parallel
                const [salesRes, inventoryRes, seasonalityRes] = await Promise.all([
                    fetch(`${BASE_API_URL}/api/v1/forecast/sales?organization_id=${organization_id}`, { headers }),
                    fetch(`${BASE_API_URL}/api/v1/forecast/inventory?organization_id=${organization_id}`, { headers }),
                    fetch(`${BASE_API_URL}/api/v1/forecast/seasonality?organization_id=${organization_id}`, { headers })
                ]);

                const [salesData, inventoryData, seasonalityData] = await Promise.all([
                    salesRes.json(),
                    inventoryRes.json(),
                    seasonalityRes.json()
                ]);

                setForecasts({
                    sales: salesData,
                    inventory: inventoryData,
                    seasonality: seasonalityData
                });
            } catch (err) {
                console.error('Error fetching forecasts:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchForecasts();
    }, [organization_id]);

    const renderSalesForecast = () => (
        <div className="bg-gray-800/50 backdrop-blur border border-gray-700/50 rounded-2xl p-6">
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center gap-3">
                    <div className="p-3 rounded-xl ring-1 bg-blue-500/10 text-blue-400 border-blue-500/20">
                        <TrendingUp className="w-6 h-6" />
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-white">Sales Forecast</h3>
                        <p className="text-sm text-gray-400">Next 30 days prediction</p>
                    </div>
                </div>
            </div>

            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={forecasts.sales?.sales_forecast || []}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date) => new Date(date).toLocaleDateString()}
                            stroke="#9CA3AF"
                        />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip
                            contentStyle={{
                                backgroundColor: '#1F2937',
                                border: '1px solid #374151',
                                borderRadius: '0.5rem'
                            }}
                            labelStyle={{ color: '#9CA3AF' }}
                        />
                        <Line
                            type="monotone"
                            dataKey="predicted_sales"
                            stroke="#3B82F6"
                            strokeWidth={2}
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="lower_bound"
                            stroke="#60A5FA"
                            strokeWidth={1}
                            strokeDasharray="5 5"
                            dot={false}
                        />
                        <Line
                            type="monotone"
                            dataKey="upper_bound"
                            stroke="#60A5FA"
                            strokeWidth={1}
                            strokeDasharray="5 5"
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );

    const renderInventoryAlerts = () => {
        const alerts = Object.entries(forecasts.inventory?.forecasts || {})
            .filter(([_, data]) => data.reorder_needed)
            .slice(0, 5);

        return (
            <div className="bg-gray-800/50 backdrop-blur border border-gray-700/50 rounded-2xl p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-3">
                        <div className="p-3 rounded-xl ring-1 bg-orange-500/10 text-orange-400 border-orange-500/20">
                            <Package className="w-6 h-6" />
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold text-white">Inventory Alerts</h3>
                            <p className="text-sm text-gray-400">Items needing attention</p>
                        </div>
                    </div>
                </div>

                <div className="space-y-4">
                    {alerts.map(([item, data]) => (
                        <div
                            key={item}
                            className="flex items-center justify-between p-4 bg-gray-700/20 rounded-xl border border-gray-700"
                        >
                            <div className="flex items-center gap-3">
                                <AlertTriangle className="w-5 h-5 text-orange-400" />
                                <div>
                                    <p className="text-sm font-medium text-white">{item}</p>
                                    <p className="text-xs text-gray-400">
                                        {data.days_until_depletion
                                            ? `${data.days_until_depletion} days until stockout`
                                            : 'Immediate reorder needed'}
                                    </p>
                                </div>
                            </div>
                            <div className="text-right">
                                <p className="text-sm font-medium text-white">
                                    {data.current_stock.toFixed(0)} units
                                </p>
                                <p className="text-xs text-orange-400">
                                    Order {data.optimal_order_quantity.toFixed(0)} units
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSeasonalityInsights = () => {
        const currentMonth = new Date().getMonth() + 1;
        const monthlyPattern = forecasts.seasonality?.monthly_patterns?.[currentMonth];

        return (
            <div className="bg-gray-800/50 backdrop-blur border border-gray-700/50 rounded-2xl p-6">
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-3">
                        <div className="p-3 rounded-xl ring-1 bg-purple-500/10 text-purple-400 border-purple-500/20">
                            <Calendar className="w-6 h-6" />
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold text-white">Seasonal Insights</h3>
                            <p className="text-sm text-gray-400">Current month analysis</p>
                        </div>
                    </div>
                </div>

                {monthlyPattern && (
                    <div className="space-y-6">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="p-4 bg-gray-700/20 rounded-xl">
                                <p className="text-sm text-gray-400">Avg Daily Sales</p>
                                <p className="text-2xl font-bold text-white">
                                    ${monthlyPattern.sales.mean.toFixed(2)}
                                </p>
                            </div>
                            <div className="p-4 bg-gray-700/20 rounded-xl">
                                <p className="text-sm text-gray-400">Avg Transactions</p>
                                <p className="text-2xl font-bold text-white">
                                    {monthlyPattern.transactions.mean.toFixed(0)}
                                </p>
                            </div>
                        </div>

                        {forecasts.seasonality?.recommendations && (
                            <div className="p-4 bg-purple-500/10 rounded-xl border border-purple-500/20">
                                <h4 className="text-sm font-medium text-purple-400 mb-2">
                                    Recommendations
                                </h4>
                                <ul className="space-y-2 text-sm text-gray-300">
                                    {Object.values(forecasts.seasonality.recommendations).map((rec, idx) => (
                                        <li key={idx} className="flex items-start gap-2">
                                            <Sparkles className="w-4 h-4 text-purple-400 mt-0.5 flex-shrink-0" />
                                            <span>{rec}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    if (error) {
        return (
            <div className="text-red-400 text-center p-6">
                Error loading forecasts: {error}
            </div>
        );
    }

    if (loading) {
        return (
            <div className="text-gray-400 text-center p-6">
                Loading forecasts...
            </div>
        );
    }

    return (
        <div className="space-y-6">
            {/* Section Header */}
            <div className="flex items-center gap-2 px-4">
                <Sparkles className="w-5 h-5 text-blue-400" />
                <h2 className="text-xl font-semibold text-white">Predictive Analytics</h2>
            </div>

            {/* Forecasting Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 px-4">
                {renderSalesForecast()}
                {renderInventoryAlerts()}
                {renderSeasonalityInsights()}
            </div>
        </div>
    );
};

export default ForecastingSection;