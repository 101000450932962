import React from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Bot, Mail, Twitter, Linkedin, Github } from 'lucide-react';
import JaanchLogo from './JaanchLogo';

const Footer = () => {
  const socialLinks = [
    { icon: Twitter, href: '#', label: 'Twitter' },
    { icon: Linkedin, href: '#', label: 'LinkedIn' },
    { icon: Github, href: '#', label: 'GitHub' },
    { icon: Mail, href: '#', label: 'Email' }
  ];

  return (
    <footer className="relative border-t border-gray-800/50 bg-gray-900/95 backdrop-blur-xl text-gray-400">
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          <div className="flex items-center gap-3">
           
            <div>
              <Link to="/" className="text-xl font-bold">
                <JaanchLogo className="h-6" />
              </Link>
              <p className="text-sm text-gray-500">AI-Powered Analytics</p>
            </div>
          </div>

          <div className="flex items-center gap-6">
            {socialLinks.map((link, i) => (
              <motion.a
                key={i}
                href={link.href}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="p-2 rounded-lg hover:bg-gray-800/50 text-gray-400 hover:text-gray-300 transition-colors"
                aria-label={link.label}
              >
                <link.icon className="w-5 h-5" />
              </motion.a>
            ))}
          </div>

          <div className="text-center md:text-right text-sm">
            <p>© 2024 JaanchAI. All rights reserved.</p>
            <p className="mt-1 text-gray-500">
              Built with <span className="text-red-400">❤</span> in India
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;