import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-scroll';
import { motion, useTransform, useViewportScroll, AnimatePresence } from 'framer-motion';
import { TrendingUp, DollarSign, Package, Zap, ChevronRight, Bot, Activity } from 'lucide-react';
import BoostSales from './BoostSales';
import OptimizePricing from '../OptimizePricing';
import StreamlineInventory from './StreamlineInventory';
import EnhanceEfficiency from '../EnhanceEfficiency';

const Solutions = () => {
  const [activeSection, setActiveSection] = useState('boost-sales');
  const [hoveredSection, setHoveredSection] = useState(null);
  const { scrollY } = useViewportScroll();
  const navOpacity = useTransform(scrollY, [0, 100], [0, 1]);

  const sections = useMemo(() => [
    { 
      id: 'boost-sales', 
      title: 'Boost Sales',
      subtitle: 'AI-Driven Growth',
      icon: TrendingUp, 
      color: 'from-blue-500 to-cyan-500',
      gradient: 'from-blue-500/20 to-cyan-500/20',
      border: 'border-blue-500/20' 
    },
    { 
      id: 'optimize-pricing', 
      title: 'Smart Pricing',
      subtitle: 'Dynamic Optimization',
      icon: DollarSign, 
      color: 'from-purple-500 to-pink-500',
      gradient: 'from-purple-500/20 to-pink-500/20',
      border: 'border-purple-500/20'
    },
    { 
      id: 'streamline-inventory', 
      title: 'Inventory AI',
      subtitle: 'Smart Management',
      icon: Package, 
      color: 'from-emerald-500 to-teal-500',
      gradient: 'from-emerald-500/20 to-teal-500/20',
      border: 'border-emerald-500/20'
    },
    { 
      id: 'enhance-efficiency', 
      title: 'Workflow AI',
      subtitle: 'Process Automation',
      icon: Zap, 
      color: 'from-amber-500 to-orange-500',
      gradient: 'from-amber-500/20 to-orange-500/20',
      border: 'border-amber-500/20'
    },
  ], []);

  useEffect(() => {
    const handleScroll = () => {
      const currentSection = sections.find(section => {
        const element = document.getElementById(section.id);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) setActiveSection(currentSection.id);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  return (
    <div className="relative bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black text-white">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <motion.nav 
        className="fixed top-16 left-0 right-0 z-50 border-y border-gray-800/50"
        style={{ opacity: navOpacity }}
      >
        <div className="bg-gray-900/80 backdrop-blur-xl">
          <div className="container mx-auto py-4">
            <div className="flex justify-center flex-wrap gap-4">
              {sections.map((section) => (
                <Link
                  key={section.id}
                  to={section.id}
                  smooth={true}
                  duration={600}
                  onMouseEnter={() => setHoveredSection(section.id)}
                  onMouseLeave={() => setHoveredSection(null)}
                >
                  <motion.div
                    className={`relative cursor-pointer rounded-xl overflow-hidden ${
                      activeSection === section.id
                        ? `bg-gradient-to-r ${section.gradient} border ${section.border}`
                        : 'bg-gray-800/50 border border-gray-700/50 hover:border-gray-600/50'
                    } transition-colors`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <div className="relative px-4 py-2 flex items-center gap-3">
                      <section.icon className={`w-4 h-4 ${
                        activeSection === section.id ? 'text-white' : 'text-gray-400'
                      }`} />
                      <div>
                        <div className={`text-sm font-medium ${
                          activeSection === section.id ? 'text-white' : 'text-gray-300'
                        }`}>
                          {section.title}
                        </div>
                        <div className="text-xs text-gray-500">
                          {section.subtitle}
                        </div>
                      </div>
                      {(activeSection === section.id || hoveredSection === section.id) && (
                        <motion.div
                          initial={{ opacity: 0, x: -10 }}
                          animate={{ opacity: 1, x: 0 }}
                          className="text-gray-400"
                        >
                          <ChevronRight className="w-4 h-4" />
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </motion.nav>

      <div className="pt-32 relative z-10">
        {sections.map((section, index) => (
          <motion.div
            key={section.id}
            id={section.id}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="py-20"
          >
            <div className="container mx-auto px-4">
              <div className="flex items-center gap-4 mb-8">
                <motion.div 
                  className={`p-3 rounded-xl bg-gradient-to-br ${section.gradient} border ${section.border}`}
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                >
                  <section.icon className="w-6 h-6 text-white" />
                </motion.div>
                <div>
                  <h2 className={`text-4xl font-bold bg-gradient-to-r ${section.color} bg-clip-text text-transparent`}>
                    {section.title}
                  </h2>
                  <p className="text-gray-400 mt-1">{section.subtitle}</p>
                </div>
              </div>

              {section.id === 'boost-sales' && <BoostSales />}
              {section.id === 'optimize-pricing' && <OptimizePricing />}
              {section.id === 'streamline-inventory' && <StreamlineInventory />}
              {section.id === 'enhance-efficiency' && <EnhanceEfficiency />}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Solutions;