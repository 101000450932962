import React, {useState} from 'react';
import {Bar, BarChart, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {formatLargeNumber} from "../../utils/functions";

const ProductLifecycleChart = ({data = {}}) => {
    const [activeMetric, setActiveMetric] = useState('average_transaction_value');

    if (!data.lifecycle_metrics || data.lifecycle_metrics.length === 0) {
        return (
            <div className="flex items-center justify-center h-64 bg-white rounded-lg">
                <p className="text-gray-500">No data to show for the given date range</p>
            </div>
        );
    }

    // Lifecycle stage colors remain consistent
    const stageColors = {
        'Introduction': '#22c55e',  // Green
        'Maturity': '#3b82f6',     // Blue
        'Decline': '#ef4444'       // Red
    };

    // Neutral colors for metrics
    const metrics = [
        {
            key: 'average_transaction_value',
            name: 'Avg Transaction Value',
            color: '#6b7280',  // Gray
            format: (value) => `₹${value.toLocaleString()}`
        },
        {
            key: 'units_sold',
            name: 'Units Sold',
            color: '#6b7280',  // Gray
            format: (value) => value.toLocaleString()
        }
    ];


    const totalRevenue = data.lifecycle_metrics.reduce((sum, item) => sum + item.total_revenue, 0);
    const totalUnits = data.lifecycle_metrics.reduce((sum, item) => sum + item.units_sold, 0);

    const CustomPieTooltip = ({active, payload}) => {
        if (active && payload && payload[0]) {
            const data = payload[0].payload;
            return (
                <div className="bg-white p-3 border rounded-lg shadow-lg">
                    <div className="flex items-center gap-2 mb-1">
                        <div
                            className="w-3 h-3 rounded-full"
                            style={{backgroundColor: stageColors[data.lifecycle_stage]}}
                        />
                        <p className="font-semibold text-gray-800">{data.lifecycle_stage}</p>
                    </div>
                    <p className="text-sm text-gray-600">
                        Revenue Share: {data.revenue_share_percentage.toFixed(2)}%
                    </p>
                    <p className="text-sm text-gray-600">
                        Revenue: {formatLargeNumber(data.total_revenue)}
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomBarTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            const itemData = data.lifecycle_metrics.find(d => d.lifecycle_stage === label);
            if (!itemData) return null;

            return (
                <div className="bg-white p-4 border rounded-lg shadow-lg">
                    <div className="flex items-center gap-2 mb-2">
                        <div
                            className="w-3 h-3 rounded-full"
                            style={{backgroundColor: stageColors[label]}}
                        />
                        <p className="font-semibold text-gray-800">{label} Stage</p>
                    </div>
                    <div className="space-y-1">
                        {metrics.map(metric => (
                            <p key={metric.key} className="text-sm text-gray-600">
                                {metric.name}: {metric.format(itemData[metric.key])}
                            </p>
                        ))}
                        <div className="border-t border-gray-200 mt-2 pt-2">
                            <p className="text-sm text-gray-600">
                                Unique Products: {itemData.unique_products.toLocaleString()}
                            </p>
                            <p className="text-sm text-gray-600">
                                Avg Revenue/Product: ₹{itemData.average_revenue_per_product.toLocaleString()}
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="p-3 bg-white rounded-lg shadow-lg">
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
                <div className="bg-blue-50 p-3 rounded-lg">
                    <h3 className="text-sm font-medium text-blue-800">Total Revenue</h3>
                    <p className="text-base font-bold text-blue-600">
                        {formatLargeNumber(totalRevenue)}
                    </p>
                </div>
                <div className="bg-green-50 p-3 rounded-lg">
                    <h3 className="text-sm font-medium text-green-800">Total Units</h3>
                    <p className="text-base font-bold text-green-600">
                        {totalUnits.toLocaleString()}
                    </p>
                </div>
            </div>


            {/* Lifecycle Distribution */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                {/* Pie Chart */}
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                    <h3 className="text-sm font-medium text-gray-600 mb-2">Revenue Distribution</h3>
                    <ResponsiveContainer width="100%" height={140}>
                        <PieChart>
                            <Pie
                                data={data.lifecycle_metrics}
                                dataKey="revenue_share_percentage"
                                nameKey="lifecycle_stage"
                                cx="50%"
                                cy="50%"
                                outerRadius={60}
                            >
                                {data.lifecycle_metrics.map((entry) => (
                                    <Cell
                                        key={entry.lifecycle_stage}
                                        fill={stageColors[entry.lifecycle_stage]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomPieTooltip/>}/>
                        </PieChart>
                    </ResponsiveContainer>
                </div>


                {/* Stage Performance */}
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                    <h3 className="text-sm font-medium text-gray-600 mb-4">Stage Performance</h3>
                    <div className="space-y-4">
                        {data.lifecycle_metrics.map(stage => (
                            <div
                                key={stage.lifecycle_stage}
                                className="flex items-center gap-3 px-3 py-1.5 bg-gray-50 rounded-lg"
                            >
                                <div
                                    className="w-4 h-4 flex-shrink-0 rounded-full"
                                    style={{backgroundColor: stageColors[stage.lifecycle_stage]}}
                                />
                                <span className="text-sm text-gray-600 min-w-[100px]">
                  {stage.lifecycle_stage}
                </span>
                                <span className="text-sm font-medium text-gray-900 ml-auto">
                  {formatLargeNumber(stage.total_revenue)}
                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Metric Selection */}
            <div className="mb-3 flex flex-wrap gap-2">
                {metrics.map(metric => (
                    <button
                        key={metric.key}
                        onClick={() => setActiveMetric(metric.key)}
                        className={`px-3 py-1 rounded-full text-sm ${
                            activeMetric === metric.key
                                ? 'bg-gray-600 text-white'
                                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                        }`}
                    >
                        {metric.name}
                    </button>
                ))}
            </div>

            {/* Bar Chart */}
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={data.lifecycle_metrics}
                    margin={{top: 10, right: 30, left: 40, bottom: 10}}
                >
                    <XAxis
                        dataKey="lifecycle_stage"
                        interval={0}
                        tick={{fontSize: 12}}
                    />
                    <YAxis/>
                    <Tooltip content={<CustomBarTooltip/>}/>
                    <Bar
                        dataKey={activeMetric}
                        name={metrics.find(m => m.key === activeMetric)?.name}
                        radius={[4, 4, 0, 0]}
                    >
                        {data.lifecycle_metrics.map((entry) => (
                            <Cell
                                key={entry.lifecycle_stage}
                                fill={stageColors[entry.lifecycle_stage]}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
        ;
};

export default ProductLifecycleChart;
