import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {formatLargeNumber} from "../../utils/functions";

const ProductAgeAnalysisChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('revenue_share_percentage');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  const processedData = [...data]
      .map(item => ({
        ...item,
            total_revenue: item.total_revenue / 100000,
      }))

  const metrics = [
    {
      key: 'revenue_share_percentage',
      name: 'Revenue Share %',
      color: '#2563eb',
      format: (value) => `${value.toFixed(2)}%`
    },
    {
      key: 'total_revenue',
      name: 'Total Revenue (Lacs)',
      color: '#16a34a',
      format: (value) => `₹${(value / 100000).toFixed(2)}L`
    },
    {
      key: 'avg_transaction_value',
      name: 'Avg Transaction Value',
      color: '#7c3aed',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'units_sold',
      name: 'Units Sold',
      color: '#ea580c',
      format: (value) => value.toLocaleString()
    }
  ];

  // Calculate totals
  const totalRevenue = data.reduce((sum, item) => sum + item.total_revenue, 0);
  const totalUnits = data.reduce((sum, item) => sum + item.units_sold, 0);

  const MetricCard = ({ title, establishedValue, newValue, format }) => (
    <div className="bg-white p-3 rounded-lg border border-gray-200">
      <h3 className="text-sm font-medium text-gray-600 mb-2">{title}</h3>
      <div className="space-y-2">
        <div className="bg-gray-50 rounded pt-2">
          <p className="text-xs text-gray-500">Established</p>
          <p className="text-sm font-semibold text-gray-900">
            {format(establishedValue)}
          </p>
        </div>
        <div className="bg-gray-50 rounded pt-0">
          <p className="text-xs text-gray-500">New</p>
          <p className="text-sm font-semibold text-gray-900">
            {format(newValue)}
          </p>
        </div>
      </div>
    </div>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const itemData = data.find(d => d.category === label);
      if (!itemData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{itemData.category} Products</p>
          <div className="space-y-1">
            <p className="text-sm" style={{ color: metrics.find(m => m.key === activeMetric)?.color }}>
              {metrics.find(m => m.key === activeMetric)?.name}: {
                metrics.find(m => m.key === activeMetric)?.format(itemData[activeMetric])
              }
            </p>
            <div className="border-t border-gray-200 mt-2 pt-2">
              <p className="text-sm text-gray-600">
                Unique Products: {itemData.unique_products.toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                Sales Frequency: {itemData.sales_frequency.toFixed(2)}
              </p>
              <p className="text-sm text-gray-600">
                Avg Revenue/Product: ₹{itemData.avg_revenue_per_product.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

return (
    <div className="p-3 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
        <div className="bg-blue-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Total Revenue</h3>
          <p className="text-base font-bold text-blue-600">
            {formatLargeNumber(totalRevenue)}
          </p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-green-800">Total Units</h3>
          <p className="text-base font-bold text-green-600">
            {totalUnits.toLocaleString()}
          </p>
        </div>
      </div>

      {/* Comparison Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-4">
        <MetricCard
          title="Sales Frequency"
          establishedValue={data[0].sales_frequency}
          newValue={data[1].sales_frequency}
          format={(value) => value.toFixed(2)}
        />
        <MetricCard
          title="Unique Products"
          establishedValue={data[0].unique_products}
          newValue={data[1].unique_products}
          format={(value) => value.toLocaleString()}
        />
        <MetricCard
          title="Avg Revenue/Product"
          establishedValue={data[0].avg_revenue_per_product}
          newValue={data[1].avg_revenue_per_product}
          format={(value) => `₹${value.toLocaleString()}`}
        />
      </div>

      {/* Metric Selection */}
      <div className="mb-3 flex flex-wrap gap-2">
        {metrics.map(metric => (
          <button
            key={metric.key}
            onClick={() => setActiveMetric(metric.key)}
            className={`px-3 py-1 rounded-full text-sm ${
              activeMetric === metric.key
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            {metric.name}
          </button>
        ))}
      </div>

      {/* Main Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={processedData}
          margin={{ top: 10, right: 30, left: 40, bottom: 10 }}
        >
          <XAxis
            dataKey="category"
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={activeMetric}
            name={metrics.find(m => m.key === activeMetric)?.name}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductAgeAnalysisChart;