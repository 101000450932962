import JaanchLogo from "../components/JaanchLogo";
import React, {useState} from 'react';
import {FaBoxes, FaChartLine, FaMegaport, FaRecycle, FaShoppingBag, FaTags, FaUsers} from 'react-icons/fa';
import NavbarProfileButton from "./dashboard/NavbarProfileButton";
import {useSelector} from "react-redux";
import Footer from "./Footer";
import ChatbotFloater from "./ChatFloater";
import EnhancedDateRangePicker from "./presentational/DateRangePicker";
import {AnimatePresence, motion} from "framer-motion";
import FileUploadModal from "./dashboard/FileUploadModal";

export const categoryIcons = {
    sales_analysis: FaChartLine,
    product_analysis: FaShoppingBag,
    marketing: FaMegaport,
    product_attributes: FaTags,
    inventory_management: FaBoxes,
    product_lifecycle: FaRecycle,
};



  const Layout = ({children}) => {
      const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const {organization} = useSelector(state => state.user);
    const dateRange = useSelector(state => state.dateRange);

    return (
      <div className="flex min-h-screen">
        <div className="flex-grow bg-gray-900">
          <header className="bg-[#1a1f2e] border-b border-gray-800 z-10 fixed top-0 right-0 left-0">
            <div className="mx-auto">
                <div className="flex items-center justify-between py-2 px-4">
                    <div className="block ml-6">
                        <JaanchLogo className="h-24"/>
                    </div>

                    <div
                        onClick={() => setIsUploadModalOpen(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-all duration-200 cursor-pointer"
                    >
                        <div className="flex items-center gap-2">
                            <FaUsers className="text-blue-400 w-4 h-4"/>
                            <span className="text-white">{organization?.organization_name}</span>
                        </div>
                    </div>

                    <NavbarProfileButton/>
                </div>

                <AnimatePresence>
                    <motion.div
                        initial={{height: 0, opacity: 0}}
                        animate={{height: 'auto', opacity: 1}}
                        exit={{height: 0, opacity: 0}}
                        transition={{duration: 0.2}}
                        className="border-t border-gray-800"
                    >
                        <div className="py-2 px-4 flex">
                    <div className="ml-[62px]">
                      <EnhancedDateRangePicker dateRange={dateRange}/>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </header>
  
          <main className="bg-gray-900 mt-14 mx-auto px-4 py-6">
            {children}
          </main>
  
          <div className="bg-gray-900 mt-16">
            <Footer className="relative z-0 mt-16" />
            <ChatbotFloater /> {/* Added here at root level */}
          </div>
        </div>

          <FileUploadModal
              isOpen={isUploadModalOpen}
              onClose={() => setIsUploadModalOpen(false)}
          />
      </div>
    );
  };
  
  export default Layout;