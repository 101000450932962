import React from 'react';
import { motion } from 'framer-motion';
import { categoryIcons } from './Layout';
import { FaPlus, FaMinus } from 'react-icons/fa';

const KPI = ({ id, name, category, endpoint, color, description_text, onClick, isSelected }) => {
 const Icon = categoryIcons[category];

 return (
   <motion.div
     initial={{ opacity: 0, y: 20 }}
     animate={{ opacity: 1, y: 0 }}
     exit={{ opacity: 0, y: -20 }}
     whileHover={{ scale: 1.02 }}
     whileTap={{ scale: 0.98 }}
     onClick={() => onClick?.({ id, name, category, endpoint })}
     className="rounded-lg shadow-md mb-3 cursor-pointer relative"
     style={{
       background: `linear-gradient(to right, ${color}15, ${color}25)`,
       border: `1px solid ${color}30`
     }}
   >
     <div className="flex items-center justify-between p-4">
       <div className="flex items-center gap-3">
         {Icon && <Icon style={{ color }} size={20} />}
         <div className="flex flex-col">
           <span className="font-medium" style={{ color }}>{name}</span>
           {description_text && (
             <span className="text-xs opacity-75 mt-1" style={{ color }}>
               {description_text}
             </span>
           )}
         </div>
       </div>
       <div
         className="w-6 h-6 flex items-center justify-center rounded-full"
         style={{
           backgroundColor: isSelected ? 'rgba(239, 68, 68, 0.2)' : 'rgba(34, 197, 94, 0.2)'
         }}
       >
         {isSelected ? (
           <FaMinus className="text-red-500" size={12} />
         ) : (
           <FaPlus className="text-green-400" size={12} />
         )}
       </div>
     </div>
   </motion.div>
 );
};

export default KPI;