import axios from 'axios';
import {BASE_API_URL} from "../constants";
import {getAccessToken} from "./functions";

const api = axios.create({
    baseURL: BASE_API_URL,
    timeout: 30000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
api.interceptors.response.use(
    (response) => response.data,
    (error) => {
        return Promise.reject(error);
    }
);

// Auth endpoints
export const authService = {
    login: async (credentials) => {
        try {
            const response = await api.post('/auth/login/password', credentials);
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    verifyToken: async () => {
        try {
            const response = await api.get('/auth/me');
            console.log(response);
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    getManagedUsers: async () => {
        try {
            const response = await api.get('/auth/managed-users');
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    requestOTPForAddingUserToOrg: async (data) => {
        try {
            const response = await api.post('/auth/add-user/otp', data);
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    verifyOTPForAddingUserToOrg: async (data) => {
        try {
            const response = await api.post('/auth/verify/otp/add-user', data);
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    requestOTPForLogin: async (data) => {
        try {
            const response = await api.post('/auth/login/otp', data);
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    verifyOTPForLogin: async (data) => {
        try {
            const response = await api.post('/auth/verify/otp/login', data);
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },
};

// KPI endpoints
export const kpiService = {
    getKPIs: async (params) => {
        console.log(params);
        try {
            const response = await api.get('/api/v1/kpis', {
                params: {
                    organization_id: params.organization_id
                },
            });
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },


    getKPIData: async (endpoint, params) => {
        try {
            const response = await api.get(`/api/v1/${endpoint}`, {params});
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    getDashboardKPIData: async (params) => {
        try {
            const response = await api.get(`/api/v1/dashboard-kpis`, {params});
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    getChatResponse: async (data, params) => {
        console.log(data)
        try {
            const response = await api.post('/api/v1/text-to-sql', data, {
                params: {
                    organization_id: params.organization_id,
                }
            });
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    }
};

// File upload endpoints
export const handleRawDataService = {
    uploadForIngestion: async (data, params) => {
        try {
            const response = await api.post(`/api/v1/ingest-data/${params.organization_id}`, data, {
                params: {
                    organization_id: params.organization_id,
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    }
}