import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {verifyAuth} from "../../utils/functions";
import {clearUser, setUser} from "../../redux/features/userSlice";
import {clearKpis} from "../../redux/features/kpiSlice";
import GradientSpinner from "../skeletons/GradientSpinner";


const ProtectedRoute = ({ children }) => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const { isAuthenticated } = useSelector(state => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      console.log("checking auth")
      const {isAuthValid, data} = await verifyAuth();

      if (!isAuthValid && isAuthenticated) {
        // Clear redux state if backend verification fails
        dispatch(clearUser());
        dispatch(clearKpis());
      } else {
        // If token is valid, restore user and org data in redux state
        data && data.user && data.organization && dispatch(
            setUser({
              user: data.user,
              organization: data.organization
            })
        );
        setIsValid(isAuthValid);
        setIsVerifying(false);
      }

    };

    checkAuth();
  }, [dispatch, isAuthenticated]);

  if (isVerifying) {
    return (
        <div className="fixed inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 flex items-center justify-center">
          <GradientSpinner size="lg"/>
        </div>
    )
  }

  if (!isValid) {
    return <Navigate to="/login" state={{from: location}} replace/>;
  }

  return children;
};

export default ProtectedRoute;