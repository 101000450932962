import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const BranchPerformanceChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('total_sales');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Process and format the data
  const processedData = data.map(branch => ({
    ...branch,
    total_sales: Number((branch.total_sales / 100000).toFixed(2)), // Convert to millions
    avg_transaction_value: Number(branch.avg_transaction_value.toFixed(2)),
    total_units_sold: Number(branch.total_units_sold.toFixed(2)),
    transaction_count: Number(branch.transaction_count.toFixed(2))
  }));

  const metrics = [
    {
      key: 'total_sales',
      name: 'Total Sales (Lacs)',
      color: '#2563eb',
      format: (value) => `₹${value}L`
    },
    {
      key: 'transaction_count',
      name: 'Transaction Count',
      color: '#16a34a',
      format: (value) => value.toLocaleString()
    },
    {
      key: 'avg_transaction_value',
      name: 'Avg Transaction Value (₹)',
      color: '#7c3aed',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'total_units_sold',
      name: 'Total Units Sold',
      color: '#ea580c',
      format: (value) => value.toLocaleString()
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const branchData = processedData.find(d => d.branch_name === label);
      if (!branchData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">Branch: {label}</p>
          <div className="space-y-1">
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(branchData[metric.key])}
              </p>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="mb-4 flex flex-wrap gap-2">
        {metrics.map(metric => (
          <button
            key={metric.key}
            onClick={() => setActiveMetric(metric.key)}
            className={`px-3 py-1 rounded-full text-sm ${
              activeMetric === metric.key
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            {metric.name}
          </button>
        ))}
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
        >
          <XAxis
            dataKey="branch_name"
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={activeMetric}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BranchPerformanceChart;