import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const StockTurnover = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('turnover_ratio');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  const processedData = data.filter(item =>
    item.branch_name !== '(NIL)' &&
    item.branch_name !== 'GRAND TOTALS' &&
    item.turnover_ratio !== null
  );

  const metrics = [
    {
      key: 'turnover_ratio',
      name: 'Turnover Ratio',
      color: '#2563eb',
      format: (value) => value.toFixed(2)
    },
    {
      key: 'sales_quantity',
      name: 'Sales Quantity',
      color: '#16a34a',
      format: (value) => value.toLocaleString()
    },
    {
      key: 'average_inventory',
      name: 'Average Inventory',
      color: '#7c3aed',
      format: (value) => (value * 100).toFixed(1) + '%'
    },
    {
      key: 'total_stock',
      name: 'Total Stock',
      color: '#ea580c',
      format: (value) => (value * 100).toLocaleString()
    }
  ];

  // Calculate summary statistics
  const summaryStats = {
    avgTurnover: processedData.reduce((sum, item) => sum + item.turnover_ratio, 0) / processedData.length,
    totalStock: processedData.reduce((sum, item) => sum + item.total_stock, 0),
    totalSales: processedData.reduce((sum, item) => sum + item.sales_quantity, 0)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const branchData = processedData.find(d => d.branch_name === label);
      if (!branchData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">Branch: {branchData.branch_name}</p>
          <div className="space-y-1">
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(branchData[metric.key])}
              </p>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-3 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-4">
        <div className="bg-blue-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Avg Turnover Ratio</h3>
          <p className="text-base font-bold text-blue-600">
            {summaryStats.avgTurnover.toFixed(2)}
          </p>
        </div>
        <div className="bg-purple-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-purple-800">Total Stock</h3>
          <p className="text-base font-bold text-purple-600">
            {(summaryStats.totalStock * 100).toLocaleString()}
          </p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-green-800">Total Sales Qty</h3>
          <p className="text-base font-bold text-green-600">
            {summaryStats.totalSales.toLocaleString()}
          </p>
        </div>
      </div>

      {/* Metric Selection */}
      <div className="mb-3 flex flex-wrap gap-2">
        {metrics.map(metric => (
            <button
                key={metric.key}
                onClick={() => setActiveMetric(metric.key)}
                className={`px-3 py-1 rounded-full text-sm ${
                    activeMetric === metric.key
                ? metric.key === 'turnover_ratio'
                  ? 'bg-blue-600 text-white'
                  : metric.key === 'total_sales_quantity'
                    ? 'bg-green-600 text-white'
                    : 'bg-purple-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            {metric.name}
          </button>
        ))}
      </div>

      {/* Bar Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={processedData}
          margin={{ top: 10, right: 30, left: 40, bottom: 10 }}
        >
          <XAxis
            dataKey="branch_name"
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={activeMetric}
            name={metrics.find(m => m.key === activeMetric)?.name}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StockTurnover;