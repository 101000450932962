import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import ClickBarIndicator from "./ClickBarIndicator";
import {formatLargeNumber} from "../../utils/functions";

const ProductTypesChart = ({data = {}}) => {
    const [activeMetric, setActiveMetric] = useState('market_share_percentage');
    const [displayCount, setDisplayCount] = useState(10);
    const [selectedType, setSelectedType] = useState(null);

    if (!data.overall_metrics || Object.keys(data.overall_metrics).length === 0) {
        return (
            <div className="flex items-center justify-center h-64 bg-white rounded-lg">
                <p className="text-gray-500">No data to show for the given date range</p>
            </div>
        );
    }

    const metrics = [
        {
            key: 'market_share_percentage',
            name: 'Market Share %',
            color: '#2563eb',
            format: (value) => `${value.toFixed(2)}%`
        },
        {
            key: 'net_amount',
            name: 'Net Amount (Lacs)',
            color: '#16a34a',
            format: (value) => `₹${(value).toFixed(2)}L`
        },
        {
            key: 'total_quantity',
            name: 'Total Quantity',
            color: '#7c3aed',
            format: (value) => value.toLocaleString()
        }
    ];

    // Process and sort data
    const processedData = Object.entries(data.overall_metrics)
        .filter(([key]) => key !== '(NIL)' && key !== 'null')
        .map(([type, metrics]) => ({
            type,
            ...metrics,
            net_amount: metrics.net_amount / 100000,
        }))
        .sort((a, b) => b[activeMetric] - a[activeMetric])
        .slice(0, displayCount);

    // Get top products data for selected type
    const topProducts = selectedType && data.top_products?.[selectedType]
        ? data.top_products[selectedType]
            .map(product => ({
                ...product,
                product_name: product.product_name.length > 25
                    ? `${product.product_name.slice(0, 25)}...`
                    : product.product_name,
                full_name: product.product_name
            }))
        : [];

    // Calculate summary statistics
    const totalRevenue = Object.values(data.overall_metrics)
        .reduce((sum, item) => sum + item.net_amount, 0);
    const totalQuantity = Object.values(data.overall_metrics)
        .reduce((sum, item) => sum + item.total_quantity, 0);
    const totalItems = Object.values(data.overall_metrics)
        .reduce((sum, item) => sum + item.item_count, 0);

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;

            return (
                <div className="bg-white p-4 border rounded-lg shadow-lg">
                    <p className="font-semibold text-gray-800 mb-2">
                        {data.full_name || data.type}
                    </p>
                    <div className="space-y-1">
                        {payload.map(({name, value, color}) => {
                            const metric = metrics.find(m => m.name === name);
                            return (
                                <p
                                    key={name}
                                    className="text-sm"
                                    style={{color}}
                                >
                                    {name}: {
                                    name.includes('Share') ?
                                        `${value.toFixed(2)}%` :
                                        (metric ? metric.format(value) : value.toLocaleString())
                                }
                                </p>
                            )
                        })}
                        {data.type && (
                            <div className="border-t border-gray-200 mt-2 pt-2">
                                <p className="text-sm text-gray-600">
                                    Items: {data.item_count.toLocaleString()}
                                </p>
                                <p className="text-sm text-gray-600">
                                    Bills: {data.bill_count.toLocaleString()}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="space-y-6">
            <div className="p-4 bg-white rounded-lg shadow-lg">
                {/* Summary Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                    <div className="bg-blue-50 p-4 rounded-lg">
                        <h3 className="text-sm font-medium text-blue-800">Total Revenue</h3>
                        <p className="text-lg font-bold text-blue-600">
                            {formatLargeNumber(totalRevenue)}
                        </p>
                    </div>
                    <div className="bg-green-50 p-4 rounded-lg">
                        <h3 className="text-sm font-medium text-green-800">Total Quantity</h3>
                        <p className="text-lg font-bold text-green-600">
                            {totalQuantity.toLocaleString()} units
                        </p>
                    </div>
                    <div className="bg-purple-50 p-4 rounded-lg">
                        <h3 className="text-sm font-medium text-purple-800">Total Items</h3>
                        <p className="text-lg font-bold text-purple-600">
                            {totalItems.toLocaleString()}
                        </p>
                    </div>
                </div>

                <div className="mb-4 flex flex-wrap gap-4 justify-between items-center">
                    <div className="flex flex-wrap gap-2">
                        {metrics.map(metric => (
                            <button
                                key={metric.key}
                                onClick={() => setActiveMetric(metric.key)}
                                className={`px-3 py-1 rounded-full text-sm ${
                                    activeMetric === metric.key
                                        ? 'bg-blue-600 text-white'
                                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                }`}
                            >
                                {metric.name}
                            </button>
                        ))}
                    </div>
                    <div className="flex gap-2 items-center">
                        <span className="text-sm text-gray-600">Show:</span>
                        <select
                            value={displayCount}
                            onChange={(e) => setDisplayCount(Number(e.target.value))}
                            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8 appearance-none w-24"
                            style={{
                                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right 0.5rem center',
                                backgroundSize: '1.5em 1.5em'
                            }}
                        >
                            <option value={5}>Top 5</option>
                            <option value={10}>Top 10</option>
                            <option value={15}>Top 15</option>
                            <option value={20}>Top 20</option>
                        </select>
                    </div>
                </div>

                <div className="relative">
                    <ClickBarIndicator/>

                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={processedData}
                            margin={{top: 20, right: 30, left: 40, bottom: 60}}
                            onClick={(data) => {
                                if (data && data.activePayload) {
                                    const clickedType = data.activePayload[0].payload.type;
                                    setSelectedType(selectedType === clickedType ? null : clickedType);
                                }
                            }}
                        >
                            <XAxis
                                dataKey="type"
                                angle={-35}
                                textAnchor="end"
                                height={100}
                                interval={0}
                                tick={{fontSize: 12}}
                            />
                            <YAxis/>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend/>
                            <Bar
                                dataKey={activeMetric}
                                name={metrics.find(m => m.key === activeMetric)?.name}
                                fill={metrics.find(m => m.key === activeMetric)?.color}
                                radius={[4, 4, 0, 0]}
                                cursor="pointer"
                                className="transition-all duration-200 hover:brightness-110"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>

            {/* Top Products Section */}
            {selectedType && topProducts.length > 0 && (
                <div className="bg-white rounded-lg shadow-lg">
                    <div className="p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-semibold mb-4">
                                Top Products - {selectedType}
                            </h3>
                            <button
                                onClick={() => setSelectedType(null)}
                                className="text-sm text-gray-600 hover:text-gray-800"
                            >
                                ✕ Close
                            </button>
                        </div>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                data={topProducts}
                                margin={{top: 20, right: 30, left: 40, bottom: 20}}
                            >
                                <XAxis
                                    dataKey="product_name"
                                    angle={-35}
                                    textAnchor="end"
                                    height={100}
                                    interval={0}
                                    tick={{fontSize: 12}}
                                />
                                <YAxis/>
                                <Tooltip content={<CustomTooltip/>}/>
                                <Legend/>
                                <Bar
                                    dataKey="revenue_share_percentage"
                                    name="Revenue Share %"
                                    fill="#dc2626"
                                    radius={[4, 4, 0, 0]}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductTypesChart;