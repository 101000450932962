import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const ProductDemographicsChart = ({ data = [] }) => {
  const [sortMetric, setSortMetric] = useState('market_share_percentage');
  const [activeMetric, setActiveMetric] = useState('market_share_percentage');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  const metrics = [
    {
      key: 'market_share_percentage',
      name: 'Market Share %',
      color: '#2563eb',
      format: (value) => `${value.toFixed(2)}%`
    },
    {
      key: 'monthly_growth_percentage',
      name: 'Monthly Growth %',
      color: '#16a34a',
      format: (value) => `${value.toFixed(2)}%`
    },
    {
      key: 'avg_price_per_unit',
      name: 'Avg Price/Unit',
      color: '#7c3aed',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'total_quantity',
      name: 'Total Quantity',
      color: '#ea580c',
      format: (value) => value.toLocaleString()
    }
  ];

  // Process and sort data
  const processedData = [...data]
    .sort((a, b) => b[sortMetric] - a[sortMetric])
    .slice(0, 10)
    .map(product => ({
      ...product,
      item_name: product.item_name.length > 20
        ? `${product.item_name.slice(0, 20)}...`
        : product.item_name,
      full_name: product.item_name
    }));

  const handleMetricChange = metric => {
    setActiveMetric(metric.key);
    setSortMetric(metric.key)
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const productData = processedData.find(p => p.item_name === label);
      if (!productData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{productData.full_name}</p>
          <div className="space-y-1">
            <p className="text-sm text-gray-600">
              Branch: {productData.branch_name}
            </p>
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(productData[metric.key])}
              </p>
            ))}
            <p className="text-sm text-gray-600">
              Bills: {productData.bill_count}
            </p>
            <p className="text-sm text-gray-600">
              Net Amount: ₹{productData.net_amount.toLocaleString()}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="mb-4 flex flex-wrap gap-4">
        <div className="flex gap-2 items-center">
          <span className="text-sm font-medium text-gray-700">Sort by:</span>
          <select
            value={sortMetric}
            onChange={(e) => setSortMetric(e.target.value)}
            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 pl-3 pr-8 appearance-none w-40"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 0.5rem center',
              backgroundSize: '1.5em 1.5em'
            }}
          >
            {metrics.map(metric => (
              <option key={metric.key} value={metric.key}>{metric.name}</option>
            ))}
          </select>
        </div>
        <div className="flex flex-wrap gap-2">
          {metrics.map(metric => (
            <button
              key={metric.key}
              onClick={() => handleMetricChange(metric)}
              className={`px-3 py-1 rounded-full text-sm ${
                activeMetric === metric.key
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {metric.name}
            </button>
          ))}
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
        >
          <XAxis
            dataKey="item_name"
            angle={-35}
            textAnchor="end"
            height={80}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{
            paddingTop: '40px',
          }} />
          <Bar
            dataKey={activeMetric}
            name={metrics.find(m => m.key === activeMetric)?.name}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductDemographicsChart;