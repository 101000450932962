import React from 'react';

const GradientSpinner = ({ size = 'md' }) => {
  const sizes = {
    sm: 'w-8 h-8',
    md: 'w-12 h-12',
    lg: 'w-16 h-16',
    xl: 'w-24 h-24'
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div
        className={`${sizes[size]} rounded-full relative`}
        style={{
          background: 'conic-gradient(from 0deg, transparent 0%, #60A5FA 40%, #9333EA 70%, transparent 100%)',
          animation: 'spin 1.2s linear infinite',
          boxShadow: '0 0 15px rgba(96, 165, 250, 0.3)'
        }}
      >
        <div
          className={`${sizes[size]} rounded-full absolute inset-0 flex items-center justify-center`}
          style={{
            background: '#1a1f2e',
            transform: 'scale(0.85)',
          }}
        />
      </div>
      <div className="text-blue-400 text-sm">Loading...</div>
    </div>
  );
};

export default GradientSpinner;