import React from "react";

function ClickBarIndicator() {
    return (
        <div
            className="absolute -top-8 right-0 flex items-center text-sm text-gray-600 bg-blue-50 px-3 py-1 rounded-full">
            <span>→ Click bars for product details</span>
        </div>
    )
}

export default ClickBarIndicator;