import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { TrendingUp, Zap, Brain, Bot, Box, ArrowRight } from 'lucide-react';

const impactMetrics = [
  { metric: "₹40Cr+", label: "Revenue Optimized", icon: TrendingUp },
  { metric: "28%", label: "Margin Growth", icon: Zap },
  { metric: "12K+", label: "Products Analyzed", icon: Box }
];

const caseStudies = [
  {
    title: "Leading D2C Beauty Brand",
    result: "+32% Revenue",
    description: "Smart pricing across premium beauty segment",
    stats: [
      { label: "Margin Growth", value: "+18%" },
      { label: "Stock Efficiency", value: "2.1x" }
    ],
    category: "Beauty & Personal Care"
  },
  {
    title: "Major Baby Care Retailer",
    result: "+45% Growth",
    description: "Inventory & supply chain optimization",
    stats: [
      { label: "Stockout Reduction", value: "62%" },
      { label: "Cost Savings", value: "28%" }
    ],
    category: "Baby Products"
  },
  {
    title: "Premium Grocery Chain",
    result: "+29% Orders",
    description: "AI-driven demand forecasting",
    stats: [
      { label: "Waste Reduction", value: "41%" },
      { label: "Delivery Efficiency", value: "58%" }
    ],
    category: "Fresh Grocery"
  }
];

const features = [
  'Real-time Price Intelligence',
  'Demand Forecasting',
  'Market Trend Analysis',
  'Customer Segmentation',
  'Supply Chain Optimization'
];

const JaanchAIOverview = () => {
  const [activeCase, setActiveCase] = useState(0);
  const [hoveredMetric, setHoveredMetric] = useState(null);
  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCase(prev => (prev + 1) % caseStudies.length);
      controls.start("visible");
    }, 6000);
    return () => clearInterval(interval);
  }, [controls]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <section className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black text-white">
      <div className="container mx-auto px-4 py-20">
        {/* Hero */}
        <motion.div 
          className="text-center mb-20"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div 
            variants={itemVariants}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-blue-500/10 to-purple-500/10 border border-blue-500/20 mb-6"
          >
            <Bot className="w-4 h-4 text-blue-400" />
            <span className="text-blue-400 text-sm">Trusted by India's Leading Brands</span>
          </motion.div>

          <motion.h1 
            variants={itemVariants}
            className="text-5xl md:text-7xl font-bold mb-6 tracking-tight"
          >
            <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
              Intelligent Commerce
            </span>
            <br />
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Made Simple
            </span>
          </motion.h1>
        </motion.div>

        {/* Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
          {impactMetrics.map((item, i) => (
            <motion.div
              key={i}
              variants={itemVariants}
              whileHover={{ 
                scale: 1.02,
                transition: { type: "spring", stiffness: 400 }
              }}
              className="relative group"
              onMouseEnter={() => setHoveredMetric(i)}
              onMouseLeave={() => setHoveredMetric(null)}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
              <div className="relative p-8 bg-gray-800/50 backdrop-blur-xl border border-gray-700/50 rounded-2xl">
                <motion.div 
                  animate={{
                    scale: hoveredMetric === i ? 1.1 : 1,
                    color: hoveredMetric === i ? "#60A5FA" : "#9CA3AF"
                  }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <item.icon className="w-8 h-8 mb-4" />
                </motion.div>
                <div className="text-4xl font-bold mb-2">{item.metric}</div>
                <div className="text-gray-400">{item.label}</div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Case Studies */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
            Success Stories
          </h2>
          
          <AnimatePresence mode="wait">
            <motion.div
              key={activeCase}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ type: "spring", stiffness: 100 }}
              className="grid grid-cols-1 lg:grid-cols-2 gap-8"
            >
              <div className="p-8 bg-gray-800/50 backdrop-blur-xl border border-gray-700/50 rounded-2xl relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-purple-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-500" />
                <div className="relative">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-2xl font-bold">{caseStudies[activeCase].title}</h3>
                    <span className="px-3 py-1 rounded-full bg-emerald-500/10 text-emerald-400 text-sm">
                      {caseStudies[activeCase].category}
                    </span>
                  </div>
                  <div className="text-3xl font-bold text-blue-400 mb-4">
                    {caseStudies[activeCase].result}
                  </div>
                  <p className="text-gray-400 mb-6">
                    {caseStudies[activeCase].description}
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    {caseStudies[activeCase].stats.map((stat, i) => (
                      <motion.div 
                        key={i}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ delay: i * 0.1 }}
                        className="p-4 bg-gray-900/50 rounded-xl"
                      >
                        <div className="text-xl font-bold text-white mb-1">{stat.value}</div>
                        <div className="text-sm text-gray-400">{stat.label}</div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="lg:p-8 flex items-center">
                <div className="space-y-4">
                  <h4 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent mb-6">
                    Powered by AI
                  </h4>
                  {features.map((feature, i) => (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: i * 0.1 }}
                      className="flex items-center gap-3 text-gray-400 group"
                    >
                      <div className="h-px w-6 bg-gray-700 group-hover:w-12 group-hover:bg-blue-400 transition-all duration-300" />
                      {feature}
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* CTA */}
        <motion.div 
          className="text-center"
          variants={itemVariants}
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="group relative px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl font-medium text-white flex items-center gap-2 mx-auto overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 translate-y-[100%] group-hover:translate-y-0 transition-transform" />
            <span className="relative">Experience the Future</span>
            <ArrowRight className="w-4 h-4 relative group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default JaanchAIOverview;