import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Settings, Bot, Zap, BadgeCheck, ArrowRight, Brain, Rocket, Activity, LineChart, BarChart2 } from 'lucide-react';

const EfficiencyMetrics = () => (
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-12">
    {[
      { label: 'Time Saved', value: '72%', icon: Activity },
      { label: 'Cost Reduction', value: '45%', icon: BarChart2 },
      { label: 'Task Automation', value: '89%', icon: Settings },
      { label: 'Error Reduction', value: '94%', icon: BadgeCheck }
    ].map((metric, i) => (
      <motion.div
        key={i}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: i * 0.1 }}
        className="relative group"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-red-500/20 to-pink-500/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-all duration-300" />
        <div className="relative bg-gray-800/50 border border-gray-700/50 rounded-xl p-4">
          <metric.icon className="w-5 h-5 text-red-400 mb-2" />
          <div className="text-2xl font-bold text-white">{metric.value}</div>
          <div className="text-sm text-gray-400">{metric.label}</div>
        </div>
      </motion.div>
    ))}
  </div>
);

const ProcessAnimation = () => (
  <div className="relative h-48">
    <motion.div 
      animate={{ 
        background: [
          'linear-gradient(90deg, #f87171 0%, #ec4899 100%)',
          'linear-gradient(90deg, #ec4899 0%, #f87171 100%)',
          'linear-gradient(90deg, #f87171 0%, #ec4899 100%)'
        ]
      }}
      transition={{ duration: 4, repeat: Infinity }}
      className="absolute inset-0 opacity-10 rounded-xl"
    />
    <div className="relative h-full grid grid-cols-3 gap-4 p-4">
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          initial={{ y: 20, opacity: 0 }}
          animate={{ 
            y: [20, 0, 20],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            delay: i * 0.5
          }}
          className="flex flex-col items-center justify-center gap-2"
        >
          <div className="p-2 rounded-lg bg-gray-800/50">
            <Activity className="w-4 h-4 text-red-400" />
          </div>
          <div className="h-1 w-16 bg-gradient-to-r from-red-500/20 to-pink-500/20 rounded-full" />
        </motion.div>
      ))}
    </div>
  </div>
);

const features = [
  {
    title: "AI Automation",
    subtitle: "Smart task automation",
    icon: Brain,
    color: "from-red-500 to-pink-500",
    gradient: "from-red-500/20 to-pink-500/20",
    metrics: [
      { label: "Tasks Automated", value: "89%" },
      { label: "Time Saved", value: "64hrs/mo" }
    ],
    features: [
      "Process automation",
      "Smart workflows",
      "Error prevention"
    ]
  },
  {
    title: "Performance Boost",
    subtitle: "Efficiency optimization",
    icon: Rocket,
    color: "from-purple-500 to-blue-500",
    gradient: "from-purple-500/20 to-blue-500/20",
    metrics: [
      { label: "Speed Increase", value: "+156%" },
      { label: "Resource Usage", value: "-42%" }
    ],
    features: [
      "Resource optimization",
      "Parallel processing",
      "Smart scheduling"
    ]
  },
  {
    title: "Smart Analytics",
    subtitle: "Real-time insights",
    icon: LineChart,
    color: "from-emerald-500 to-teal-500",
    gradient: "from-emerald-500/20 to-teal-500/20",
    metrics: [
      { label: "Accuracy", value: "98.7%" },
      { label: "Insights", value: "+312%" }
    ],
    features: [
      "Performance tracking",
      "Trend analysis",
      "Predictive insights"
    ]
  }
];

const EnhanceEfficiency = () => {
  return (
    <section className="relative overflow-hidden py-20">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,_var(--tw-gradient-stops))] from-red-500/10 via-pink-500/5 to-transparent" />
      </div>

      <div className="relative container mx-auto px-4">
        <motion.div className="text-center mb-16">
          <motion.div 
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-red-500/10 border border-red-500/20 mb-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Zap className="w-5 h-5 text-red-400" />
            <span className="text-red-400 text-sm">Efficiency Boost</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Supercharge Your
            </span>
            <br />
            <span className="bg-gradient-to-r from-red-400 to-pink-400 bg-clip-text text-transparent">
              Business Operations
            </span>
          </h2>
        </motion.div>

        <EfficiencyMetrics />

        <motion.div 
          className="mb-16 bg-gray-900/50 backdrop-blur-xl border border-gray-800/50 rounded-2xl p-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="flex items-center gap-3 mb-6">
            <Bot className="w-6 h-6 text-red-400" />
            <h3 className="text-2xl font-bold bg-gradient-to-r from-red-400 to-pink-400 bg-clip-text text-transparent">
              Success Story
            </h3>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <p className="text-gray-300">
                A leading D2C brand transformed their operations with our AI automation:
              </p>
              <div className="space-y-3">
                {[
                  { label: "Manual Tasks", value: "-82%", icon: Settings },
                  { label: "Processing Time", value: "-67%", icon: Activity },
                  { label: "Team Productivity", value: "+94%", icon: Rocket }
                ].map((stat, i) => (
                  <div key={i} className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-gray-800/50">
                      <stat.icon className="w-4 h-4 text-red-400" />
                    </div>
                    <div className="font-medium text-gray-300">{stat.label}</div>
                    <div className="text-red-400 font-bold">{stat.value}</div>
                  </div>
                ))}
              </div>
            </div>
            <ProcessAnimation />
          </div>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Rest of feature card code similar to previous components */}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EnhanceEfficiency;