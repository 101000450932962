import React, {useState} from 'react';
import {Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {COLORS} from "../../utils/constants";

const BranchProductsChart = ({data = {}}) => {
    const [selectedBranch, setSelectedBranch] = useState(Object.keys(data || {})[0] || '');
    const [activeMetric, setActiveMetric] = useState('net_amount');
    console.log(data)

    if (!data || data.length === 0) {
        return (
            <div className="flex items-center justify-center h-64 bg-white rounded-lg">
                <p className="text-gray-500">No data to show for the given date range</p>
            </div>
        );
    }

    const branches = Object.keys(data);

    const metrics = [
        {
            key: 'net_amount',
            name: 'Net Amount',
            color: '#2563eb',
            format: (value) => `₹${value.toLocaleString()}`
        },
        {
            key: 'total_quantity',
            name: 'Total Quantity',
            color: '#16a34a',
            format: (value) => value.toLocaleString()
        },

    ];

    // Process products data for selected branch
    const processedData = data[selectedBranch]
        .filter(product => product.product_name.toLowerCase() !== "others")
        .map(product => ({
            ...product,
            product_name: product.product_name.length > 15
                ? `${product.product_name.slice(0, 15)}...`
                : product.product_name,
            full_name: product.product_name
        }));

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            const productData = processedData.find(p => p.product_name === label);
            if (!productData) return null;

            return (
                <div className="bg-white p-4 border rounded-lg shadow-lg">
                    <p className="font-semibold text-gray-800 mb-2">{productData.full_name}</p>
                    <div className="space-y-1">
                        {metrics.map(metric => (
                            <p
                                key={metric.key}
                                className="text-sm"
                                style={{color: metric.color}}
                            >
                                {metric.name}: {metric.format(productData[metric.key])}
                            </p>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-lg">
            <div className="mb-4 flex flex-wrap gap-4 items-center">
                <div className="flex gap-2 items-center min-w-[150px]">
                    <span className="text-sm font-medium text-gray-700">Branch:</span>
                    <select
                        value={selectedBranch}
                        onChange={(e) => setSelectedBranch(e.target.value)}
                        className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 pl-3 pr-8 appearance-none w-24"
                        style={{
                            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 0.5rem center',
                            backgroundSize: '1.5em 1.5em'
                        }}
                    >
                        {branches.map(branch => (
                            <option key={branch} value={branch}>{branch}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Sales contribution % of product by branch */}
            <div className="mb-8">
                <h3 className="text-sm font-semibold text-gray-800 mb-4">Sales Contribution Distribution</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={processedData}
                            dataKey="sales_contribution_percentage"
                            nameKey="product_name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label={({
                                        cx,
                                        cy,
                                        midAngle,
                                        innerRadius,
                                        outerRadius,
                                        value,
                                        name
                                    }) => {
                                const RADIAN = Math.PI / 180;
                                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        fill="#000"
                                        textAnchor={x > cx ? 'start' : 'end'}
                                        dominantBaseline="central"
                                        className="text-xs"
                                    >
                                        {`${name} (${(value * 100).toFixed(1)}%)`}
                                    </text>)
                            }}
                        >
                            {processedData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                        <Tooltip
                            content={({active, payload}) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div className="bg-white p-2 border rounded shadow-lg">
                                            <p className="text-sm font-semibold">{payload[0].name}</p>
                                            <p className="text-sm text-gray-600">
                                                {`${(payload[0].value * 100).toFixed(2)}%`}
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>

            <div className="mb-4 flex flex-wrap gap-4 items-center">
                <div className="flex flex-wrap gap-2">
                    {metrics.map(metric => (
                        <button
                            key={metric.key}
                            onClick={() => setActiveMetric(metric.key)}
                            className={`px-3 py-1 rounded-full text-sm ${
                                activeMetric === metric.key
                                    ? 'bg-blue-600 text-white'
                                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                            }`}
                        >
                            {metric.name}
                        </button>
                    ))}
                </div>
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={processedData}
                    margin={{top: 20, right: 30, left: 40, bottom: 20}}
                >
                    <XAxis
                        dataKey="product_name"
                        angle={-35}
                        textAnchor="end"
                        height={80}
                        interval={0}
                        tick={{fontSize: 12}}
                    />
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend/>
                    <Bar
                        dataKey={activeMetric}
                        name={metrics.find(m => m.key === activeMetric)?.name}
                        fill={metrics.find(m => m.key === activeMetric)?.color}
                        radius={[4, 4, 0, 0]}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BranchProductsChart;