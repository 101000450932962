import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {Activity, ArrowDownCircle, ArrowUpCircle, Boxes, ChevronDown, Sparkles, Store, TrendingUp} from 'lucide-react';
import {useSelector} from "react-redux";
import ForecastingSection from "./ForecastingSection"
import {kpiService} from "../../utils/axios";

const DashboardHero = () => {
    const { user } = useSelector(state => state.user);
    const [quickStats, setQuickStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const iconMapping = {
        'operations': Store,
        'inventory': Boxes,
        'finance': Activity,
        'performance': TrendingUp
    };

    useEffect(() => {
        const fetchDashboardKPIs = async () => {
            try {
                setLoading(true);

                const data = await kpiService.getDashboardKPIData({
                    organization_id: user.organization_id
                });

                const formattedStats = Object.entries(data).map(([key, stat]) => ({
                    key,
                    label: stat.name,
                    value: stat.metric.value.toLocaleString(),
                    icon: iconMapping[stat.category] || Activity,
                    color: stat.color,
                    trend: stat.metric.trend,
                    changePercentage: stat.metric.change_percentage,
                    previousValue: stat.metric.previous_value,
                    comparisonPeriod: stat.comparison_period
                }));

                setQuickStats(formattedStats);
            } catch (err) {
                console.error('Error fetching KPIs:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardKPIs();
    }, [user.organization_id]);

    const getColorClasses = (color) => {
        const colors = {
            blue: 'bg-blue-500/10 text-blue-400 border-blue-500/20 ring-blue-400/20',
            purple: 'bg-purple-500/10 text-purple-400 border-purple-500/20 ring-purple-400/20',
            green: 'bg-green-500/10 text-green-400 border-green-500/20 ring-green-400/20',
            orange: 'bg-orange-500/10 text-orange-400 border-orange-500/20 ring-orange-400/20',
            red: 'bg-red-500/10 text-red-400 border-red-500/20 ring-red-400/20',
        };
        return colors[color.toLowerCase()] || colors.blue;
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    };

    const renderTrendIndicator = (trend, changePercentage) => {
        if (!changePercentage) return null;

        const Icon = trend === 'up' ? ArrowUpCircle : ArrowDownCircle;
        const colorClass = trend === 'up' ? 'text-green-400' : 'text-red-400';

        return (
            <div className={`flex items-center gap-1 ${colorClass} text-sm`}>
                <Icon className="w-4 h-4" />
                <span>{Math.abs(changePercentage)}%</span>
            </div>
        );
    };

    if (error) {
        return (
            <div className="min-h-screen bg-gray-900 flex items-center justify-center">
                <div className="text-red-400 text-lg">
                    Error loading dashboard stats: {error}
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 flex flex-col relative overflow-hidden">
            {/* Background gradient */}
            <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent" />

            <div className="flex-grow flex flex-col justify-start px-4 sm:px-6 lg:px-8 py-16 relative">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="max-w-7xl mx-auto w-full space-y-12"
                >
                    {/* Welcome Section */}
                    <motion.div variants={itemVariants} className="text-center space-y-6">
                        <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm">
                            <Sparkles className="w-4 h-4 text-blue-400 mr-2" />
                            <span className="text-blue-400 text-sm font-medium">Welcome to your command center</span>
                        </div>

                        <h1 className="text-4xl md:text-6xl font-bold">
                            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
                                Welcome back,{' '}
                            </span>
                            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                                {user?.full_name?.split(' ')[0] || 'User'}
                            </span>
                        </h1>

                        <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                            Get real-time insights and AI-powered analytics across your business operations
                        </p>
                    </motion.div>

                    {/* Stats Grid */}
                    <motion.div
                        variants={containerVariants}
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
                    >
                        {quickStats.map((stat) => {
                            const IconComponent = stat.icon;
                            return (
                                <motion.div
                                    key={stat.label}
                                    variants={itemVariants}
                                    className="group relative"
                                >
                                    <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-gray-800 to-gray-700 opacity-0 group-hover:opacity-100 transition-opacity" />
                                    <div className="relative bg-gray-800/50 backdrop-blur border border-gray-700/50 rounded-2xl p-6 hover:border-gray-600/50 transition-colors">
                                        <div className="flex items-center justify-between mb-4">
                                            <div className={`p-3 rounded-xl ring-1 ${getColorClasses(stat.color)}`}>
                                                <IconComponent className="w-6 h-6" />
                                            </div>
                                            <div className="text-right">
                                                <span className="text-3xl font-bold text-white">{stat.value}</span>
                                                {renderTrendIndicator(stat.trend, stat.changePercentage)}
                                            </div>
                                        </div>
                                        <h3 className="text-gray-400 font-medium">{stat.label}</h3>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </motion.div>

                    {/* Forecasting Section */}
                    <motion.div variants={itemVariants} className="mt-12">
                        <ForecastingSection organization_id={user.organization_id} />
                    </motion.div>

                    {/* Scroll Indicator */}
                    <motion.div variants={itemVariants} className="flex justify-center pt-8">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="group relative px-8 py-4 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium flex items-center gap-3 hover:from-blue-600 hover:to-blue-700 transition-colors"
                        >
                            <TrendingUp className="w-5 h-5" />
                            <span>Explore Detailed Analytics</span>
                        </motion.button>
                    </motion.div>
                </motion.div>
            </div>

            {/* Scroll Indicator */}
            <motion.button
                className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-gray-400 hover:text-gray-300 transition-colors"
                animate={{ y: [0, 10, 0] }}
                transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "loop"
                }}
                aria-label="Scroll to analytics dashboard"
            >
                <ChevronDown className="w-6 h-6" />
            </motion.button>
        </div>
    );
};

export default DashboardHero;