import React from 'react';
import { motion } from 'framer-motion';
import { Bot, Brain, Zap, BarChart3, Box, Settings, Sparkles } from 'lucide-react';

const features = [
  {
    name: 'AI Decision Engine',
    icon: Brain,
    description: 'Automate complex decisions with minimal intervention',
    gradient: 'from-blue-500/20 to-cyan-500/20',
    border: 'border-blue-500/20',
    color: 'text-blue-400'
  },
  {
    name: 'Real-Time Analytics',
    icon: BarChart3,
    description: 'Get instant insights from your business data',
    gradient: 'from-purple-500/20 to-pink-500/20',
    border: 'border-purple-500/20',
    color: 'text-purple-400'
  },
  {
    name: 'Smart Optimization',
    icon: Sparkles,
    description: 'Optimize pricing and inventory dynamically',
    gradient: 'from-emerald-500/20 to-teal-500/20',
    border: 'border-emerald-500/20',
    color: 'text-emerald-400'
  },
  {
    name: 'Lightning Fast',
    icon: Zap,
    description: 'Process millions of data points in seconds',
    gradient: 'from-amber-500/20 to-orange-500/20',
    border: 'border-amber-500/20',
    color: 'text-amber-400'
  },
  {
    name: 'Supply Chain AI',
    icon: Box,
    description: 'Predict and optimize your logistics flow',
    gradient: 'from-red-500/20 to-rose-500/20',
    border: 'border-red-500/20',
    color: 'text-red-400'
  },
  {
    name: 'Easy Integration',
    icon: Settings,
    description: 'Connect with your existing tech stack',
    gradient: 'from-indigo-500/20 to-violet-500/20',
    border: 'border-indigo-500/20',
    color: 'text-indigo-400'
  }
];

const JaanchAIFeatures = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <section className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black text-white relative overflow-hidden py-20">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="space-y-16"
        >
          {/* Header */}
          <div className="text-center space-y-6">
            <motion.div 
              variants={itemVariants}
              className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 mb-6"
            >
              <Bot className="w-4 h-4 text-blue-400" />
              <span className="text-blue-400 text-sm">AI-Powered Features</span>
            </motion.div>

            <motion.h2 
              variants={itemVariants}
              className="text-4xl md:text-6xl font-bold"
            >
              <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
                Supercharge Your
              </span>
              <br />
              <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
                Business Intelligence
              </span>
            </motion.h2>
          </div>

          {/* Features Grid */}
          <motion.div 
            variants={containerVariants}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.02,
                  transition: { type: "spring", stiffness: 400 }
                }}
                className="group relative"
              >
                <div className={`h-full p-8 bg-gradient-to-br ${feature.gradient} backdrop-blur-xl border ${feature.border} rounded-2xl`}>
                  <div className="relative z-10 flex flex-col h-full">
                    <div className={`p-3 rounded-xl bg-gray-900/50 ${feature.color} w-fit mb-4 group-hover:scale-110 transition-transform`}>
                      <feature.icon className="w-6 h-6" />
                    </div>
                    <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-400 transition-colors">
                      {feature.name}
                    </h3>
                    <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* CTA */}
          <motion.div 
            variants={itemVariants}
            className="text-center"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group relative px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl font-medium text-white inline-flex items-center gap-2 overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 translate-y-[100%] group-hover:translate-y-0 transition-transform" />
              <span className="relative">Experience the Power of AI</span>
              <Sparkles className="w-4 h-4 relative" />
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default JaanchAIFeatures;