import React from 'react';
import {FaArrowDown, FaArrowUp, FaChartLine, FaShoppingCart, FaUsers, FaWarehouse} from 'react-icons/fa';
import {motion} from 'framer-motion';

// TODO: @kanishk use api endpoint once provided to populate this with dummy data
const MonthlySummary = () => {
    // Dummy data
    const metrics = [
        {
            title: 'Total Sales',
            value: '₹47.2L',
            change: '+12.5%',
            isPositive: true,
            icon: FaChartLine,
            detail: '₹42.1L last month',
            color: 'blue'
        },
        {
            title: 'Total Orders',
            value: '1,483',
            change: '+8.2%',
            isPositive: true,
            icon: FaShoppingCart,
            detail: '1,371 last month',
            color: 'purple'
        },
        {
            title: 'New Customers',
            value: '286',
            change: '-3.1%',
            isPositive: false,
            icon: FaUsers,
            detail: '295 last month',
            color: 'green'
        },
        {
            title: 'Stock Value',
            value: '₹92.8L',
            change: '+5.4%',
            isPositive: true,
            icon: FaWarehouse,
            detail: '₹88.1L last month',
            color: 'orange'
        }
    ];

    const getColorClasses = (color) => {
        const colors = {
            blue: 'bg-blue-500/10 text-blue-400 border-blue-500/20',
            purple: 'bg-purple-500/10 text-purple-400 border-purple-500/20',
            green: 'bg-green-500/10 text-green-400 border-green-500/20',
            orange: 'bg-orange-500/10 text-orange-400 border-orange-500/20'
        };
        return colors[color] || colors.blue;
    };

    return (
        <div className="w-full bg-gray-800 rounded-lg p-6 mb-8 border border-gray-700">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {metrics.map((metric, index) => {
                    const IconComponent = metric.icon;
                    return (
                        <motion.div
                            key={metric.title}
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.3, delay: index * 0.1}}
                            className="relative bg-gray-900 rounded-lg p-6 border border-gray-700"
                        >
                            <div className="flex justify-between items-start mb-4">
                                <div>
                                    <p className="text-gray-400 text-sm mb-1">{metric.title}</p>
                                    <h3 className="text-2xl font-bold text-white">{metric.value}</h3>
                                </div>
                                <div className={`p-3 rounded-lg ${getColorClasses(metric.color)}`}>
                                    <IconComponent className="w-6 h-6"/>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    {metric.isPositive ? (
                                        <FaArrowUp className="w-4 h-4 text-green-400 mr-1"/>
                                    ) : (
                                        <FaArrowDown className="w-4 h-4 text-red-400 mr-1"/>
                                    )}
                                    <span
                                        className={`text-sm ${metric.isPositive ? 'text-green-400' : 'text-red-400'}`}>
                                        {metric.change}
                                    </span>
                                </div>
                                <p className="text-gray-500 text-sm">{metric.detail}</p>
                            </div>
                        </motion.div>
                    );
                })}
            </div>
        </div>
    );
};

export default MonthlySummary;

/* Commented out API integration code for future use:
const [summaryData, setSummaryData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
    const fetchSummaryData = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/api/v1/monthly-summary`);
            if (!response.ok) {
                throw new Error('Failed to fetch summary data');
            }
            const data = await response.json();
            setSummaryData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    fetchSummaryData();
}, []);

if (loading) {
    return (
        <div className="w-full bg-gray-800 rounded-lg p-6 mb-8">
            <div className="animate-pulse flex space-x-6">
                {[1, 2, 3, 4].map((i) => (
                    <div key={i} className="flex-1 space-y-4">
                        <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                        <div className="h-8 bg-gray-700 rounded"></div>
                        <div className="h-4 bg-gray-700 rounded w-1/2"></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

if (error) {
    return (
        <div className="w-full bg-gray-800 rounded-lg p-6 mb-8">
            <p className="text-red-400">Error loading summary: {error}</p>
        </div>
    );
}
*/