import React, {useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {AlertCircle, Check, FileSpreadsheet, Upload, X} from 'lucide-react';
import {useSelector} from 'react-redux';
import {handleRawDataService} from "../../utils/axios";

const FileUploadModal = ({isOpen, onClose}) => {
    const [processedStock, setProcessedStock] = useState(null);
    const [processedSales, setProcessedSales] = useState(null);
    const [duplicateSales, setDuplicateSales] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const {user: {organization_id}} = useSelector(state => state.user);

    const handleFileChange = (event, fileType) => {
        const file = event.target.files[0];
        if (file) {
            switch (fileType) {
                case 'processedStock':
                    setProcessedStock(file);
                    break;
                case 'processedSales':
                    setProcessedSales(file);
                    break;
                case 'duplicateSales':
                    setDuplicateSales(file);
                    break;
            }
        }
    };

    const handleUpload = async () => {
        if (!processedStock || !processedSales || !duplicateSales) {
            setError('Please select all required files');
            return;
        }

        setUploading(true);
        setError('');
        setSuccess('');

        const formData = new FormData();
        formData.append('processed_stock', processedStock);
        formData.append('processed_sales', processedSales);
        formData.append('duplicate_sales', duplicateSales);

        try {
            await handleRawDataService.uploadForIngestion(formData, {
                organization_id: organization_id,
            })

            setSuccess('Files uploaded successfully!');
            setTimeout(() => {
                onClose();
                setProcessedStock(null);
                setProcessedSales(null);
                setDuplicateSales(null);
                setSuccess('');
            }, 2000);
        } catch (err) {
            setError(err.message || 'Failed to upload files. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
                >
                    <motion.div
                        initial={{scale: 0.95, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        exit={{scale: 0.95, opacity: 0}}
                        className="w-full max-w-md bg-gray-900 rounded-xl shadow-xl border border-gray-800"
                    >
                        <div className="flex justify-between items-center p-4 border-b border-gray-800">
                            <h2 className="text-lg font-semibold text-gray-200">Upload Data Files</h2>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-300 transition-colors"
                            >
                                <X className="w-5 h-5"/>
                            </button>
                        </div>

                        <div className="p-4 space-y-4">
                            {error && (
                                <div
                                    className="flex items-center gap-2 p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
                                    <AlertCircle className="w-4 h-4"/>
                                    <p className="text-sm">{error}</p>
                                </div>
                            )}

                            {success && (
                                <div
                                    className="flex items-center gap-2 p-3 bg-green-500/10 border border-green-500/20 rounded-lg text-green-400">
                                    <Check className="w-4 h-4"/>
                                    <p className="text-sm">{success}</p>
                                </div>
                            )}

                            {/* Processed Stock File Upload */}
                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Processed Stock Data (CSV/Excel) *
                                </label>
                                <div className="relative">
                                    <input
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'processedStock')}
                                        accept=".csv,.xlsx,.xls"
                                        className="hidden"
                                        id="processed-stock-file"
                                        disabled={uploading}
                                    />
                                    <label
                                        htmlFor="processed-stock-file"
                                        className="flex items-center justify-between w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg cursor-pointer hover:bg-gray-800 transition-colors"
                                    >
                                        <div className="flex items-center gap-2">
                                            <FileSpreadsheet className="w-5 h-5 text-blue-400"/>
                                            <span className="text-sm text-gray-300">
                        {processedStock ? processedStock.name : 'Choose file'}
                      </span>
                                        </div>
                                        <Upload className="w-4 h-4 text-gray-400"/>
                                    </label>
                                </div>
                            </div>

                            {/* Processed Sales File Upload */}
                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Processed Sales Data (CSV/Excel) *
                                </label>
                                <div className="relative">
                                    <input
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'processedSales')}
                                        accept=".csv,.xlsx,.xls"
                                        className="hidden"
                                        id="processed-sales-file"
                                        disabled={uploading}
                                    />
                                    <label
                                        htmlFor="processed-sales-file"
                                        className="flex items-center justify-between w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg cursor-pointer hover:bg-gray-800 transition-colors"
                                    >
                                        <div className="flex items-center gap-2">
                                            <FileSpreadsheet className="w-5 h-5 text-green-400"/>
                                            <span className="text-sm text-gray-300">
                                                {processedSales ? processedSales.name : 'Choose file'}
                                            </span>
                                        </div>
                                        <Upload className="w-4 h-4 text-gray-400"/>
                                    </label>
                                </div>
                            </div>

                            {/* Duplicate Sales File Upload */}
                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Duplicate Sales Data (CSV/Excel) *
                                </label>
                                <div className="relative">
                                    <input
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'duplicateSales')}
                                        accept=".csv,.xlsx,.xls"
                                        className="hidden"
                                        id="duplicate-sales-file"
                                        disabled={uploading}
                                    />
                                    <label
                                        htmlFor="duplicate-sales-file"
                                        className="flex items-center justify-between w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg cursor-pointer hover:bg-gray-800 transition-colors"
                                    >
                                        <div className="flex items-center gap-2">
                                            <FileSpreadsheet className="w-5 h-5 text-yellow-400"/>
                                            <span className="text-sm text-gray-300">
                                                {duplicateSales ? duplicateSales.name : 'Choose file'}
                                            </span>
                                        </div>
                                        <Upload className="w-4 h-4 text-gray-400"/>
                                    </label>
                                </div>
                            </div>

                            <p className="text-xs text-gray-400">* All files are required</p>
                        </div>

                        <div className="p-4 border-t border-gray-800">
                            <button
                                onClick={handleUpload}
                                disabled={uploading || !processedStock || !processedSales || !duplicateSales}
                                className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                            >
                                {uploading ? (
                                    <>
                                        <div
                                            className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin"/>
                                        <span>Uploading...</span>
                                    </>
                                ) : (
                                    <>
                                        <Upload className="w-4 h-4"/>
                                        <span>Upload Files</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default FileUploadModal;