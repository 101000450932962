import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { TrendingUp, Users, Star, Heart, ArrowRight, Bot, BadgeCheck, LineChart, Activity, BarChart } from 'lucide-react';
import Modal from '../Modal';
import { Link } from 'react-router-dom';

const SuccessMetrics = () => (
  <div className="grid grid-cols-3 gap-4 mb-8">
    {[
      { label: 'Revenue Growth', value: '+47%', icon: TrendingUp },
      { label: 'Customer Retention', value: '+32%', icon: Users },
      { label: 'Satisfaction Rate', value: '96%', icon: Star }
    ].map((metric, i) => (
      <motion.div
        key={i}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: i * 0.1 }}
        className="bg-gray-800/50 border border-gray-700/50 rounded-xl p-4"
      >
        <metric.icon className="w-5 h-5 text-blue-400 mb-2" />
        <div className="text-2xl font-bold text-white">{metric.value}</div>
        <div className="text-sm text-gray-400">{metric.label}</div>
      </motion.div>
    ))}
  </div>
);

const AnimatedChart = () => (
  <div className="relative h-48">
    <motion.div
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 2, ease: "easeInOut" }}
      className="absolute inset-0"
    >
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <motion.path
          d="M0,50 Q25,10 50,50 T100,50"
          fill="none"
          stroke="url(#blue-gradient)"
          strokeWidth="2"
        />
        <defs>
          <linearGradient id="blue-gradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#3B82F6" />
            <stop offset="100%" stopColor="#8B5CF6" />
          </linearGradient>
        </defs>
      </svg>
    </motion.div>
  </div>
);

const BoostSales = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const strategies = [
    {
      title: "AI-Driven Retention",
      subtitle: "Keep customers engaged",
      icon: Users,
      color: "from-emerald-500 to-teal-500",
      gradient: "from-emerald-500/20 to-teal-500/20",
      border: "border-emerald-500/20",
      iconColor: "text-emerald-400",
      metrics: [
        { label: "Churn Reduction", value: "-45%" },
        { label: "Engagement Rate", value: "+67%" }
      ],
      features: [
        "Smart segmentation",
        "Predictive analytics",
        "Automated campaigns"
      ]
    },
    {
      title: "Smart Satisfaction",
      subtitle: "Elevate experiences",
      icon: Star,
      color: "from-blue-500 to-cyan-500",
      gradient: "from-blue-500/20 to-cyan-500/20",
      border: "border-blue-500/20",
      iconColor: "text-blue-400",
      metrics: [
        { label: "CSAT Score", value: "94%" },
        { label: "Response Time", value: "-60%" }
      ],
      features: [
        "Real-time feedback",
        "Sentiment analysis",
        "Smart responses"
      ]
    },
    {
      title: "Value Maximizer",
      subtitle: "Grow customer value",
      icon: Heart,
      color: "from-purple-500 to-pink-500",
      gradient: "from-purple-500/20 to-pink-500/20",
      border: "border-purple-500/20",
      iconColor: "text-purple-400",
      metrics: [
        { label: "AOV Growth", value: "+38%" },
        { label: "CLV Boost", value: "+55%" }
      ],
      features: [
        "Dynamic pricing",
        "Cross-sell AI",
        "Loyalty automation"
      ]
    }
  ];

  return (
    <section className="relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
      </div>

      <div className="relative container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <motion.div 
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500/10 to-purple-500/10 border border-blue-500/20 mb-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Bot className="w-5 h-5 text-blue-400" />
            <span className="text-blue-400 text-sm font-medium">AI-Powered Growth</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Transform Your
            </span>
            <br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Sales Performance
            </span>
          </h2>
        </motion.div>

        <SuccessMetrics />

        <motion.div 
          className="mb-16 bg-gray-900/50 backdrop-blur-xl border border-gray-800/50 rounded-2xl p-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="flex items-center gap-3 mb-6">
            <BadgeCheck className="w-6 h-6 text-emerald-400" />
            <h3 className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent">
              Success Story
            </h3>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <p className="text-gray-300 mb-4 leading-relaxed">
                A leading beauty brand transformed their operations with our AI-powered analytics, achieving unprecedented growth in sales and customer retention.
              </p>
              <div className="space-y-3">
                {[
                  { label: "Revenue Increase", value: "+127%", icon: LineChart },
                  { label: "Customer Retention", value: "+45%", icon: Activity },
                  { label: "Market Share Growth", value: "+32%", icon: BarChart }
                ].map((stat, i) => (
                  <div key={i} className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-gray-800/50">
                      <stat.icon className="w-4 h-4 text-emerald-400" />
                    </div>
                    <div className="font-medium text-gray-300">{stat.label}</div>
                    <div className="text-emerald-400 font-bold">{stat.value}</div>
                  </div>
                ))}
              </div>
            </div>
            <AnimatedChart />
          </div>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-6">
          {strategies.map((strategy, index) => (
            <motion.div
              key={strategy.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              <div className={`p-1 rounded-2xl bg-gradient-to-br ${strategy.gradient}`}>
                <div className="bg-gray-900/90 backdrop-blur-xl rounded-xl p-6">
                  <div className={`p-3 rounded-xl bg-gradient-to-br ${strategy.gradient} mb-4 w-fit`}>
                    <strategy.icon className={`w-6 h-6 ${strategy.iconColor}`} />
                  </div>

                  <h3 className="text-xl font-bold text-white mb-1">{strategy.title}</h3>
                  <p className="text-gray-400 text-sm mb-4">{strategy.subtitle}</p>

                  <div className="space-y-4 mb-6">
                    {strategy.metrics.map((metric, i) => (
                      <div key={i} className="flex justify-between items-center">
                        <span className="text-gray-400 text-sm">{metric.label}</span>
                        <span className="font-bold text-white">{metric.value}</span>
                      </div>
                    ))}
                  </div>

                  <div className="space-y-2">
                    {strategy.features.map((feature, i) => (
                      <div key={i} className="flex items-center gap-2 text-sm text-gray-400">
                        <div className="w-1 h-1 rounded-full bg-gray-600" />
                        {feature}
                      </div>
                    ))}
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`mt-6 w-full bg-gradient-to-r ${strategy.color} rounded-xl py-2 px-4 text-white font-medium flex items-center justify-center gap-2`}
                  >
                    Learn More
                    <ArrowRight className="w-4 h-4" />
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BoostSales;