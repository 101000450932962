import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Package, TrendingDown, Bot, BadgeCheck, CircleDollarSign, AlertCircle, Box, TrendingUp, ArrowRight, LineChart, Activity } from 'lucide-react';

const InventoryMetrics = () => (
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-12">
    {[
      { label: 'Stockout Reduction', value: '-42%', icon: AlertCircle },
      { label: 'Inventory Turnover', value: '+63%', icon: TrendingUp },
      { label: 'Cost Reduction', value: '-28%', icon: CircleDollarSign },
      { label: 'Order Accuracy', value: '99.8%', icon: Box }
    ].map((metric, i) => (
      <motion.div
        key={i}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: i * 0.1 }}
        className="relative group"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-all duration-300" />
        <div className="relative bg-gray-800/50 border border-gray-700/50 rounded-xl p-4">
          <metric.icon className="w-5 h-5 text-purple-400 mb-2" />
          <div className="text-2xl font-bold text-white">{metric.value}</div>
          <div className="text-sm text-gray-400">{metric.label}</div>
        </div>
      </motion.div>
    ))}
  </div>
);

const AnimatedInventory = () => (
  <div className="relative h-48">
    {/* Grid background */}
    <div className="absolute inset-0 grid grid-cols-6 gap-2">
      {Array.from({ length: 24 }).map((_, i) => (
        <motion.div
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: [0.1, 0.2, 0.1] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            delay: i * 0.1
          }}
          className="bg-purple-500/10 rounded"
        />
      ))}
    </div>
    
    {/* Inventory level line */}
    <svg className="absolute inset-0 w-full h-full">
      <motion.path
        d="M0,40 C60,40 60,100 120,100 S180,20 240,20"
        fill="none"
        stroke="url(#purpleGradient)"
        strokeWidth="2"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 2 }}
      />
      <defs>
        <linearGradient id="purpleGradient" x1="0" y1="0" x2="100%" y2="0">
          <stop offset="0%" stopColor="#A855F7" />
          <stop offset="100%" stopColor="#EC4899" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

const features = [
  {
    title: "Smart Stock Prediction",
    subtitle: "AI-powered forecasting",
    icon: TrendingUp,
    color: "from-purple-500 to-pink-500",
    gradient: "from-purple-500/20 to-pink-500/20",
    metrics: [
      { label: "Forecast Accuracy", value: "94%" },
      { label: "Lead Time", value: "-35%" }
    ],
    features: [
      "Demand forecasting",
      "Seasonal analysis",
      "Trend detection"
    ]
  },
  {
    title: "Stock Optimization",
    subtitle: "Real-time management",
    icon: Box,
    color: "from-blue-500 to-cyan-500",
    gradient: "from-blue-500/20 to-cyan-500/20",
    metrics: [
      { label: "Stock Turnover", value: "+58%" },
      { label: "Space Usage", value: "+42%" }
    ],
    features: [
      "Auto-reordering",
      "Space optimization",
      "Stock allocation"
    ]
  },
  {
    title: "Cost Control",
    subtitle: "Expense reduction",
    icon: CircleDollarSign,
    color: "from-emerald-500 to-teal-500",
    gradient: "from-emerald-500/20 to-teal-500/20",
    metrics: [
      { label: "Carrying Cost", value: "-32%" },
      { label: "Dead Stock", value: "-45%" }
    ],
    features: [
      "Cost tracking",
      "Vendor analysis",
      "Budget optimization"
    ]
  }
];

const StreamlineInventory = () => {
  return (
    <section className="relative overflow-hidden py-20">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,_var(--tw-gradient-stops))] from-purple-500/10 via-pink-500/5 to-transparent" />
      </div>

      <div className="relative container mx-auto px-4">
        <motion.div className="text-center mb-16">
          <motion.div 
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-purple-500/10 border border-purple-500/20 mb-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Bot className="w-5 h-5 text-purple-400" />
            <span className="text-purple-400 text-sm">Inventory Intelligence</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Smart Inventory
            </span>
            <br />
            <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
              Management
            </span>
          </h2>
        </motion.div>

        <InventoryMetrics />

        <motion.div 
          className="mb-16 bg-gray-900/50 backdrop-blur-xl border border-gray-800/50 rounded-2xl p-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="flex items-center gap-3 mb-6">
            <BadgeCheck className="w-6 h-6 text-emerald-400" />
            <h3 className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent">
              Success Story
            </h3>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <p className="text-gray-300">
                A leading fashion retailer revolutionized their inventory management with our AI system:
              </p>
              <div className="space-y-3">
                {[
                  { label: "Cost Savings", value: "₹2.8Cr", icon: CircleDollarSign },
                  { label: "Efficiency Gain", value: "+67%", icon: Activity },
                  { label: "ROI Achieved", value: "486%", icon: LineChart }
                ].map((stat, i) => (
                  <div key={i} className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-gray-800/50">
                      <stat.icon className="w-4 h-4 text-emerald-400" />
                    </div>
                    <div className="font-medium text-gray-300">{stat.label}</div>
                    <div className="text-emerald-400 font-bold">{stat.value}</div>
                  </div>
                ))}
              </div>
            </div>
            <AnimatedInventory />
          </div>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              <div className={`p-1 rounded-2xl bg-gradient-to-br ${feature.gradient}`}>
                <div className="bg-gray-900/90 backdrop-blur-xl rounded-xl p-6">
                  <div className={`p-3 rounded-xl bg-gradient-to-br ${feature.gradient} mb-4 w-fit`}>
                    <feature.icon className="w-6 h-6 text-white" />
                  </div>

                  <h3 className="text-xl font-bold text-white mb-1">{feature.title}</h3>
                  <p className="text-gray-400 text-sm mb-4">{feature.subtitle}</p>

                  <div className="space-y-4 mb-6">
                    {feature.metrics.map((metric, i) => (
                      <div key={i} className="flex justify-between items-center">
                        <span className="text-gray-400 text-sm">{metric.label}</span>
                        <span className="font-bold text-white">{metric.value}</span>
                      </div>
                    ))}
                  </div>

                  <div className="space-y-2">
                    {feature.features.map((feat, i) => (
                      <div key={i} className="flex items-center gap-2 text-sm text-gray-400">
                        <div className="w-1 h-1 rounded-full bg-gray-600" />
                        {feat}
                      </div>
                    ))}
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`mt-6 w-full bg-gradient-to-r ${feature.color} rounded-xl py-2 px-4 text-white font-medium flex items-center justify-center gap-2`}
                  >
                    Learn More
                    <ArrowRight className="w-4 h-4" />
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StreamlineInventory;