import React, {useEffect, useRef, useState} from 'react';
import {FaPaperPlane, FaRobot} from 'react-icons/fa';
import Layout from '../Layout';

const WhatsAppStyleChat = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const chatEndRef = useRef(null);

  const predefinedInsights = [
    "Sales have increased by 15% this quarter. Top product: Smartphone Pro Max.",
    "Inventory levels are optimal, with a turnover ratio of 6.5.",
    "Customer satisfaction is at 85%, but churn in the 18-25 age group is concerning.",
    "Revenue forecast shows a 12% growth next quarter, but rising costs could impact margins."
  ];

  useEffect(() => {
    // Auto-scroll to the bottom whenever messages are updated
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = () => {
    if (userInput.trim() !== '') {
      const newMessage = { text: userInput, type: 'user', timestamp: new Date() };
      setMessages([...messages, newMessage]);
      setUserInput('');
      setTimeout(() => {
        handleJaanchAIResponse();
      }, 1000); // Simulate delay for JaanchAI response
    }
  };

  const handleJaanchAIResponse = () => {
    const randomInsight = predefinedInsights[Math.floor(Math.random() * predefinedInsights.length)];
    const newMessage = { text: randomInsight, type: 'bot', timestamp: new Date() };
    setMessages([...messages, newMessage]);
  };

  const formatTimestamp = (timestamp) => {
    return `${timestamp.getHours()}:${timestamp.getMinutes().toString().padStart(2, '0')}`;
  };

  return (
    <Layout>
      <div className="p-6 bg-gray-100 h-screen flex flex-col">
        <div className="flex items-center justify-between bg-green-500 text-white px-4 py-2 rounded-t-lg">
          <div className="flex items-center">
            <FaRobot className="text-2xl mr-2" />
            <h2 className="text-xl font-semibold">JaanchAI Insights</h2>
          </div>
        </div>

        <div className="flex-grow p-4 bg-white rounded-b-lg shadow-inner overflow-y-auto">
          {messages.map((message, index) => (
            <div key={index} className={`mb-4 flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`max-w-xs px-4 py-2 rounded-lg ${message.type === 'user' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
                <p>{message.text}</p>
                <small className="block mt-1 text-xs text-right">{formatTimestamp(message.timestamp)}</small>
              </div>
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="bg-white p-4 flex rounded-lg shadow-lg mt-4">
          <input
            type="text"
            className="flex-grow border border-gray-300 rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500"
            placeholder="Type your question..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-r-lg focus:outline-none"
            onClick={handleSendMessage}
          >
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default WhatsAppStyleChat;
