
import TopCustomersChart from "./visualizations/TopCustomersChart";
import BranchPerformanceChart from "./visualizations/BranchPerformanceChart";
import BranchProductsChart from "./visualizations/BranchProductsChart";
import ProductDemographicsChart from "./visualizations/ProductDemographicsChart";
import MarketingChannelsChart from "./visualizations/MarketingChannelsChart";
import ColorPerformanceChart from "./visualizations/ColorPerformanceChart";
import DeadStockChart from "./visualizations/DeadStockChart";
import ProductTypesChart from "./visualizations/ProductTypesChart";
import ProductGroupsChart from "./visualizations/ProductGroupsChart";
import PackGradesChart from "./visualizations/PackGradesChart";
import ProductAgeAnalysisChart from "./visualizations/ProductAgeAnalysisChart";
import ProductLifecycleChart from "./visualizations/ProductLifecycleChart";
import StockTurnoverChart from "./visualizations/StockTurnoverChart";
import StockoutsAnalysis from "./visualizations/StockoutsChart";
import OptimalStockLevels from "./visualizations/OptimalStockLevelsChart";
import BranchTransfers from "./visualizations/BranchTransfersChart";

export const CHART_COMPONENTS = {
    'Top Customers': TopCustomersChart,
    'Branch Performance': BranchPerformanceChart,
    'Top Products by Branch': BranchProductsChart,
    'Product Demographics': ProductDemographicsChart,
    'Marketing Channels': MarketingChannelsChart,
    'Color Performance': ColorPerformanceChart,
    'Dead Stock Analysis': DeadStockChart,
    'Product Types': ProductTypesChart,
    'Product Groups': ProductGroupsChart,
    'Pack Grades': PackGradesChart,
    'Product Age Analysis': ProductAgeAnalysisChart,
    'Product Lifecycle': ProductLifecycleChart,
    'Stock Turnover': StockTurnoverChart,
    'Stockouts': StockoutsAnalysis,
    'Optimal Stock Levels': OptimalStockLevels,
    'Branch Transfers': BranchTransfers
  };

  