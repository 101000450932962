import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Sparkles, TrendingUp, Users } from 'lucide-react';

const DashboardSkeleton = () => {
    const [currentLoadingMessage, setCurrentLoadingMessage] = useState(0);
    const loadingMessages = [
        "Analyzing market trends with AI...",
        "Generating predictive insights...",
        "Optimizing performance metrics...",
        "Synthesizing competitive intelligence..."
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentLoadingMessage(prev => (prev + 1) % loadingMessages.length);
        }, 2500);
        return () => clearInterval(interval);
    }, []);

    const shimmerEffect = {
        animate: {
            x: ['-100%', '100%'],
        },
        transition: {
            repeat: Infinity,
            duration: 1.8,
            ease: 'linear',
        },
    };

    const cards = [
        { icon: <TrendingUp className="w-5 h-5" />, title: 'Revenue' },
        { icon: <Sparkles className="w-5 h-5" />, title: 'Growth' },
        { icon: <Users className="w-5 h-5" />, title: 'Customers' }
    ];

    const categories = [
        { title: 'Market Intelligence', subtitle: 'AI-Powered Insights' },
        { title: 'Customer Behavior', subtitle: 'Predictive Analysis' },
        { title: 'Revenue Streams', subtitle: 'Real-time Tracking' },
        { title: 'Growth Metrics', subtitle: 'Forward Projections' },
        { title: 'Competition Map', subtitle: 'Market Position' },
        { title: 'Strategic Planning', subtitle: 'AI Recommendations' }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-950">
            <div className="max-w-7xl mx-auto px-4 py-8 space-y-8">
                {/* Header Section */}
                <div className="flex items-center justify-between">
                    <div className="space-y-1">
                        <div className="flex items-center gap-3">
                            <div className="h-12 w-72 bg-gray-800/50 backdrop-blur rounded-xl relative overflow-hidden">
                                <motion.div
                                    className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-700/30 to-transparent"
                                    {...shimmerEffect}
                                />
                            </div>
                            <div className="flex items-center">
                                <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent font-bold text-xl">Jaanch</span>
                                <span className="text-gray-400 font-bold text-xl">AI</span>
                                <div className="text-gray-500 text-xs ml-2 font-medium">Enterprise Analytics</div>
                            </div>
                        </div>
                        <div className="h-6 relative">
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={currentLoadingMessage}
                                    initial={{ opacity: 0, y: 5 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -5 }}
                                    className="absolute text-gray-400/80 text-sm font-medium"
                                >
                                    {loadingMessages[currentLoadingMessage]}
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </div>
                </div>

                {/* Key Metrics */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {cards.map((card, i) => (
                        <motion.div
                            key={i}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: i * 0.1 }}
                            className="bg-gray-800/40 backdrop-blur border border-gray-700/30 rounded-2xl overflow-hidden"
                        >
                            <div className="p-6 space-y-4">
                                <div className="flex items-center gap-3">
                                    <div className="p-2 bg-gray-700/30 rounded-lg text-gray-400/80">
                                        {card.icon}
                                    </div>
                                    <span className="text-gray-400/80 font-medium">{card.title}</span>
                                </div>
                                <div className="h-24 relative overflow-hidden rounded-xl bg-gray-700/20">
                                    <motion.div
                                        className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-600/20 to-transparent"
                                        {...shimmerEffect}
                                        transition={{
                                            ...shimmerEffect.transition,
                                            delay: i * 0.2,
                                        }}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* Analytics Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {categories.map((category, i) => (
                        <motion.div
                            key={i}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: i * 0.1 }}
                            className="bg-gray-800/40 backdrop-blur border border-gray-700/30 rounded-2xl overflow-hidden"
                        >
                            <div className="p-6 space-y-4">
                                <div className="space-y-1">
                                    <h3 className="text-gray-300 font-medium">{category.title}</h3>
                                    <p className="text-gray-500 text-sm">{category.subtitle}</p>
                                </div>
                                <div className="h-48 relative overflow-hidden rounded-xl bg-gray-700/20">
                                    <div className="absolute inset-0 grid grid-cols-4 gap-2 p-4 opacity-30">
                                        {Array.from({ length: 8 }).map((_, j) => (
                                            <motion.div
                                                key={j}
                                                initial={{ height: 0 }}
                                                animate={{ height: `${Math.random() * 60 + 20}%` }}
                                                transition={{ delay: j * 0.1, duration: 0.5 }}
                                                className="bg-gray-600/50 rounded-lg"
                                                style={{ marginTop: 'auto' }}
                                            />
                                        ))}
                                    </div>
                                    <motion.div
                                        className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-600/20 to-transparent"
                                        {...shimmerEffect}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* Progress Bar */}
                <div className="flex justify-center">
                    <div className="w-64 h-1 bg-gray-800 rounded-full overflow-hidden">
                        <motion.div
                            className="h-full bg-gradient-to-r from-blue-400 to-purple-400 rounded-full"
                            animate={{ x: ['-100%', '0%'] }}
                            transition={{
                                repeat: Infinity,
                                duration: 2,
                                ease: 'linear',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardSkeleton;