import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FaLock, FaPhone, FaUserAlt} from 'react-icons/fa';
import {useDispatch} from 'react-redux';
import {setUser} from '../../redux/features/userSlice';
import {authService} from "../../utils/axios";
import {AlertCircle, Eye, EyeOff} from 'lucide-react';
import {motion} from 'framer-motion';

const LoginPage = () => {
  const [loginMethod, setLoginMethod] = useState('password');
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    phone: '',
    otp: ''
  });
  const [sessionToken, setSessionToken] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const setCookie = (name, value, days = 7) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/; secure; samesite=strict`;
  };

  const handlePasswordLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const data = await authService.login({
        email: credentials.email,
        password: credentials.password
      });
      setCookie('access_token', data.access_token);
      dispatch(setUser({user: data.user, organization: data.organization}));
      navigate('/dashboard');
    } catch (error) {
      setError(error.detail || 'Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestOtp = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const data = await authService.requestOTPForLogin({
        phone: credentials.phone
      });
      setSessionToken(data.session_token);
      sessionStorage.setItem('login_session_token', data.session_token);
      setShowOtpInput(true);
    } catch (error) {
      setError(error.detail || 'Failed to send OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const data = await authService.verifyOTPForLogin({
        session_token: sessionToken,
        otp_code: credentials.otp
      });
      setCookie('access_token', data.access_token);
      dispatch(setUser({user: data.user, organization: data.organization}));
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
      setError(error.detail || 'Invalid OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSessionToken(sessionStorage.getItem('login_session_token'));
  }, [])

  return (
      <div
          className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 flex items-center justify-center p-4">
        <div
            className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent pointer-events-none"/>

        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            className="max-w-md w-full space-y-8"
        >
          <div
              className="relative p-8 bg-gray-900/90 backdrop-blur-xl border border-gray-800/50 rounded-2xl shadow-2xl overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-blue-500/5 to-purple-500/5"/>

            <div className="relative space-y-6">
              <div className="text-center space-y-2">
                <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                  JaanchAI
                </h1>
                <p className="text-gray-400">Sign in to your account</p>
              </div>

              {error && (
                  <div className="flex items-center gap-2 p-3 bg-red-500/10 border border-red-500/20 rounded-lg">
                    <AlertCircle className="w-5 h-5 text-red-400"/>
                    <p className="text-sm text-red-400">{error}</p>
                  </div>
              )}

              <div className="grid grid-cols-2 gap-2 p-1 bg-gray-800/50 rounded-lg">
                <button
                    type="button"
                    onClick={() => {
                      setLoginMethod('password');
                      setError('');
                      setShowOtpInput(false);
                    }}
                    className={`py-2 rounded-md transition-all duration-200 ${
                        loginMethod === 'password'
                            ? 'bg-blue-500/20 text-blue-400 shadow-lg shadow-blue-500/10'
                            : 'hover:bg-gray-700/50 text-gray-400'
                    }`}
                >
                  Password
                </button>
                <button
                    type="button"
                    onClick={() => {
                      setLoginMethod('otp');
                      setError('');
                      setShowOtpInput(false);
                    }}
                    className={`py-2 rounded-md transition-all duration-200 ${
                        loginMethod === 'otp'
                            ? 'bg-blue-500/20 text-blue-400 shadow-lg shadow-blue-500/10'
                            : 'hover:bg-gray-700/50 text-gray-400'
                    }`}
                >
                  OTP
                </button>
            </div>

              {loginMethod === 'password' ? (
                  <form onSubmit={handlePasswordLogin} className="space-y-4">
                    <div className="space-y-4">
                      <div className="relative">
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          <FaUserAlt/>
                        </div>
                        <input
                            type="email"
                            name="email"
                            value={credentials.email}
                            onChange={handleChange}
                            required
                            className="w-full bg-gray-800/50 text-gray-200 pl-10 pr-4 py-3 rounded-lg border border-gray-700/50 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/50 transition-all duration-200"
                            placeholder="Email"
                            disabled={isLoading}
                        />
                      </div>

                      <div className="relative">
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          <FaLock/>
                        </div>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            required
                            className="w-full bg-gray-800/50 text-gray-200 pl-10 pr-12 py-3 rounded-lg border border-gray-700/50 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/50 transition-all duration-200"
                            placeholder="Password"
                            disabled={isLoading}
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-300 transition-colors"
                        >
                          {showPassword ? (
                              <EyeOff className="w-5 h-5"/>
                          ) : (
                              <Eye className="w-5 h-5"/>
                          )}
                        </button>
                      </div>
                    </div>

                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-200 disabled:opacity-50"
                    >
                      {isLoading ? 'Signing in...' : 'Sign in'}
                    </button>
                  </form>
              ) : (
                  <form onSubmit={showOtpInput ? handleOtpLogin : handleRequestOtp} className="space-y-4">
                    <div className="space-y-4">
                      <div className="relative">
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          <FaPhone/>
                        </div>
                        <input
                            type="tel"
                            name="phone"
                            value={credentials.phone}
                            onChange={handleChange}
                            required
                            className="w-full bg-gray-800/50 text-gray-200 pl-10 pr-4 py-3 rounded-lg border border-gray-700/50 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/50 transition-all duration-200"
                            placeholder="Phone Number"
                            disabled={isLoading || showOtpInput}
                        />
                      </div>

                      {showOtpInput && (
                          <motion.div
                              initial={{opacity: 0, y: -10}}
                              animate={{opacity: 1, y: 0}}
                              className="relative"
                          >
                            <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                              <FaLock/>
                            </div>
                            <input
                                type="text"
                                name="otp"
                                value={credentials.otp}
                                onChange={handleChange}
                                required
                                maxLength={6}
                                className="w-full bg-gray-800/50 text-gray-200 pl-10 pr-4 py-3 rounded-lg border border-gray-700/50 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/50 transition-all duration-200"
                                placeholder="Enter 6-digit OTP"
                                disabled={isLoading}
                            />
                          </motion.div>
                      )}
                    </div>

                    <motion.button
                        type="submit"
                        disabled={isLoading}
                        className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-200 disabled:opacity-50"
                        whileHover={{scale: 1.02}}
                        whileTap={{scale: 0.98}}
                    >
                      {isLoading
                          ? showOtpInput ? 'Verifying...' : 'Sending OTP...'
                          : showOtpInput ? 'Verify OTP' : 'Send OTP'}
                    </motion.button>
                  </form>
              )}
            </div>
          </div>
        </motion.div>
    </div>
  );
};

export default LoginPage;