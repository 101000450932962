import React, {useState} from 'react';
import {Bar, BarChart, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const MarketingChannelsChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('total_revenue');
  const [displayCount, setDisplayCount] = useState(8);

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  const metrics = [
    {
      key: 'total_revenue',
      name: 'Total Revenue',
      color: '#2563eb',
      format: (value) => `₹${(value).toFixed(2)}L`
    },
    {
      key: 'number_of_sales',
      name: 'Number of Sales',
      color: '#16a34a',
      format: (value) => `${value}`
    },
    {
      key: 'unique_customers',
      name: 'Unique Customers',
      color: '#7c3aed',
      format: (value) => `${(value)}`
    },
    {
      key: 'total_units',
      name: 'Total Units',
      color: '#ea580c',
      format: (value) => `${value.toLocaleString()}`
    }
  ];

  const efficiencyScore = (agent) => {
    const maxRevenue = Math.max(...data.map(a => a.total_revenue));
    const maxCustomers = Math.max(...data.map(a => a.unique_customers));
    const maxSales = Math.max(...data.map(a => a.number_of_sales));

    return (
      (agent.total_revenue / maxRevenue) +
      (agent.unique_customers / maxCustomers) +
      (agent.number_of_sales / maxSales)
    );
  };

  // Process and sort data
  const processedData = [...data]
    .map(agent => ({
      ...agent,
      total_revenue: agent.total_revenue / 100000,
      efficiency_score: efficiencyScore(agent),
      short_name: agent.agent_name.length > 15
        ? `${agent.agent_name.slice(0, 15)}...`
        : agent.agent_name
    }))
    .sort((a, b) => b.efficiency_score - a.efficiency_score)
    .slice(0, displayCount);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const agentData = processedData.find(a => a.short_name === label);
      if (!agentData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{agentData.agent_name}</p>
          <div className="space-y-1">
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(agentData[metric.key])}
              </p>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="mb-4 flex flex-wrap gap-4 justify-between items-center">
        <div className="flex flex-wrap gap-2">
          {metrics.map(metric => (
            <button
              key={metric.key}
              onClick={() => setActiveMetric(metric.key)}
              className={`px-3 py-1 rounded-full text-sm ${
                activeMetric === metric.key
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {metric.name}
            </button>
          ))}
        </div>
        <div className="flex gap-2 items-center">
          <span className="text-sm text-gray-600">Show:</span>
          <select
            value={displayCount}
            onChange={(e) => setDisplayCount(Number(e.target.value))}
            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8 appearance-none"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 0.5rem center',
              backgroundSize: '1.5em 1.5em'
            }}
          >
            <option value={5}>Top 5</option>
            <option value={8}>Top 8</option>
            <option value={10}>Top 10</option>
            <option value={15}>Top 15</option>
          </select>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
        >
          <XAxis
            dataKey="short_name"
            angle={-35}
            textAnchor="end"
            height={80}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{
            paddingTop: '20px'
          }}/>
          <Bar
            dataKey={activeMetric}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            name={metrics.find(m => m.key === activeMetric)?.name}
          >
            {processedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={metrics.find(m => m.key === activeMetric)?.color}
                fillOpacity={1 - (index * 0.05)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketingChannelsChart;