import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Bot, Settings, HelpCircle, MessageSquare, LogIn, ChevronRight, Menu, X } from 'lucide-react';
import JaanchLogo from "../components/JaanchLogo";

const navItems = [
  { to: '/', icon: Bot, text: 'Home' },
  { to: '/solutions', icon: Settings, text: 'Solutions' },
  { to: '/faq', icon: HelpCircle, text: 'FAQ' },
  { to: '/talk-to-expert', icon: MessageSquare, text: 'Talk to Expert' },
  { to: '/login', icon: LogIn, text: 'Login' }
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-gray-900/80 backdrop-blur-xl border-b border-gray-800/50' : 'bg-transparent'
    }`}>
      <div className="container mx-auto px-4">
        <div className="h-16 flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2">
           
            <div className="text-xl font-bold">
              <JaanchLogo className="h-6" />
            </div>
          </Link>

          <nav className="hidden md:flex items-center gap-8">
            {navItems.map((item, i) => (
              <Link
                key={i}
                to={item.to}
                className="group flex items-center gap-2 text-sm text-gray-400 hover:text-white transition-colors"
              >
                <item.icon className="w-4 h-4" />
                <span>{item.text}</span>
                <ChevronRight className="w-3 h-3 opacity-0 -ml-1 group-hover:opacity-100 group-hover:ml-0 transition-all" />
              </Link>
            ))}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl text-white text-sm font-medium hover:from-blue-600 hover:to-purple-600"
            >
              Request Demo
            </motion.button>
          </nav>

          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="p-2 md:hidden text-gray-400 hover:text-white"
          >
            {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="border-t border-gray-800/50 bg-gray-900/95 backdrop-blur-xl md:hidden"
          >
            <nav className="container mx-auto p-4">
              <div className="flex flex-col gap-4">
                {navItems.map((item, i) => (
                  <Link
                    key={i}
                    to={item.to}
                    onClick={() => setIsOpen(false)}
                    className="flex items-center gap-3 p-3 rounded-xl hover:bg-gray-800/50 text-gray-400 hover:text-white transition-colors"
                  >
                    <item.icon className="w-5 h-5" />
                    <span>{item.text}</span>
                  </Link>
                ))}
                <button className="mt-2 p-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl text-white font-medium hover:from-blue-600 hover:to-purple-600">
                  Request Demo
                </button>
              </div>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;