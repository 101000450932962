import React, {useEffect, useState} from 'react';
import {FaTimes} from 'react-icons/fa';
import KPI from './KPI';
import {splitAndCapitalize} from "../utils/functions";

const KPIFlyout = ({ isOpen, onClose, kpis, selectedKPIs, onKPISelect }) => {
    const [selectedCategory, setSelectedCategory] = useState('all');

    // Get unique categories from KPIs
    const categories = ['all', ...new Set(kpis.map(({category}) => category))];

    // Filter KPIs based on selected category
    const displayKpis = selectedCategory === 'all'
        ? kpis
        : kpis.filter(kpi => kpi.category === selectedCategory);

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            const flyout = document.getElementById('kpi-flyout');
            const toggleButton = document.getElementById('flyout-toggle');

            if (isOpen &&
                flyout &&
                !flyout.contains(event.target) &&
                !toggleButton?.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen, onClose]);

    return (
        <div
            id="kpi-flyout" // DO NOT REMOVE THIS ID
            className={`fixed inset-y-0 right-0 w-[40rem] bg-gray-800 shadow-lg transform transition-transform duration-300 ease-in-out z-30 ${
                isOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
        >
            <div className="h-full flex flex-col">
                <div className="flex justify-between items-center p-4 border-b border-gray-700">
                    <div className="flex items-center space-x-4">
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            className="bg-gray-700 text-white text-xl font-semibold rounded-lg px-4 py-2 capitalize focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {categories.map(category => (
                                <option key={category} value={category} className="capitalize">
                                    {category === 'all' ? 'All KPIs' : `${splitAndCapitalize(category)} KPIs`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-white transition-colors duration-200"
                    >
                        <FaTimes className="text-xl"/>
                    </button>
                </div>

                <div className="flex-1 overflow-y-auto p-4">
                    <div className="grid grid-cols-2 gap-4">
                        {displayKpis.length > 0 ? (
                            displayKpis
                                .sort((a, b) => {
                                    // First sort by category
                                    if (a.category < b.category) return 1;
                                    if (a.category > b.category) return -1;
                                    // Then by name within same category
                                    return a.name.localeCompare(b.name);
                                })
                                .map((kpi) => {
                                    // Check if KPI is in selectedKPIs
                                    const isSelected = selectedKPIs.some(selected => selected.id === kpi.id);

                                    return (
                                        <KPI
                                            key={kpi.id}
                                            id={kpi.id}
                                            name={kpi.name}
                                            color={kpi.color}
                                            category={kpi.category}
                                            description_text={kpi.description_text}
                                            endpoint={kpi.endpoint}
                                            onClick={onKPISelect}
                                            isSelected={isSelected}
                                        />
                                    );
                                })
                        ) : (
                            <div className="text-white text-center col-span-2">
                                No KPIs available{selectedCategory !== 'all' ? ' for this category' : ''}.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KPIFlyout;