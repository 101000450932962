import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kpis: [],
  loading: false,
  error: null,
  selectedKPIs: [],
};

const kpiSlice = createSlice({
  name: 'kpi',
  initialState,
  reducers: {
    setKpis: (state, action) => {
      return {
        ...state,
        kpis: [...action.payload],
        loading: false,
        error: null,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload
      };
    },
    setError: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    },
    clearKpis: (state) => {
      return {
        ...initialState,
        selectedKPIs: state.selectedKPIs,
      };
    },
    addToSelectedKPIs: (state, action) => {
      if (!state.selectedKPIs.some(kpi => kpi.id === action.payload.id)) {
        console.log(`adding ${action.payload}`);
        state.selectedKPIs.push(action.payload);
      }
    },
    removeFromSelectedKPIs: (state, action) => {
        console.log(`removing ${action.payload}`);
      state.selectedKPIs = state.selectedKPIs.filter(kpi => kpi.id !== action.payload.id);
    },
  },
});

export const {setKpis, setLoading, setError, clearKpis, addToSelectedKPIs, removeFromSelectedKPIs} = kpiSlice.actions;
export default kpiSlice.reducer;