import React, { useState, useEffect, useMemo } from 'react';
import { 
  Table, Search, SlidersHorizontal, Download, Filter, ArrowUpDown,
  Maximize2, BarChart as ChartIcon, Grid2x2, TableProperties, X
} from 'lucide-react';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, LineChart, Line, AreaChart, Area
} from 'recharts';

const QueryResultTable = ({ markdownResult }) => {
  const [displayedRows, setDisplayedRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState('modern');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [selectedColumns, setSelectedColumns] = useState(new Set());
  const [showColumnSelect, setShowColumnSelect] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [chartType, setChartType] = useState('area');
  const rowsPerPage = 8;

  const parseTableData = (markdown) => {
    try {
      const tableLines = markdown
        .split('\n')
        .filter(line => line.trim() && !line.startsWith('###') && !line.startsWith('Total'));
      
      if (tableLines.length < 3) return { headers: [], rows: [], totalResults: 0 };

      const headers = tableLines[0]
        .split('|')
        .slice(1, -1)
        .map(h => h.trim());

      const rows = tableLines.slice(2).map(line => {
        const values = line
          .split('|')
          .slice(1, -1)
          .map(v => v.trim());
        
        return headers.reduce((obj, header, index) => {
          const value = values[index] || '';
          obj[header] = !isNaN(value) && value !== '' ? Number(value) : value;
          return obj;
        }, {});
      });

      return { headers, rows, totalResults: rows.length };
    } catch (error) {
      console.error('Error parsing table data:', error);
      return { headers: [], rows: [], totalResults: 0 };
    }
  };

  useEffect(() => {
    const { headers: parsedHeaders, rows, totalResults: total } = parseTableData(markdownResult);
    setHeaders(parsedHeaders);
    setAllRows(rows);
    setTotalResults(total);
    setSelectedColumns(new Set(parsedHeaders));
    setDisplayedRows(rows.slice(0, rowsPerPage));
  }, [markdownResult]);

  const filteredRows = useMemo(() => {
    return allRows.filter(row =>
      Object.entries(row).some(([key, value]) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [allRows, searchTerm]);

  const sortedRows = useMemo(() => {
    if (!sortConfig.key) return filteredRows;

    return [...filteredRows].sort((a, b) => {
      if (a[sortConfig.key] === null) return 1;
      if (b[sortConfig.key] === null) return -1;
      
      const modifier = sortConfig.direction === 'asc' ? 1 : -1;
      return a[sortConfig.key] > b[sortConfig.key] ? modifier : -modifier;
    });
  }, [filteredRows, sortConfig]);

  useEffect(() => {
    setDisplayedRows(sortedRows.slice(0, page * rowsPerPage));
  }, [sortedRows, page]);

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const loadMoreRows = () => {
    setPage(prev => prev + 1);
  };

  const exportData = () => {
    const csvContent = [
      Array.from(selectedColumns).join(','),
      ...sortedRows.map(row => 
        Array.from(selectedColumns)
          .map(header => row[header])
          .join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data_export.csv';
    a.click();
  };

  const ColumnSelector = () => (
    <div className="absolute right-0 top-full mt-2 w-64 bg-gray-900 rounded-lg shadow-xl border border-gray-700 z-50">
      <div className="p-3 border-b border-gray-700 flex justify-between items-center">
        <span className="text-sm font-medium text-gray-300">Show/Hide Columns</span>
        <button 
          onClick={() => setShowColumnSelect(false)}
          className="text-gray-400 hover:text-gray-200"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      <div className="p-2 max-h-64 overflow-y-auto">
        {headers.map(header => (
          <label key={header} className="flex items-center p-2 hover:bg-gray-800 rounded-md">
            <input
              type="checkbox"
              checked={selectedColumns.has(header)}
              onChange={() => {
                setSelectedColumns(prev => {
                  const next = new Set(prev);
                  if (next.has(header)) {
                    next.delete(header);
                  } else {
                    next.add(header);
                  }
                  return next;
                });
              }}
              className="rounded border-gray-600 text-blue-500 focus:ring-blue-500 bg-gray-700"
            />
            <span className="ml-2 text-sm text-gray-300">{header}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const renderChart = () => {
    const numericColumns = headers.filter(header => 
      typeof allRows[0]?.[header] === 'number'
    );

    const ChartComponent = {
      'area': AreaChart,
      'bar': BarChart,
      'line': LineChart
    }[chartType];

    const DataComponent = {
      'area': Area,
      'bar': Bar,
      'line': Line
    }[chartType];

    return (
      <div className="h-96 w-full">
        <ResponsiveContainer>
          <ChartComponent 
            data={sortedRows.slice(0, 15)} 
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              {numericColumns.map((col, index) => (
                <linearGradient key={col} id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={`hsl(${index * 40 + 200}, 70%, 50%)`} stopOpacity={0.8}/>
                  <stop offset="95%" stopColor={`hsl(${index * 40 + 200}, 70%, 50%)`} stopOpacity={0.1}/>
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" opacity={0.3} />
            <XAxis 
              dataKey={headers[0]} 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
              tickLine={{ stroke: '#4B5563' }}
            />
            <YAxis 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
              tickLine={{ stroke: '#4B5563' }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: 'rgba(17, 24, 39, 0.95)',
                border: '1px solid rgba(75, 85, 99, 0.5)',
                borderRadius: '0.5rem',
                backdropFilter: 'blur(8px)',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.2)',
                padding: '8px 12px'
              }}
              itemStyle={{ color: '#E5E7EB' }}
              labelStyle={{ color: '#9CA3AF', marginBottom: '4px' }}
              // remove "_" and convert the label to titlecase
              

            />

            
            <Legend 
              wrapperStyle={{ paddingTop: '16px' }}
              formatter={(value) => (
                <span className="text-gray-300">
                  {value.split('_').map(w => w[0].toUpperCase() + w.slice(1)).join(' ')}  
                </span>
              )}
            />
            {numericColumns.slice(0, 3).map((col, index) => (
              <DataComponent
                key={col}
                type="monotone"
                dataKey={col}
                stroke={`hsl(${index * 40 + 200}, 70%, 50%)`}
                fill={chartType === 'area' ? `url(#gradient${index})` : `hsl(${index * 40 + 200}, 70%, 50%)`}
                strokeWidth={2}
              />
            ))}
          </ChartComponent>
        </ResponsiveContainer>
      </div>
    );
  };

  if (!headers.length || !allRows.length) {
    return (
      <div className="rounded-xl bg-gray-900 p-8 text-center">
        <div className="text-gray-400">No data available</div>
      </div>
    );
  }

  return (
    <div className="w-full space-y-6">
      {/* Header Controls */}
      <div className="flex flex-col gap-4 bg-gray-900 rounded-xl p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="bg-blue-500/20 p-2 rounded-lg">
              <Table className="w-5 h-5 text-blue-400" />
            </div>
            <div>
              <h3 className="font-medium text-gray-200">Query Results</h3>
              <p className="text-sm text-gray-400">
                {displayedRows.length} of {totalResults} entries
              </p>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <button
              onClick={exportData}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-300 hover:text-gray-100 
                       hover:bg-gray-800 rounded-lg transition-colors duration-150"
            >
              <Download className="w-4 h-4" />
              Export
            </button>
            
            <div className="h-6 w-px bg-gray-700" />
            
            <button
              onClick={() => setView(prev => prev === 'modern' ? 'chart' : 'modern')}
              className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-lg transition-all duration-200
                       ${view === 'chart' 
                         ? 'bg-blue-500/20 text-blue-400' 
                         : 'text-gray-300 hover:text-gray-100 hover:bg-gray-800'}`}
            >
              {view === 'chart' ? <Grid2x2 className="w-4 h-4" /> : <ChartIcon className="w-4 h-4" />}
              {view === 'chart' ? 'Grid View' : 'Chart View'}
            </button>

            <div className="relative">
              <button
                onClick={() => setShowColumnSelect(prev => !prev)}
                className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-300 hover:text-gray-100 
                         hover:bg-gray-800 rounded-lg transition-colors duration-150"
              >
                <TableProperties className="w-4 h-4" />
                Columns
              </button>
              {showColumnSelect && <ColumnSelector />}
            </div>
          </div>
        </div>

        <div className="flex gap-3">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
            <input
              type="text"
              placeholder="Search in results..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full bg-gray-800 text-gray-200 pl-10 pr-4 py-2 text-sm rounded-lg 
                       border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500
                       placeholder-gray-500 transition-colors duration-150"
            />
          </div>
          
          <button className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 bg-gray-800 
                           hover:bg-gray-750 rounded-lg border border-gray-700 transition-colors duration-150">
            <Filter className="w-4 h-4" />
            Filter
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="bg-gray-900 rounded-xl p-4">
        {view === 'modern' ? (
          <>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    {Array.from(selectedColumns).map(header => (
                      <th
                        key={header}
                        onClick={() => handleSort(header)}
                        className="group px-6 py-3 text-left border-b border-gray-800 bg-gray-900 sticky top-0"
                      >
                        <div className="flex items-center gap-2 cursor-pointer">
                          <span className="text-sm font-medium text-gray-300">
                            {header}
                          </span>
                          <ArrowUpDown className={`w-4 h-4 transition-colors duration-150
                            ${sortConfig.key === header 
                              ? 'text-blue-400' 
                              : 'text-gray-600 group-hover:text-gray-400'}`} 
                          />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {displayedRows.map((row, rowIdx) => (
                    <tr
                      key={rowIdx}
                      onClick={() => setExpandedRow(expandedRow === rowIdx ? null : rowIdx)}
                      className={`group transition-all duration-200 cursor-pointer
                        ${expandedRow === rowIdx ? 'bg-gray-800' : 'hover:bg-gray-800/50'}`}
                    >
                      {Array.from(selectedColumns).map((header, colIdx) => (
                        <td
                          key={`${rowIdx}-${colIdx}`}
                          className={`px-6 py-4 text-sm ${
                            typeof row[header] === 'number' 
                              ? 'text-blue-300 font-medium tabular-nums' 
                              : 'text-gray-300'
                          }`}
                        >
                          <div className="flex items-center gap-2">
                            {colIdx === 0 && (
                              <div className={`w-1.5 h-1.5 rounded-full bg-blue-400 transition-all duration-200
                                ${expandedRow === rowIdx ? 'scale-150' : 'group-hover:scale-125'}`} 
                              />
                            )}
                            {row[header]}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {displayedRows.length < sortedRows.length && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={loadMoreRows}
                  className="px-6 py-2 text-sm text-blue-400 bg-blue-500/10 rounded-lg 
                           hover:bg-blue-500/15 transition-all duration-200 group"
                >
                  <span className="inline-flex items-center gap-2">
                    Load More
                    <Maximize2 className="w-3 h-3 transition-transform duration-200 group-hover:scale-125" />
                  </span>
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="space-y-4">
            <div className="flex gap-2">
              {['area', 'line', 'bar'].map((type) => (
                <button
                  key={type}
                  onClick={() => setChartType(type)}
                  className={`px-3 py-1.5 text-sm rounded-lg capitalize transition-all duration-200 ${
                    chartType === type 
                      ? 'bg-blue-500/20 text-blue-400' 
                      : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800'
                  }`}
                >
                  {type} Chart
                </button>
              ))}
            </div>
            {renderChart()}
          </div>
        )}
      </div>
    </div>
  );
};

export default QueryResultTable;