import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const OptimalStockLevels = ({ data }) => {
  const [displayCount, setDisplayCount] = useState(8);
  
  // Ensure data is an array and has content
  const safeData = Array.isArray(data) ? data : [];

  if (!safeData || safeData.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Filter out items with zero or invalid values
  const processedData = safeData
    .filter(item =>
      item &&  // Add null check
      item.item_name &&
      item.item_name !== '.' &&
      Number(item.eoq) > 0 &&
      Number(item.daily_demand) > 0
    )
    .map(item => ({
      ...item,
      // Ensure all numeric values are properly converted
      current_stock: Number(item.current_stock) || 0,
      reorder_point: Number(item.reorder_point) || 0,
      order_recommendation: Number(item.order_recommendation) || 0,
      daily_demand: Number(item.daily_demand) || 0,
      demand_std: Number(item.demand_std) || 0,
      safety_stock: Number(item.safety_stock) || 0,
      eoq: Number(item.eoq) || 0,
      display_name: item.item_name?.length > 15
        ? `${item.item_name.slice(0, 15)}...`
        : item.item_name,
      full_name: item.item_name,
      stock_status: Number(item.current_stock) <= Number(item.reorder_point) 
        ? 'Below Reorder Point' 
        : 'Adequate Stock'
    }))
    .sort((a, b) => 
      (a.current_stock - a.reorder_point) - (b.current_stock - b.reorder_point)
    )
    .slice(0, displayCount);

  // Calculate summary statistics with null checks
  const summaryStats = {
    belowReorderPoint: safeData.filter(item =>
      item &&
      Number(item.current_stock) <= Number(item.reorder_point) &&
      Number(item.reorder_point) > 0
    ).length,
    averageStockLevel: safeData.reduce((sum, item) => 
      sum + (Number(item.current_stock) || 0), 0) /
      (safeData.filter(item => Number(item.current_stock) > 0).length || 1),
    totalOrderRecommendations: safeData.reduce((sum, item) => 
      sum + (Number(item.order_recommendation) || 0), 0)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const itemData = processedData.find(d => d.display_name === label);
      if (!itemData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{itemData.full_name}</p>
          <div className="space-y-1">
            <p className="text-sm text-blue-600">
              Current Stock: {itemData.current_stock.toFixed(1)}
            </p>
            <p className="text-sm text-orange-600">
              Reorder Point: {itemData.reorder_point.toFixed(1)}
            </p>
            <p className="text-sm text-green-600">
              Order Recommendation: {itemData.order_recommendation.toFixed(1)}
            </p>
            <div className="border-t border-gray-200 mt-2 pt-2">
              <p className="text-sm text-gray-600">
                Daily Demand: {itemData.daily_demand.toFixed(2)}
              </p>
              <p className="text-sm text-gray-600">
                Demand Std Dev: {itemData.demand_std.toFixed(2)}
              </p>
              <p className="text-sm text-gray-600">
                Safety Stock: {itemData.safety_stock.toFixed(1)}
              </p>
              <p className="text-sm text-gray-600">
                EOQ: {itemData.eoq.toFixed(1)}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-3 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-4">
        <div className="bg-red-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-red-800">Below Reorder Point</h3>
          <p className="text-base font-bold text-red-600">
            {summaryStats.belowReorderPoint} items
          </p>
        </div>
        <div className="bg-blue-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Avg Stock Level</h3>
          <p className="text-base font-bold text-blue-600">
            {summaryStats.averageStockLevel.toFixed(1)} units
          </p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-green-800">Total Order Qty</h3>
          <p className="text-base font-bold text-green-600">
            {summaryStats.totalOrderRecommendations.toFixed(1)} units
          </p>
        </div>
      </div>

      {/* Display Count Selection */}
      <div className="flex justify-end mb-3">
        <select
          value={displayCount}
          onChange={(e) => setDisplayCount(Number(e.target.value))}
          className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8"
        >
          <option value={8}>Top 8</option>
          <option value={12}>Top 12</option>
          <option value={15}>Top 15</option>
        </select>
      </div>

      {/* Bar Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
        >
          <XAxis
            dataKey="display_name"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={100}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            label={{
              value: 'Stock Units',
              angle: -90,
              position: 'insideLeft',
              offset: -25
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar 
            dataKey="current_stock" 
            name="Current Stock" 
            fill="#3b82f6" 
            radius={[4, 4, 0, 0]} 
          />
          <Bar 
            dataKey="reorder_point" 
            name="Reorder Point" 
            fill="#f97316" 
            radius={[4, 4, 0, 0]} 
          />
          <Bar 
            dataKey="order_recommendation" 
            name="Order Recommendation" 
            fill="#22c55e" 
            radius={[4, 4, 0, 0]} 
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OptimalStockLevels;