import React, {useMemo, useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const BranchTransfersChart = ({ data = {} }) => {
  const [fromBranch, setFromBranch] = useState('all');
  const [toBranch, setToBranch] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  // Get unique branches
  const branches = useMemo(() => {
    if (!data.transfer_recommendations) return ['all'];

    const branchSet = new Set();
    data.transfer_recommendations.forEach(transfer => {
      branchSet.add(transfer.from_branch);
      branchSet.add(transfer.to_branch);
    });
    return ['all', ...Array.from(branchSet)].sort();
  }, [data.transfer_recommendations]);

  if (!data.transfer_recommendations || data.transfer_recommendations.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Process data for branch summaries
  const branchSummaries = data.transfer_recommendations.reduce((acc, transfer) => {
    if (!acc[transfer.from_branch]) {
      acc[transfer.from_branch] = {
        branch: transfer.from_branch,
        outgoing: 0,
        incoming: 0,
        costSavings: 0
      };
    }
    acc[transfer.from_branch].outgoing += transfer.transfer_quantity;

    if (!acc[transfer.to_branch]) {
      acc[transfer.to_branch] = {
        branch: transfer.to_branch,
        outgoing: 0,
        incoming: 0,
        costSavings: 0
      };
    }
    acc[transfer.to_branch].incoming += transfer.transfer_quantity;
    acc[transfer.to_branch].costSavings += transfer.cost_saving;

    return acc;
  }, {});

  const summaryData = Object.values(branchSummaries);

  // Filter transfer recommendations and sort desc wrt cost_savings
  const filteredTransfers = data.transfer_recommendations
      .sort((a,b) => b.cost_saving - a.cost_saving)
      .filter(transfer => {
    const matchesFrom = fromBranch === 'all' || transfer.from_branch === fromBranch;
    const matchesTo = toBranch === 'all' || transfer.to_branch === toBranch;
    const matchesSearch = transfer.item.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesFrom && matchesTo && matchesSearch;
  });

  // Calculate totals
  const totals = {
    totalTransfers: filteredTransfers.reduce((sum, item) => sum + item.transfer_quantity, 0),
    totalSavings: filteredTransfers.reduce((sum, item) => sum + item.cost_saving, 0),
    uniqueItems: new Set(filteredTransfers.map(item => item.item)).size
  };

  const formatCurrency = (value) =>
    `₹${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">Branch: {data.branch}</p>
          <div className="space-y-1">
            <p className="text-sm text-blue-600">
              Incoming Transfers: {data.incoming} units
            </p>
            <p className="text-sm text-orange-600">
              Outgoing Transfers: {data.outgoing} units
            </p>
            {data.costSavings > 0 && (
              <p className="text-sm text-green-600">
                Cost Savings: {formatCurrency(data.costSavings)}
              </p>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-3 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-4">
        <div className="bg-blue-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Total Transfers</h3>
          <p className="text-base font-bold text-blue-600">
            {totals.totalTransfers} units
          </p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-green-800">Potential Savings</h3>
          <p className="text-base font-bold text-green-600">
            {formatCurrency(totals.totalSavings)}
          </p>
        </div>
        <div className="bg-purple-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-purple-800">Unique Items</h3>
          <p className="text-base font-bold text-purple-600">
            {totals.uniqueItems}
          </p>
        </div>
      </div>

      {/* Branch Transfer Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={summaryData}
          margin={{ top: 20, right: 30, left: 40, bottom: 10 }}
        >
          <XAxis
            dataKey="branch"
            label={{
              value: 'Branches',
              position: 'bottom',
              offset: 0
            }}
          />
          <YAxis
            label={{
              value: 'Number of Units',
              angle: -90,
              position: 'insideLeft',
              offset: -15,
              dy: 40
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{
                paddingTop: "20px"
            }} />
          <Bar dataKey="incoming" name="Incoming Transfers" fill="#3b82f6" radius={[4, 4, 0, 0]} />
          <Bar dataKey="outgoing" name="Outgoing Transfers" fill="#f97316" radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>

      <hr className="my-6 border-gray-200" />

      {/* Item Wise Transfer Recommendation Table */}
      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-4">
        <div className="flex items-center gap-2">
          <label className="text-sm text-gray-600">From:</label>
          <select
            value={fromBranch}
            onChange={(e) => setFromBranch(e.target.value)}
            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8"
          >
            {branches.map(branch => (
              <option key={branch} value={branch}>
                {branch === 'all' ? 'All Branches' : branch}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-2">
          <label className="text-sm text-gray-600">To:</label>
          <select
            value={toBranch}
            onChange={(e) => setToBranch(e.target.value)}
            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8"
          >
            {branches.map(branch => (
              <option key={branch} value={branch}>
                {branch === 'all' ? 'All Branches' : branch}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-2">
          <label className="text-sm text-gray-600">Search Item:</label>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by item name..."
            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8 w-48"
          />
        </div>
      </div>

      {/* Transfer Recommendations Table */}
      <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
        <table className="w-full">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Item</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">From</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">To</th>
              <th className="px-4 py-2 text-right text-sm font-medium text-gray-600">Transfer Qty</th>
              <th className="px-4 py-2 text-right text-sm font-medium text-gray-600">Cost Saving</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredTransfers.map((transfer, idx) => (
              <tr key={idx} className="hover:bg-gray-50">
                <td className="px-4 py-2 text-sm text-gray-800">{transfer.item}</td>
                <td className="px-4 py-2 text-sm text-gray-800">{transfer.from_branch}</td>
                <td className="px-4 py-2 text-sm text-gray-800">{transfer.to_branch}</td>
                <td className="px-4 py-2 text-sm text-right text-blue-600">{transfer.transfer_quantity}</td>
                <td className="px-4 py-2 text-sm text-right text-green-600">{formatCurrency(transfer.cost_saving)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BranchTransfersChart;