import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {
  AlertCircle,
  Bot,
  ChevronRight,
  Code,
  Download,
  Eye,
  EyeOff,
  HelpCircle,
  Lightbulb,
  Menu as MenuIcon,
  MessageSquare,
  Search,
  Send,
  User,
  X
} from 'lucide-react';
import {useSelector} from "react-redux";
import QueryResultTable from "./QueryResultTable";
import {kpiService} from "../utils/axios";


const analyzeQueryContext = (query) => {
  const contexts = {
    sales: /sales|revenue|sold|selling|performance/i,
    inventory: /stock|inventory|level|quantity/i,
    customer: /customer|client|party|buyer/i,
    branch: /branch|store|location/i,
    time: /month|year|period|daily|weekly/i
  };

  return Object.entries(contexts)
    .filter(([_, pattern]) => pattern.test(query))
    .map(([context]) => context);
};

const generateFollowUp = (currentContext, result) => {
  const followUps = {
    sales: [
      {
        question: "Would you like to see how this compares to last year?",
        query: "Compare these sales with the same period last year"
      },
      {
        question: "Should I break this down by product category?",
        query: "Break down these sales by product category"
      },
      {
        question: "Would you like to see the top performing branches?",
        query: "Show top performing branches for this period"
      }
    ],
    inventory: [
      {
        question: "Would you like to see sales velocity for these items?",
        query: "Calculate sales velocity for these products"
      },
      {
        question: "Should I check stock levels across all branches?",
        query: "Compare stock levels across branches"
      },
      {
        question: "Would you like to see reorder recommendations?",
        query: "Generate reorder recommendations based on current stock"
      }
    ],
    // Add more contexts...
  };

  return followUps[currentContext] || [];
};




const Menu = ({ onSelect }) => {
  const analysisOptions = [
    {
      category: "Sales Analysis",
      items: [
        { id: 'get_top_customers', label: 'Top Customers' },
        { id: 'get_branch_performance', label: 'Branch Performance' },
        { id: 'analyze_sales_trends', label: 'Sales Trends' },
        { id: 'analyze_sales_by_period', label: 'Period Analysis' }
      ]
    },
    {
      category: "Product Analysis",
      items: [
        { id: 'get_best_selling_products_by_branch', label: 'Best Selling Products' },
        { id: 'analyze_product_demographics', label: 'Product Demographics' },
        { id: 'analyze_product_lifecycle', label: 'Product Lifecycle' },
        { id: 'analyze_product_types', label: 'Product Types' }
      ]
    },
    {
      category: "Inventory Management",
      items: [
        { id: 'analyze_stock_turnover', label: 'Stock Turnover' },
        { id: 'analyze_dead_stock', label: 'Dead Stock' },
        { id: 'analyze_stockouts', label: 'Stockouts' },
        { id: 'calculate_optimal_stock', label: 'Optimal Stock' }
      ]
    }
  ];

  return (
    <div className="p-4 space-y-6">
      {analysisOptions.map((category, index) => (
        <div key={index} className="space-y-2">
          <h3 className="text-sm font-medium text-gray-400">{category.category}</h3>
          <div className="grid grid-cols-1 gap-2">
            {category.items.map((item) => (
              <button
                key={item.id}
                onClick={() => onSelect(`Analyze ${item.id}`)}
                className="flex items-center justify-between p-3 text-left bg-gray-800/50 hover:bg-gray-700/50 rounded-lg text-gray-200 transition-colors group"
              >
                <span className="text-sm">{item.label}</span>
                <ChevronRight className="w-4 h-4 text-gray-400 group-hover:text-gray-200" />
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};


const QuerySuggestions = ({ onSelect }) => {
  const suggestions = [
    {
      text: "Show top 5 customers by branch.",
      description: "View your highest-value customers"
    },
    {
      text: "What were my total sales last month",
      description: "Get monthly sales summary"
    },
    {
      text: "Show products with low inventory",
      description: "Check stock levels"
    },
    {
      text: "Compare sales between branches",
      description: "Branch performance analysis"
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 p-4">
      {suggestions.map((suggestion, index) => (
        <button
          key={index}
          onClick={() => onSelect(suggestion.text)}
          className="flex flex-col gap-1 p-4 bg-gray-800/50 hover:bg-gray-700/50 rounded-xl border border-gray-700/50 transition-colors text-left"
        >
          <div className="flex items-center gap-2">
            <Lightbulb className="w-4 h-4 text-blue-400" />
            <span className="text-gray-200 text-sm font-medium">{suggestion.text}</span>
          </div>
          <span className="text-xs text-gray-400">{suggestion.description}</span>
        </button>
      ))}
    </div>
  );
};


const ChatbotFloater = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [messages, setMessages] = useState([
    { 
      id: 1, 
      type: 'bot', 
      content: "👋 Hi! I'm your data assistant. You can either ask me questions directly or use the menu to explore specific analyses.",
      timestamp: new Date() 
    }
  ]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showTechnicalDetails, setShowTechnicalDetails] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const { user: { organization_id } } = useSelector((state) => state.user);

  const [queryContext, setQueryContext] = useState([]);
  const [followUpSuggestions, setFollowUpSuggestions] = useState([]);
  const [showFollowUps, setShowFollowUps] = useState(false);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    const query = input.trim();
    if (!query) return;

    const contexts = analyzeQueryContext(query);
    setQueryContext(contexts);

    const userMessage = { 
      id: Date.now(), 
      type: 'user', 
      content: query,
      timestamp: new Date() 
    };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      const data = await kpiService.getChatResponse({query}, {organization_id});

      if (data.error) {
        throw new Error(data.error || 'Failed to process query');
      }

      const botMessage = {
        id: Date.now(),
        type: 'bot',
        content: 'Here are your results:',
        markdown_result: data.markdown_result,
        sql_query: data.sql_query,
        timestamp: new Date()
      };
      setMessages(prev => [...prev, botMessage]);

      if (contexts.length > 0) {
        const suggestions = generateFollowUp(contexts[0], data);
        setFollowUpSuggestions(suggestions);
        setShowFollowUps(true);
      }

    } catch (err) {
      const errorMessage = {
        id: Date.now(),
        type: 'error',
        content: "I couldn't understand that query.",
        help: "Try rephrasing your question or use the menu to select a specific analysis.",
        timestamp: new Date()
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setInput(suggestion);
    inputRef.current?.focus();
  };

  const handleMenuSelect = (query) => {
    setInput(query);
    setShowMenu(false);
    handleSubmit();
  };

  const filteredMessages = messages.filter(message =>
    message.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.markdown_result?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.sql_query?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const exportHistory = () => {
    const exportData = messages.map(msg => ({
      timestamp: msg.timestamp.toLocaleString(),
      type: msg.type,
      content: msg.content,
      markdown_result: msg.markdown_result,
      sql_query: msg.sql_query
    }));

    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'query-history.json';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      {!isOpen && (
        <motion.button
          onClick={() => setIsOpen(true)}
          className="fixed bottom-6 right-6 p-4 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 transition-colors z-50"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <MessageSquare className="w-6 h-6 text-white" />
        </motion.button>
      )}

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-gray-900/95 backdrop-blur-sm z-50 flex items-center justify-center p-4"
          >
            <div className="bg-gray-900 border border-gray-800 rounded-2xl shadow-xl w-full max-w-6xl max-h-[90vh] flex">
              {/* Menu Sidebar */}
              <div className={`w-80 border-r border-gray-800 flex flex-col transition-all duration-300 ${showMenu ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className="p-4 border-b border-gray-800">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-medium text-gray-200">Analysis Menu</h2>
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto">
                  <Menu onSelect={handleMenuSelect} />
                </div>
              </div>

              {/* Main Content */}
              <div className="flex-1 flex flex-col min-w-0">
                {/* Header */}
                <div className="p-4 border-b border-gray-800 flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => setShowMenu(!showMenu)}
                      className="p-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-300"
                    >
                      <MenuIcon className="w-5 h-5" />
                    </button>
                    <div>
                      <h1 className="text-lg font-semibold text-gray-200">Jaanch Samvaad (जांच संवाद)</h1>
                      <p className="text-sm text-gray-400">Ask questions or use the menu to explore your data</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={exportHistory}
                      className="p-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-300"
                      title="Export history"
                    >
                      <Download className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => setShowTechnicalDetails(!showTechnicalDetails)}
                      className="p-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-300"
                      title={showTechnicalDetails ? "Hide SQL queries" : "Show SQL queries"}
                    >
                      {showTechnicalDetails ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                    </button>
                    <button
                      onClick={() => setShowHelp(!showHelp)}
                      className="p-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-300"
                    >
                      <HelpCircle className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => setIsOpen(false)}
                      className="p-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-300"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                {/* Help Panel */}
                <AnimatePresence>
                  {showHelp && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      className="border-b border-gray-800 overflow-hidden"
                    >
                      <div className="p-4 space-y-4 text-sm">
                        <h3 className="font-medium text-gray-200">How to use the Data Query Assistant</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <p className="text-gray-400">For non-technical users:</p>
                            <ul className="list-disc list-inside space-y-1 text-gray-300">
                              <li>Use the menu to explore specific analyses</li>
                              <li>Ask questions in plain English</li>
                              <li>Try the suggested queries</li>
                            </ul>
                          </div>
                          <div className="space-y-2">
                            <p className="text-gray-400">For technical users:</p>
                            <ul className="list-disc list-inside space-y-1 text-gray-300">
                              <li>Toggle SQL visibility with the eye icon</li>
                              <li>Export query history as JSON</li>
                              <li>Use database terminology for precise queries</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* Search Bar */}
                <div className="p-4 border-b border-gray-800">
                  <div className="relative">
                    <Search className="absolute left-3 top-2.5 w-4 h-4 text-gray-500" />
                    <input
                      type="text"
                      placeholder="Search in conversation..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full bg-gray-800/50 rounded-lg pl-9 pr-4 py-2 text-sm text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
                    />
                  </div>
                </div>

                {/* Messages Area */}
                <div className="flex-1 overflow-y-auto p-4 space-y-6">
                  {filteredMessages.map(message => (
                    <div
                      key={message.id}
                      className={`flex items-start gap-4 ${
                        message.type === 'user' ? 'justify-end' : ''
                      }`}
                    >
                      {message.type !== 'user' && (
                        <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${
                          message.type === 'error' ? 'bg-red-500/10 text-red-400' : 'bg-blue-500/10 text-blue-400'
                        }`}>
                          {message.type === 'error' ? <AlertCircle className="w-4 h-4" /> : <Bot className="w-4 h-4" />}
                        </div>
                      )}

                      <div className={`flex flex-col gap-4 ${message.type === 'user' ? 'items-end' : 'items-start'} max-w-3xl`}>
                        <div className={`p-4 rounded-xl ${
                          message.type === 'user'
                            ? 'bg-blue-500/10 text-gray-200'
                            : message.type === 'error'
                            ? 'bg-red-500/10 text-red-200'
                            : 'bg-gray-800/50 text-gray-300'
                        }`}>
                          <p className="text-sm whitespace-pre-wrap">{message.content}</p>
                          {message.help && (
                            <p className="mt-2 text-xs text-gray-400">{message.help}</p>
                          )}
                        </div>

                        {showTechnicalDetails && message.sql_query && (
                          <div className="w-full bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700/50">
                            <div className="p-3 border-b border-gray-700/50 flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                <Code className="w-4 h-4 text-blue-400" />
                                <h3 className="text-sm font-medium text-gray-300">SQL Query</h3>
                              </div>
                            </div>
                            <div className="p-3 bg-gray-900/50">
                              <pre className="text-sm text-blue-300 whitespace-pre-wrap font-mono">{message.sql_query}</pre>
                            </div>
                          </div>
                        )}

                        {message.markdown_result && (
                          <div className="w-full bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700/50">
                            <QueryResultTable markdownResult={message.markdown_result} />
                          </div>
                        )}

                        <span className="text-xs text-gray-500">
                          {new Intl.DateTimeFormat('en-US', {
                            hour: '2-digit',
                            minute: '2-digit'
                          }).format(message.timestamp)}
                        </span>
                      </div>

                      {message.type === 'user' && (
                        <div className="w-8 h-8 rounded-lg flex items-center justify-center bg-blue-500/10 text-blue-400">
                          <User className="w-4 h-4" />
                        </div>
                      )}
                    </div>
                  ))}

                  {messages.length === 1 && !showMenu && (
                    <div className="border-t border-gray-800 pt-6">
                      <h3 className="text-sm font-medium text-gray-300 mb-3">Try these example queries:</h3>
                      <QuerySuggestions onSelect={handleSuggestionSelect} />
                    </div>
                  )}

                  <div ref={messagesEndRef} />

                  {isTyping && (
                    <div className="flex items-center gap-3">
                      <div className="w-8 h-8 rounded-lg flex items-center justify-center bg-blue-500/10 text-blue-400">
                        <Bot className="w-4 h-4" />
                      </div>
                      <div className="p-3 bg-gray-800/30 rounded-xl flex items-center gap-2">
                        <div className="flex items-center gap-1">
                          <span className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                          <span className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                          <span className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                        </div>
                        <span className="text-sm text-gray-400">Processing your query...</span>
                      </div>
                    </div>
                  )}
                </div>

                {/* Input Area */}
                <div className="p-4 border-t border-gray-800">
                  <form onSubmit={handleSubmit} className="max-w-3xl mx-auto">
                    <div className="relative flex items-center gap-2">
                      <input
                        ref={inputRef}
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Ask a question about your data..."
                        className="flex-1 bg-gray-800/50 border border-gray-700/50 rounded-xl px-4 py-3 text-gray-200 placeholder-gray-500 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/50"
                      />
                      <button
                        type="submit"
                        disabled={!input.trim() || isTyping}
                        className="px-4 py-3 bg-blue-500 text-white rounded-xl hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center gap-2"
                      >
                        <Send className="w-4 h-4" />
                        <span>Send</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ChatbotFloater;