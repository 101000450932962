const JaanchLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 40" className="h-8">
    <defs>
      {/* Premium gradient */}
      <linearGradient id="neonGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#00F5FF">
          <animate
            attributeName="stop-color"
            values="#00F5FF;#4F46E5;#00F5FF"
            dur="8s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="100%" stopColor="#4F46E5">
          <animate
            attributeName="stop-color"
            values="#4F46E5;#00F5FF;#4F46E5"
            dur="8s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>

      {/* Premium glow effect */}
      <filter id="premiumGlow" x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur stdDeviation="1" result="blur" />
        <feMerge>
          <feMergeNode in="blur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>

      {/* Energy pulse */}
      <radialGradient id="energyPulse" cx="50%" cy="50%" r="50%">
        <stop offset="0%" stopColor="#00F5FF" stopOpacity="0.3">
          <animate
            attributeName="stop-opacity"
            values="0.3;0.5;0.3"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="100%" stopColor="#4F46E5" stopOpacity="0" />
      </radialGradient>
    </defs>

    {/* Main Logo Group */}
    <g>
      {/* Advanced AI visualization */}
      <path
        d="M10,20 Q20,5 30,20 T50,20"
        stroke="url(#neonGradient)"
        strokeWidth="2.5"
        fill="none"
        filter="url(#premiumGlow)"
        strokeLinecap="round"
      >
        <animate
          attributeName="d"
          values="
            M10,20 Q20,5 30,20 T50,20;
            M10,20 Q20,35 30,20 T50,20;
            M10,20 Q20,5 30,20 T50,20
          "
          dur="4s"
          repeatCount="indefinite"
        />
      </path>

      {/* Intelligence nodes */}
      {[
        { x: 20, y: 12, delay: 0 },
        { x: 30, y: 20, delay: 0.7 },
        { x: 40, y: 12, delay: 1.4 }
      ].map((point, i) => (
        <g key={i}>
          {/* Power field */}
          <circle
            cx={point.x}
            cy={point.y}
            r="4"
            fill="url(#energyPulse)"
          >
            <animate
              attributeName="r"
              values="3;5;3"
              dur="2s"
              begin={`${point.delay}s`}
              repeatCount="indefinite"
            />
          </circle>
          
          {/* Core */}
          <circle
            cx={point.x}
            cy={point.y}
            r="2"
            fill="#00F5FF"
            filter="url(#premiumGlow)"
          >
            <animate
              attributeName="fill"
              values="#00F5FF;#4F46E5;#00F5FF"
              dur="4s"
              begin={`${point.delay}s`}
              repeatCount="indefinite"
            />
          </circle>
        </g>
      ))}

      {/* Intelligence streams */}
      <g stroke="url(#neonGradient)" strokeWidth="1.2" strokeLinecap="round">
        {[15, 25, 35].map((x, i) => (
          <line
            key={i}
            x1={x}
            y1="25"
            x2={x + 5}
            y2="30"
            strokeDasharray="4,4"
          >
            <animate
              attributeName="stroke-dashoffset"
              values="8;0;8"
              dur="3s"
              begin={`${i * 0.4}s`}
              repeatCount="indefinite"
            />
          </line>
        ))}
      </g>
    </g>

    {/* Brand Name */}
    <g transform="translate(60, 0)">
      <text
        x="0"
        y="25"
        fontFamily="Space Grotesk, Inter, system-ui"
        fontWeight="700"
        fontSize="26"
        letterSpacing="-0.03em"
        className="font-black"
        fill="#fff"
      >
        <tspan className="opacity-95">Jaanch</tspan>
        <animate
          attributeName="opacity"
          values="0.95;1;0.95"
          dur="3s"
          repeatCount="indefinite"
        />
      </text>
    </g>
  </svg>
);

export default JaanchLogo;