import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, LogOut, UserCircle, ChevronDown, Settings, Users, Workflow } from 'lucide-react';
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from 'framer-motion';
import { clearUser } from "../../redux/features/userSlice";
import { clearKpis } from "../../redux/features/kpiSlice";

const NavbarProfileButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(clearKpis());
    navigate('/login');
  };

  const notifications = [
    {
      id: 1,
      title: 'New features available',
      description: 'Check out the latest analytics capabilities',
      time: '2h ago',
      icon: Workflow,
      read: false
    }
  ];

  const menuItems = [
    { 
      label: 'Settings', 
      icon: Settings, 
      onClick: () => navigate('/settings'),
      description: 'Manage your preferences'
    },
    { 
      label: 'Team', 
      icon: Users, 
      onClick: () => navigate('/team'),
      description: 'View team members'
    },
    { 
      label: 'Sign out', 
      icon: LogOut, 
      onClick: handleLogout,
      description: 'End your session',
      className: 'text-red-400 hover:bg-red-500/10' 
    }
  ];

  return (
    <div className="flex items-center gap-3 relative z-50">
      {/* Notifications Button */}
      <div className="relative" ref={notificationRef}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowNotifications(!showNotifications)}
          className="relative p-2.5 rounded-xl bg-gray-800/50 border border-gray-700/50 hover:border-gray-600/50 hover:bg-gray-800 transition-all duration-300"
        >
          <Bell className="w-4 h-4 text-gray-400" />
          {notifications.some(n => !n.read) && (
            <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-blue-400 rounded-full ring-2 ring-gray-900" />
          )}
        </motion.button>

        <AnimatePresence>
          {showNotifications && (
            <motion.div
              initial={{ opacity: 0, y: 10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 10, scale: 0.95 }}
              style={{ position: 'absolute', top: '100%', right: 0 }}
              className="mt-2 w-80 bg-gray-900/95 backdrop-blur-xl border border-gray-800/50 rounded-2xl shadow-xl"
            >
              <div className="p-4 border-b border-gray-800/50">
                <h3 className="text-gray-200 font-medium flex items-center justify-between">
                  Notifications
                  {notifications.length > 0 && (
                    <span className="text-xs text-gray-400">{notifications.length} new</span>
                  )}
                </h3>
              </div>
              
              <div className="max-h-[400px] overflow-y-auto">
                {notifications.length > 0 ? (
                  <div className="divide-y divide-gray-800/50">
                    {notifications.map((notification) => (
                      <div 
                        key={notification.id} 
                        className="p-4 hover:bg-gray-800/50 transition-colors cursor-pointer"
                      >
                        <div className="flex gap-3">
                          <div className="mt-1">
                            <notification.icon className="w-5 h-5 text-blue-400" />
                          </div>
                          <div className="flex-1">
                            <p className="text-sm font-medium text-gray-200">{notification.title}</p>
                            <p className="text-sm text-gray-400 mt-0.5">{notification.description}</p>
                            <p className="text-xs text-gray-500 mt-1">{notification.time}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="p-8 flex flex-col items-center justify-center text-center">
                    <Bell className="w-8 h-8 text-gray-500 mb-3" />
                    <p className="text-gray-400 text-sm">No new notifications</p>
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Profile Dropdown */}
      <div className="relative" ref={dropdownRef}>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex items-center gap-3 pl-3 pr-4 py-2 rounded-xl bg-gray-800/50 border border-gray-700/50 hover:border-gray-600/50 hover:bg-gray-800 transition-all duration-300"
        >
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 rounded-xl bg-blue-500/10 flex items-center justify-center">
              <UserCircle className="w-5 h-5 text-blue-400" />
            </div>
            <span className="text-sm font-medium text-gray-300">
              {user?.full_name || 'Loading...'}
            </span>
          </div>
          <motion.div
            animate={{ rotate: isDropdownOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronDown className="w-4 h-4 text-gray-400" />
          </motion.div>
        </motion.button>

        <AnimatePresence>
          {isDropdownOpen && (
            <motion.div
              initial={{ opacity: 0, y: 10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 10, scale: 0.95 }}
              style={{ position: 'absolute', top: '100%', right: 0 }}
              className="mt-2 w-64 bg-gray-900/95 backdrop-blur-xl border border-gray-800/50 rounded-2xl shadow-xl overflow-hidden"
            >
              <div className="px-4 py-3 border-b border-gray-800/50">
                <p className="text-sm font-medium text-gray-200">{user?.email}</p>
                <p className="text-xs text-gray-500 mt-0.5">{user?.role || 'User'}</p>
              </div>

              <div className="py-2">
                {menuItems.map((item, index) => (
                  <motion.button
                    key={item.label}
                    whileHover={{ x: 4 }}
                    onClick={item.onClick}
                    className={`flex items-center gap-3 w-full px-4 py-2.5 text-sm ${
                      item.className || 'text-gray-300 hover:bg-gray-800'
                    } transition-colors`}
                  >
                    <item.icon className="w-4 h-4" />
                    <div className="flex flex-col items-start">
                      <span>{item.label}</span>
                      <span className="text-xs text-gray-500">{item.description}</span>
                    </div>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default NavbarProfileButton;