import React, {useState} from 'react';
import {Bar, BarChart, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {COLORS} from "../../utils/constants";

const ColorPerformanceChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('total_revenue');
  const [displayCount, setDisplayCount] = useState(10);

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
          <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Color mapping for common colors
  const colorMap = {
    'BLACK': '#000000',
    'BLUE': '#2563eb',
    'WINE': '#722F37',
    'NAVY': '#000080',
    'CREAM': '#FFFDD0',
    'GREY': '#808080',
    'WHITE': '#FFFFFF',
    'RAMA': '#41C0C0',
    'OTHER': '#6B7280',
    '1': '#4B5563',
  };

  const metrics = [
    {
      key: 'total_revenue',
        name: 'Total Revenue (Lacs)',
      color: '#16a34a',
      format: (value) => `₹${(value).toFixed(2)}L`
    },
    {
      key: 'avg_price_per_unit',
      name: 'Avg Price/Unit',
      color: '#7c3aed',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'units_sold',
      name: 'Units Sold',
      color: '#ea580c',
      format: (value) => value.toLocaleString()
    }
  ];

  // Process and sort data
    const processedData = [...data]
        .filter(item => item.color !== '(NIL)') // Remove NIL entries
        .slice(0, displayCount)
        .map(item => ({
            ...item,
            total_revenue: item.total_revenue / 100000,
            displayColor: colorMap[item.color] || '#6B7280', // Default color for unknown colors
        }));
  const barChartData = processedData.sort((a, b) => b[activeMetric] - a[activeMetric]);

  const pieChartData = processedData.sort((a, b) => b.revenue_share_percentage - a.revenue_share_percentage);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const colorData = processedData.find(d => d.color === label);
      if (!colorData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <div className="flex items-center gap-2 mb-2">
            <div
              className="w-4 h-4 rounded-full border border-gray-300"
              style={{
                backgroundColor: colorData.displayColor,
                borderColor: colorData.displayColor === '#FFFFFF' ? '#D1D5DB' : 'transparent'
              }}
            />
            <p className="font-semibold text-gray-800">{colorData.color}</p>
          </div>
          <div className="space-y-1">
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(colorData[metric.key])}
              </p>
            ))}
            <div className="border-t border-gray-200 mt-2 pt-2">
              <p className="text-sm text-gray-600">
                Total Sales: {colorData.number_of_sales.toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                Unique Products: {colorData.unique_products}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-4 flex flex-wrap gap-4 justify-between items-center">

          <div className="flex gap-2 items-center">
            <span className="text-sm text-gray-600">Show:</span>
            <select
                value={displayCount}
                onChange={(e) => setDisplayCount(Number(e.target.value))}
                className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8 appearance-none"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 0.5rem center',
                  backgroundSize: '1.5em 1.5em'
                }}
            >
              <option value={5}>Top 5</option>
              <option value={10}>Top 10</option>
              <option value={15}>Top 15</option>
            </select>
          </div>
        </div>

        { /* Sales contribution % of product by branch */ }
            <div className="mb-8">
                <h3 className="text-sm font-semibold text-gray-800 mb-4">Sales Contribution Distribution</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={pieChartData}
                            dataKey="revenue_share_percentage"
                            nameKey="color"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label={({
                                        cx,
                                        cy,
                                        midAngle,
                                        innerRadius,
                                        outerRadius,
                                        value,
                                        name
                                    }) => {
                                const RADIAN = Math.PI / 180;
                                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        fill="#000"
                                        textAnchor={x > cx ? 'start' : 'end'}
                                        dominantBaseline="central"
                                        className="text-xs"
                                    >
                                        {`${name} (${(value).toFixed(1)}%)`}
                                    </text>)
                            }}
                        >
                            {pieChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                        <Tooltip
                            content={({active, payload}) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div className="bg-white p-2 border rounded shadow-lg">
                                            <p className="text-sm font-semibold">{payload[0].name}</p>
                                            <p className="text-sm text-gray-600">
                                                {`${(payload[0].value).toFixed(2)}%`}
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>

          <div className="mb-4 flex flex-wrap gap-4 justify-between items-center">
            <div className="flex flex-wrap gap-2">
              {metrics.map(metric => (
                  <button
                      key={metric.key}
                      onClick={() => setActiveMetric(metric.key)}
                      className={`px-3 py-1 rounded-full text-sm ${
                          activeMetric === metric.key
                              ? 'bg-blue-600 text-white'
                              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                  >
                    {metric.name}
                  </button>
              ))}
            </div>

          </div>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={barChartData}
                margin={{top: 20, right: 30, left: 40, bottom: 20}}
            >
              <XAxis
                  dataKey="color"
                  tick={({x, y, payload}) => (
                      <g transform={`translate(${x},${y})`}>
                        <text
                            x={0}
                            y={0}
                            dy={16}
                            textAnchor="end"
                            fill="#666"
                            transform="rotate(-45)"
                        >
                          {payload.value}
                        </text>
                      </g>
                  )}
                  height={80}
                  interval={0}
              />
              <YAxis/>
              <Tooltip content={<CustomTooltip/>}/>
              <Bar
                  dataKey={activeMetric}
                  name={metrics.find(m => m.key === activeMetric)?.name}
                  fill={metrics.find(m => m.key === activeMetric)?.color}
                  radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        );
        };

        export default ColorPerformanceChart;