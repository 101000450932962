import React from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const StockoutsAnalysis = ({ data = [] }) => {
  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
          <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Calculate totals
  const totals = {
    totalStockouts: data.reduce((sum, item) => sum + item.stockout_items, 0),
    totalCritical: data.reduce((sum, item) => sum + item.critical_stockouts, 0),
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const branchData = data.find(d => d.branch_name === label);
      if (!branchData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">Branch: {branchData.branch_name}</p>
          <div className="space-y-1">
            <p className="text-sm text-orange-600">
              Total Stockouts: {branchData.stockout_items}
            </p>
            <p className="text-sm text-red-600">
              Critical Stockouts: {branchData.critical_stockouts}
            </p>
            <p className="text-sm text-blue-600">
              Stockout Rate: {branchData.stockout_rate_percentage.toFixed(2)}%
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-3 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-2 gap-3 mb-4">
        <div className="bg-orange-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-orange-800">Total Stockouts</h3>
          <p className="text-base font-bold text-orange-600">
            {totals.totalStockouts}
          </p>
        </div>
        <div className="bg-red-50 p-3 rounded-lg">
          <h3 className="text-sm font-medium text-red-800">Critical Stockouts</h3>
          <p className="text-base font-bold text-red-600">
            {totals.totalCritical}
          </p>
        </div>
      </div>

      {/* Branch Details */}
      <div className="mb-4">
        <div className="grid grid-cols-1 gap-2">
          {data.map(branch => (
            <div
              key={branch.branch_name}
              className="bg-gray-50 p-3 rounded-lg"
            >
              <div className="flex justify-between items-center mb-2">
                <span className="font-medium text-gray-800">{branch.branch_name}</span>
                <span className="text-sm text-blue-600">
                  {branch.stockout_rate_percentage.toFixed(2)}% Rate
                </span>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-orange-500" />
                  <span className="text-sm text-gray-600">
                    Stockouts: {branch.stockout_items}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-red-500" />
                  <span className="text-sm text-gray-600">
                    Critical: {branch.critical_stockouts}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Bar Chart */}
            <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 50, left: 50, bottom: 20 }}
        >
          <XAxis
            dataKey="branch_name"
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            label={{
              value: 'Total Stockouts',
              angle: -90,
              position: 'insideLeft',
              offset: -35,
              style: { textAnchor: 'middle' }
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: 'Critical Stockouts',
              angle: 90,
              position: 'insideRight',
              offset: -35,
              style: { textAnchor: 'middle' }
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            yAxisId="left"
            dataKey="stockout_items"
            name="Total Stockouts"
            fill="#f97316"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            yAxisId="right"
            dataKey="critical_stockouts"
            name="Critical Stockouts"
            fill="#ef4444"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StockoutsAnalysis;