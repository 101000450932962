import {authService} from "./axios";

export const splitAndCapitalize = (str) => {
    !str && (str = '')
    return str.replace('_', ' ').replace(/\b\w/g, c => c.toUpperCase())
}

// Basic Indian number formatting
const formatIndianNumber = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 2
});

// Format to lacs/lakhs (1 lac = 100,000)
function formatToLacs(number) {
    const lacs = number / 100000;
    if (lacs >= 1) {
        return `${formatIndianNumber.format(lacs)} lac${lacs === 1 ? '' : 's'}`;
    }
    return formatIndianNumber.format(number);
}

// Format to crores (1 crore = 10,000,000)
function formatToCrores(number) {
    const crores = number / 10000000;
    if (crores >= 1) {
        return `${formatIndianNumber.format(crores)} crore${crores === 1 ? '' : 's'}`;
    }
    return formatToLacs(number);
}

// Format to the most appropriate Indian unit (automatically choose between lacs and crores)
function formatToIndianSystem(number) {
    const crores = number / 10000000;
    if (crores >= 1) {
        return formatToCrores(number);
    }
    return formatToLacs(number);
}

// // Example usage:
// console.log(formatIndianNumber.format(123456));     // "1,23,456"
// console.log(formatToLacs(234567));                  // "2.35 lacs"
// console.log(formatToCrores(12345678));              // "1.23 crores"
// console.log(formatToIndianSystem(123456789));       // "12.35 crores"
// console.log(formatToIndianSystem(234567));          // "2.35 lacs"

// For currency formatting
const formatIndianCurrency = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 2
}).format;

// Format currency to lacs
function formatCurrencyToLacs(number) {
    const lacs = number / 100000;
    if (lacs >= 1) {
        return `₹${formatIndianNumber.format(lacs)} lac${lacs === 1 ? '' : 's'}`;
    }
    return formatIndianCurrency.format(number);
}

// // Format currency to crores
// function formatCurrencyToCrores(number) {
//     const crores = number / 10000000;
//     if (crores >= 1) {
//         return `₹${formatIndianNumber.format(crores)} crore${crores === 1 ? '' : 's'}`;
//     }
//     return formatCurrencyToLacs(number);
// }
//
// console.log({formatToIndianSystem})

export {formatToLacs, formatToCrores, formatCurrencyToLacs, formatToIndianSystem as formatLargeNumber};

// // Example currency usage:
// console.log(formatIndianCurrency.format(123456));   // "₹1,23,456"
// console.log(formatCurrencyToLacs(234567));          // "₹2.35 lacs"
// console.log(formatCurrencyToCrores(12345678));      // "₹1.23 crores"


// Get token from cookies
export const getAccessToken = () => {
  const cookies = document.cookie.split(';');
  const tokenCookie = cookies.find(cookie => cookie.trim().startsWith('access_token='));
  return tokenCookie ? tokenCookie.split('=')[1].trim() : null;
};

// Verify token with backend
export const verifyAuth = async () => {
  try {
    const token = getAccessToken();
    if (!token) return {
        isAuthValid: false,
        data: null
    };

      const res = await authService.verifyToken();

    return {
        isAuthValid: true,
        data: res
    };
  } catch (error) {
    console.error('Auth verification failed:', error);
    return false;
  }
};