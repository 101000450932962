import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Terminal, Sparkles, Zap, Brain, Bot, BarChart3, TrendingUp, ShoppingBag, Calendar, Activity } from 'lucide-react';

const features = [
  {
    title: "Festival Sales Analysis",
    query: "Compare Diwali vs Dussehra sales performance",
    steps: [
      "Analyzing seasonal patterns...",
      "Comparing YoY metrics...",
      "Found key insights!"
    ],
    response: {
      type: "festival-comparison",
      data: {
        diwali: {
          revenue: "₹2.8Cr",
          growth: 32,
          topCategories: ["Electronics", "Traditional Wear", "Home Decor"]
        },
        dussehra: {
          revenue: "₹1.9Cr",
          growth: 28,
          topCategories: ["Apparel", "Gifts", "Sweets"]
        }
      }
    }
  },
  {
    title: "Regional Performance",
    query: "Show sales distribution across regions",
    steps: [
      "Processing geographic data...",
      "Calculating regional metrics...",
      "Generated insights:"
    ],
    response: {
      type: "region-map",
      data: [
        { region: "North", revenue: "₹85L", growth: 24 },
        { region: "South", revenue: "₹92L", growth: 28 },
        { region: "East", revenue: "₹63L", growth: 19 },
        { region: "West", revenue: "₹78L", growth: 22 }
      ]
    }
  },
  {
    title: "Category Analysis",
    query: "Analyze trending categories this month",
    steps: [
      "Processing inventory data...",
      "Identifying trends...",
      "Found emerging categories:"
    ],
    response: {
      type: "category-trends",
      data: [
        { 
          category: "Sustainable Fashion",
          growth: 45,
          avgOrderValue: "₹2,200",
          topSKUs: ["Organic Cotton Kurta", "Recycled Denim"]
        },
        { 
          category: "Smart Home",
          growth: 38,
          avgOrderValue: "₹4,500",
          topSKUs: ["Smart LED Bundle", "WiFi Security Cam"]
        },
        { 
          category: "Ayurvedic Beauty",
          growth: 32,
          avgOrderValue: "₹1,800",
          topSKUs: ["Natural Face Pack", "Hair Oil"]
        }
      ]
    }
  },
  {
    title: "Price Point Analysis",
    query: "Recommend optimal price points",
    steps: [
      "Analyzing competitor data...",
      "Processing purchase patterns...",
      "Generated recommendations:"
    ],
    response: {
      type: "price-optimization",
      data: {
        segments: [
          { 
            range: "₹500-1500",
            conversion: 4.2,
            elasticity: "High",
            recommendation: "Maintain competitive pricing"
          },
          { 
            range: "₹1500-3000",
            conversion: 2.8,
            elasticity: "Medium",
            recommendation: "Room for 5-8% increase"
          },
          { 
            range: "₹3000+",
            conversion: 1.5,
            elasticity: "Low",
            recommendation: "Focus on value proposition"
          }
        ]
      }
    }
  },
  {
    title: "Customer Segmentation",
    query: "Show customer segments by lifetime value",
    steps: [
      "Processing customer data...",
      "Identifying patterns...",
      "Segments identified:"
    ],
    response: {
      type: "customer-segments",
      data: {
        segments: [
          {
            name: "Premium",
            percentage: 15,
            avgSpend: "₹12,000+",
            characteristics: ["High fashion", "Early adopters", "Urban metro"]
          },
          {
            name: "Regular",
            percentage: 45,
            avgSpend: "₹5,000-12,000",
            characteristics: ["Value seekers", "Family shoppers", "Tier 1 & 2"]
          },
          {
            name: "Budget",
            percentage: 40,
            avgSpend: "< ₹5,000",
            characteristics: ["Price sensitive", "Occasional", "Deal hunters"]
          }
        ]
      }
    }
  }
];

const renderVisualization = (response) => {
  switch (response.type) {
    case "festival-comparison":
      return (
        <motion.div 
          className="grid grid-cols-2 gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {Object.entries(response.data).map(([festival, data]) => (
            <div key={festival} className="bg-gray-800/50 p-4 rounded-xl">
              <h3 className="capitalize text-lg font-medium mb-2">{festival}</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-400">Revenue</span>
                  <span className="text-emerald-400">{data.revenue}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-400">Growth</span>
                  <span className="text-blue-400">+{data.growth}%</span>
                </div>
                <div className="mt-4">
                  <div className="text-sm text-gray-400 mb-1">Top Categories</div>
                  <div className="flex flex-wrap gap-2">
                    {data.topCategories.map(cat => (
                      <span key={cat} className="px-2 py-1 bg-gray-700/50 rounded-full text-xs">
                        {cat}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      );

    case "region-map":
      return (
        <motion.div 
          className="grid grid-cols-2 gap-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {response.data.map(region => (
            <motion.div 
              key={region.region}
              className="bg-gray-800/50 p-4 rounded-xl flex items-center justify-between"
              whileHover={{ scale: 1.02 }}
            >
              <div>
                <div className="text-gray-300">{region.region}</div>
                <div className="text-sm text-gray-500">Revenue: {region.revenue}</div>
              </div>
              <div className="text-emerald-400">+{region.growth}%</div>
            </motion.div>
          ))}
        </motion.div>
      );

    case "category-trends":
      return (
        <motion.div className="space-y-4">
          {response.data.map((category, i) => (
            <motion.div
              key={category.category}
              className="bg-gray-800/50 p-4 rounded-xl"
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: i * 0.1 }}
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-medium">{category.category}</h3>
                <span className="text-emerald-400">+{category.growth}%</span>
              </div>
              <div className="text-sm text-gray-400 mb-2">
                Avg. Order Value: {category.avgOrderValue}
              </div>
              <div className="flex flex-wrap gap-2">
                {category.topSKUs.map(sku => (
                  <span key={sku} className="px-2 py-1 bg-gray-700/50 rounded-full text-xs">
                    {sku}
                  </span>
                ))}
              </div>
            </motion.div>
          ))}
        </motion.div>
      );

    default:
      return null;
  }
};


const JaanchCopilot = () => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      const stepInterval = setInterval(() => {
        setCurrentStep(prev => {
          if (prev >= features[currentFeature].steps.length - 1) {
            clearInterval(stepInterval);
            return prev;
          }
          return prev + 1;
        });
      }, 800);

      const featureInterval = setInterval(() => {
        setCurrentFeature(prev => (prev + 1) % features.length);
        setCurrentStep(0);
      }, 6000);

      return () => {
        clearInterval(stepInterval);
        clearInterval(featureInterval);
      };
    }
  }, [currentFeature, isFocused]);

  const terminalVariants = {
    initial: { 
      opacity: 0, 
      y: 20,
      scale: 0.95
    },
    animate: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black text-white relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-emerald-500/5 via-transparent to-transparent" />
      </div>

      <div className="container mx-auto px-4 py-20 relative z-10">
        {/* Hero Section */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 mb-6"
          >
            <Bot className="w-4 h-4 text-blue-400" />
            <span className="text-sm text-blue-400">AI Analytics for Indian Markets</span>
          </motion.div>

          <motion.h1 
            className="text-4xl md:text-6xl font-bold mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-emerald-400 bg-clip-text text-transparent">
              Analytics that Speaks Your Language
            </span>
          </motion.h1>
          
          <motion.p 
            className="text-gray-400 max-w-2xl mx-auto mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Ask questions in plain language. Get instant insights tailored for Indian business context.
          </motion.p>
        </div>

        {/* Main Terminal */}
        <div className="max-w-4xl mx-auto">
          <motion.div 
            initial="initial"
            animate="animate"
            exit="exit"
            variants={terminalVariants}
            className="bg-gray-900/50 backdrop-blur-xl border border-gray-800/50 rounded-2xl overflow-hidden shadow-2xl"
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
          >
            {/* Terminal Header */}
            <div className="flex items-center justify-between px-4 py-3 bg-gray-800/50 border-b border-gray-700/50">
              <div className="flex gap-2">
                <div className="w-3 h-3 rounded-full bg-red-500/50" />
                <div className="w-3 h-3 rounded-full bg-yellow-500/50" />
                <div className="w-3 h-3 rounded-full bg-green-500/50" />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">jaanch@analytics</span>
                <div className="w-2 h-2 rounded-full bg-emerald-500 animate-pulse" />
              </div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <Calendar className="w-4 h-4" />
                <span>Live Demo</span>
              </div>
            </div>

            {/* Terminal Content */}
            <div className="p-6 font-mono">
              <AnimatePresence mode="wait">
                <motion.div
                  key={`${currentFeature}-${currentStep}`}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                  className="space-y-4"
                >
                  {/* Query Input */}
                  <div className="flex items-center gap-2 text-gray-400">
                    <Zap className="w-4 h-4 text-yellow-400" />
                    <span className="text-sm">$</span>
                    <span className="text-emerald-400">{features[currentFeature].query}</span>
                    <span className="animate-pulse">▌</span>
                  </div>

                  {/* Processing Steps */}
                  <div className="pl-6">
                    <div className="text-blue-400 mb-4 flex items-center gap-2">
                      <Activity className="w-4 h-4 animate-spin" />
                      {features[currentFeature].steps[currentStep]}
                    </div>
                    
                    {/* Visualization */}
                    {currentStep === features[currentFeature].steps.length - 1 && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                        className="mt-6"
                      >
                        {renderVisualization(features[currentFeature].response)}
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>

          {/* Quick Actions */}
          <motion.div 
            className="mt-8 flex justify-center gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <button 
              onClick={() => setIsFocused(!isFocused)}
              className="px-4 py-2 bg-gray-800/50 border border-gray-700/50 rounded-lg text-gray-400 hover:text-white hover:border-gray-600 transition-all"
            >
              {isFocused ? "Resume Demo" : "Pause Demo"}
            </button>
            <button className="px-4 py-2 bg-blue-500/10 border border-blue-500/20 rounded-lg text-blue-400 hover:bg-blue-500/20 transition-all">
              Get Started Free
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default JaanchCopilot;