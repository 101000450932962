import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AnimatePresence, LayoutGroup, motion} from 'framer-motion';
import {Activity, LineChart, Plus, RefreshCcw, Sparkles, Trash2} from 'lucide-react';
import {categoryIcons} from './Layout';
import {CHART_COMPONENTS} from './charts';
import KPIContainerSkeleton from './skeletons/KPIContainerSkeleton';
import {useSelector} from 'react-redux';
import {kpiService} from '../utils/axios';

const KPIContainer = ({ onRemoveKPI, onAddKPI }) => {
  const {user: {organization_id}} = useSelector(state => state.user);
  const {selectedKPIs} = useSelector((state) => state.kpi);

  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [hoveredKPI, setHoveredKPI] = useState(null);
  const pendingRequests = useRef({});
  const dateRange = useSelector((state) => state.dateRange);

  // Memoize the current date range for comparison
  const memoizedDateRange = useMemo(() => ({
    from: new Date(dateRange.from).toISOString(),
    to: new Date(dateRange.to).toISOString(),
    label: dateRange.label
  }), [dateRange.from, dateRange.to, dateRange.label]);

  // Memoized KPI data fetching function
  const getKPIData = useCallback(async (kpi) => {
    const cacheKey = `${kpi.name}-${memoizedDateRange.from}-${memoizedDateRange.to}`;

    if (chartData[cacheKey] || pendingRequests.current[cacheKey]) {
      return;
    }

    try {
      pendingRequests.current[cacheKey] = true;
      setLoading(prev => ({ ...prev, [kpi.name]: true }));
      setError(prev => ({ ...prev, [kpi.name]: null }));

      const data = await kpiService.getKPIData(kpi.endpoint, {
            organization_id: organization_id,
            start_date: memoizedDateRange.from.split('T')[0],
            end_date: memoizedDateRange.to.split('T')[0]
      });

      setChartData(prevChartData => ({
        ...prevChartData,
        [cacheKey]: data
      }));
    } catch (error) {
      console.error(`Error fetching data for KPI ${kpi.name}:`, error);
      setError(prev => ({
        ...prev,
        [kpi.name]: 'Failed to load data'
      }));
      setChartData(prevChartData => ({
        ...prevChartData,
        [cacheKey]: []
      }));
    } finally {
      pendingRequests.current[cacheKey] = false;
      setLoading(prev => ({ ...prev, [kpi.name]: false }));
    }
  }, [memoizedDateRange, organization_id, chartData]);

  // Initialize pending requests
  useEffect(() => {
    selectedKPIs.forEach(kpi => {
      const cacheKey = `${kpi.name}-${memoizedDateRange.from}-${memoizedDateRange.to}`;
      pendingRequests.current[cacheKey] = false;
    });
  }, [selectedKPIs, memoizedDateRange]);

  // Fetch data when KPIs or date range changes
  useEffect(() => {
    selectedKPIs.forEach(kpi => {
      const cacheKey = `${kpi.name}-${memoizedDateRange.from}-${memoizedDateRange.to}`;
      if (!pendingRequests.current[cacheKey]) {
        getKPIData(kpi);
      }
    });
  }, [selectedKPIs, memoizedDateRange, getKPIData]);

  const getChartComponent = useCallback((kpi) => {
    if (loading[kpi.name]) {
      return (
          <div className="h-full">
            <KPIContainerSkeleton/>
          </div>
      );
    }

    if (error[kpi.name]) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center justify-center h-full"
        >
          <div className="bg-red-500/10 backdrop-blur-sm border border-red-500/20 rounded-xl p-4 flex items-center gap-3">
            <Activity className="w-5 h-5 text-red-400" />
            <p className="text-red-400 font-medium">{error[kpi.name]}</p>
          </div>
        </motion.div>
      );
    }

    const cacheKey = `${kpi.name}-${memoizedDateRange.from}-${memoizedDateRange.to}`;
    const data = chartData[cacheKey] || [];
    const ChartComponent = CHART_COMPONENTS[kpi.name];

    return ChartComponent ? <ChartComponent data={data}/> : null;
  }, [loading, error, chartData, memoizedDateRange]);

  const getIcon = useCallback((category) => {
    const Icon = categoryIcons[category] || LineChart;
    return <Icon className="w-5 h-5" />;
  }, []);

  return (
    <LayoutGroup>
      <motion.div
        layout
        className="relative p-8 lg:p-10 rounded-3xl grid grid-cols-1 lg:grid-cols-2 gap-8 w-full h-full"
        style={{
          background: 'linear-gradient(to bottom right, rgba(17, 24, 39, 0.7), rgba(17, 24, 39, 0.9))',
          backdropFilter: 'blur(20px)',
          boxShadow: '0 0 80px rgba(59, 130, 246, 0.1)'
        }}
      >
        {selectedKPIs.length === 0 ? (
          <motion.div className="col-span-2 min-h-[500px] flex items-center justify-center">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="group relative overflow-hidden rounded-2xl p-1"
              onClick={onAddKPI}
              style={{
                background: 'linear-gradient(45deg, rgba(59, 130, 246, 0.5), rgba(147, 51, 234, 0.5))'
              }}
            >
              <div className="relative bg-gray-900 rounded-xl px-16 py-12 flex flex-col items-center gap-6">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-all duration-500" />
                <motion.div
                  className="relative rounded-xl p-6"
                  animate={{
                    boxShadow: ['0 0 20px rgba(59, 130, 246, 0.3)', '0 0 40px rgba(147, 51, 234, 0.3)'],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                >
                  <Sparkles className="w-12 h-12 text-blue-400" />
                </motion.div>
                <p className="text-2xl font-medium bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
                  Create Your First Insight
                </p>
              </div>
            </motion.button>
          </motion.div>
      ) : (
          <>
            <div className="col-span-1 lg:col-span-2 flex justify-start">
              <div className="px-4 py-2 bg-gray-800 rounded-lg text-gray-300">
                <span className="font-medium">{dateRange.label}</span>
                <span className="mx-2">•</span>
                <span>
                  {new Date(dateRange.from).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                  {' - '}
                  {new Date(dateRange.to).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </span>
              </div>
            </div>
            <AnimatePresence>
              {selectedKPIs.map((kpi) => (
                <motion.div
                  key={kpi.id}
                  layout
                  className="group/card relative"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  whileHover={{ translateY: -5 }}
                  onHoverStart={() => setHoveredKPI(kpi.id)}
                  onHoverEnd={() => setHoveredKPI(null)}
                >
                  <motion.div
                    className="absolute inset-0 rounded-2xl opacity-0 group-hover/card:opacity-100 transition-opacity duration-500"
                    style={{
                      background: 'linear-gradient(45deg, rgba(59, 130, 246, 0.1), rgba(147, 51, 234, 0.1))'
                    }}
                  />
                  <div className="relative min-h-[450px] rounded-2xl p-1"
                    style={{
                      background: hoveredKPI === kpi.id ?
                        'linear-gradient(45deg, rgba(59, 130, 246, 0.3), rgba(147, 51, 234, 0.3))' :
                        'linear-gradient(45deg, rgba(31, 41, 55, 0.5), rgba(31, 41, 55, 0.8))'
                    }}>
                    <div className="h-full bg-gray-900 rounded-xl">
                      <div className="px-6 py-4 border-b border-gray-800/50">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <div className="p-2 rounded-lg bg-blue-500/10">
                              {categoryIcons[kpi.category] ?
                                React.createElement(categoryIcons[kpi.category], { className: "w-5 h-5 text-blue-400" }) :
                                <LineChart className="w-5 h-5 text-blue-400" />}
                            </div>
                            <h3 className="text-lg font-medium text-gray-200">{kpi.name}</h3>
                          </div>
                          <div className="flex items-center gap-2 opacity-0 group-hover/card:opacity-100 transition-opacity">
                            <motion.button
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              className="p-2 rounded-lg hover:bg-blue-500/10 transition-colors"
                            >
                              <RefreshCcw className="w-4 h-4 text-blue-400" />
                            </motion.button>
                            <motion.button
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              className="p-2 rounded-lg hover:bg-red-500/10 transition-colors"
                              onClick={() => onRemoveKPI(kpi)}
                            >
                              <Trash2 className="w-4 h-4 text-red-400" />
                            </motion.button>
                          </div>
                        </div>
                      </div>
                      <div className="p-6">
                        {getChartComponent(kpi)}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}

              <motion.button
                layout
                className="group/add relative min-h-[450px] rounded-2xl"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onAddKPI}
              >
                <div className="absolute inset-0 rounded-2xl opacity-0 group-hover/add:opacity-100 transition-opacity duration-500"
                  style={{
                    background: 'linear-gradient(45deg, rgba(59, 130, 246, 0.1), rgba(147, 51, 234, 0.1))'
                  }}
                />
                <div className="relative h-full p-1"
                  style={{
                    background: 'linear-gradient(45deg, rgba(31, 41, 55, 0.5), rgba(31, 41, 55, 0.8))'
                  }}>
                  <div className="h-full bg-gray-900 rounded-xl flex items-center justify-center">
                    <div className="flex flex-col items-center gap-6">
                      <motion.div
                        className="rounded-xl p-6"
                        animate={{
                          boxShadow: ['0 0 20px rgba(59, 130, 246, 0.1)', '0 0 40px rgba(147, 51, 234, 0.1)'],
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: "reverse"
                        }}
                      >
                        <Plus className="w-8 h-8 text-blue-400" />
                      </motion.div>
                      <p className="text-lg font-medium text-gray-400 group-hover/add:text-gray-300">
                        Add New Insight
                      </p>
                    </div>
                  </div>
                </div>
              </motion.button>
            </AnimatePresence>
          </>
        )}
      </motion.div>
    </LayoutGroup>
  );
};

export default KPIContainer;