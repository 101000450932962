import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HelpCircle, ChevronUp, Bot, Search, Plus, Minus } from 'lucide-react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const faqs = [
    {
      category: 'General',
      question: 'What is JaanchAI?',
      answer: `JaanchAI is a cutting-edge AI-driven platform designed to simplify business analytics for retailers. It helps businesses optimize key areas such as pricing strategies, promotional effectiveness, competitor analysis, inventory management, and sales forecasting.`,
      tags: ['overview', 'platform']
    },
    {
      category: 'Features',
      question: 'How does JaanchAI improve sales?',
      answer: `JaanchAI boosts sales by leveraging advanced machine learning models to analyze customer behavior, sales trends, and product performance. It identifies high-potential opportunities for growth through customer segmentation and personalized product recommendations.`,
      tags: ['sales', 'analytics']
    },
    {
      category: 'Integration',
      question: 'Can I integrate JaanchAI with my existing tools?',
      answer: `Yes! JaanchAI seamlessly integrates with popular business tools including e-commerce systems, CRM software, and marketing automation platforms. It also offers native integrations with WhatsApp, Telegram, and email for automated reporting.`,
      tags: ['integration', 'setup']
    },
    {
      category: 'Security',
      question: 'Is JaanchAI secure?',
      answer: `Security is our top priority. We use industry-leading encryption standards for data protection, both at rest and in transit. Regular security audits and robust access controls ensure your business data remains safe.`,
      tags: ['security', 'privacy']
    },
    {
      category: 'Getting Started',
      question: 'How do I get started with JaanchAI?',
      answer: `Getting started is simple. Request a personalized demo through our platform, and our team will guide you through the features tailored to your business needs. We'll show you how to optimize operations and drive growth.`,
      tags: ['onboarding', 'setup']
    },
    {
      category: 'Use Cases',
      question: 'What kind of businesses can benefit?',
      answer: `JaanchAI supports businesses of all sizes, from small e-commerce stores to large retail chains. Our platform provides data-driven insights for pricing optimization, inventory management, and customer retention strategies.`,
      tags: ['business', 'use-cases']
    }
  ];

  const filteredFaqs = faqs.filter(faq => 
    faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchQuery.toLowerCase()) ||
    faq.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <section className="relative min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-900 to-black overflow-hidden py-20">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-purple-500/5 to-transparent" />
      </div>

      <div className="container relative mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <motion.div 
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-blue-500/10 border border-blue-500/20 mb-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Bot className="w-5 h-5 text-blue-400" />
            <span className="text-blue-400 text-sm">Got Questions?</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent">
              Frequently Asked
            </span>
            <br />
            <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Questions
            </span>
          </h2>

          <div className="max-w-xl mx-auto relative">
            <input
              type="text"
              placeholder="Search questions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-gray-800/50 border border-gray-700/50 text-gray-300 rounded-xl px-12 py-3 focus:outline-none focus:border-blue-500/50 transition-colors"
            />
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-500" />
          </div>
        </motion.div>

        <div className="max-w-3xl mx-auto space-y-4">
          <AnimatePresence>
            {filteredFaqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: index * 0.1 }}
                className="group"
              >
                <motion.div className="relative">
                  <motion.div 
                    className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-2xl opacity-0 group-hover:opacity-100 transition-all duration-300"
                    layoutId={`bg-${index}`}
                  />
                  <div className="relative bg-gray-800/50 backdrop-blur-xl border border-gray-700/50 rounded-xl overflow-hidden">
                    <button
                      onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                      className="w-full p-6 text-left focus:outline-none"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="text-xs text-blue-400 mb-2">{faq.category}</div>
                          <h3 className="text-lg font-medium text-gray-200 group-hover:text-white transition-colors">
                            {faq.question}
                          </h3>
                        </div>
                        <div className="ml-4">
                          {activeIndex === index ? (
                            <Minus className="w-5 h-5 text-gray-400" />
                          ) : (
                            <Plus className="w-5 h-5 text-gray-400" />
                          )}
                        </div>
                      </div>
                    </button>

                    <AnimatePresence>
                      {activeIndex === index && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <div className="px-6 pb-6">
                            <p className="text-gray-400 mb-4">{faq.answer}</p>
                            <div className="flex gap-2">
                              {faq.tags.map(tag => (
                                <span 
                                  key={tag}
                                  className="px-2 py-1 bg-gray-700/50 rounded-full text-xs text-gray-400"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default FAQ;