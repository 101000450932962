import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    console.error('Error Boundary caught an error:', error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    const { level = 'app', children } = this.props;

    if (hasError) {
      // For app-level errors, show full-screen error
      if (level === 'app') {
        return (
          <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
            <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8 text-center">
              <FaExclamationTriangle className="mx-auto text-6xl text-yellow-500 mb-4" />
              <h1 className="text-2xl font-bold text-white mb-4">
                Something went wrong!
              </h1>
              <p className="text-gray-400 mb-6">
                We apologize for the inconvenience. Please try refreshing the page.
              </p>
              {error && (
                <div className="bg-gray-700 p-4 rounded-md text-left mb-6">
                  <p className="text-red-400 text-sm font-mono">
                    {error.toString()}
                  </p>
                </div>
              )}
              <button
                onClick={() => window.location.reload()}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-200"
              >
                Refresh Page
              </button>
            </div>
          </div>
        );
      }

      // For dashboard-level errors, show contained error message
      return (
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 m-4">
          <div className="flex flex-col items-center justify-center text-center">
            <FaExclamationTriangle className="text-4xl text-yellow-500 mb-3" />
            <h2 className="text-xl font-semibold text-white mb-2">
              Dashboard Error
            </h2>
            <p className="text-gray-400 mb-4">
              There was an error loading this section. Please try again.
            </p>
            {error && (
              <div className="w-full bg-gray-700 p-3 rounded-md text-left mb-4 max-w-2xl">
                <p className="text-red-400 text-sm font-mono">
                  {error.toString()}
                </p>
              </div>
            )}
            <button
              onClick={() => window.location.reload()}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-200"
            >
              Retry
            </button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;