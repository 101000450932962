import {createSlice} from '@reduxjs/toolkit';

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState: {
    from: new Date(new Date().getFullYear() - 1, 0, 1),
    to: new Date(new Date().getFullYear() - 1, 11, 31),
    label: 'Last Year',
    isRelative: true,
    relativeValue: 'lastYear'
  },
  reducers: {
    setDateRange: (state, action) => {
      return action.payload;
    }
  }
});

export const {setDateRange} = dateRangeSlice.actions;
export default dateRangeSlice.reducer;