import React, {useState} from 'react';
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const TopCustomersChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('net_amount');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
          <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Clean and process the data
  const processedData = data
    .filter(customer => customer && customer.customer_name)
    .map(customer => {
      // Clean up customer name: remove extra spaces and truncate
      const fullName = customer.customer_name.trim();
      const nameWithoutBrackets = fullName.replace(/\s*\([^)]*\)/g, ''); // Remove text in brackets
      const cleanName = nameWithoutBrackets
        .replace(/-[^-]*$/, '') // Remove text after last hyphen
        .trim()
        .split(/\s+/)
        .slice(0, 2) // Take first two words
        .join(' ');

      return {
        ...customer,
        customer_name: cleanName,
        full_name: fullName, // Keep full name for tooltip
        net_amount: Number((customer.net_amount / 100000)),
        contribution_percentage: Number(customer.contribution_percentage?.toFixed(2) || 0)
      };
    })
    .slice(1, 10);

  if (processedData.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No customer data available</p>
      </div>
    );
  }

  const metrics = [
      {key: 'net_amount', name: 'Net Amount (Lacs)', color: '#2563eb'},
    { key: 'number_of_transactions', name: 'Number of Transactions', color: '#16a34a' },
    { key: 'contribution_percentage', name: 'Contribution %', color: '#7c3aed' }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const customerData = processedData.find(d => d.customer_name === label);
      if (!customerData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg max-w-sm">
          <p className="font-semibold text-gray-800 break-words">{customerData.full_name}</p>
          <div className="mt-2 space-y-1">
            <p className="text-blue-600">
              Total Amount: ₹{(payload[0].value * 100000).toLocaleString()}
            </p>
            <p className="text-green-600">
              Transactions: {customerData.number_of_transactions}
            </p>
            <p className="text-purple-600">
              Contribution: {customerData.contribution_percentage}%
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="mb-4 flex flex-wrap gap-2">
        {metrics.map(metric => (
          <button
            key={metric.key}
            onClick={() => setActiveMetric(metric.key)}
            className={`px-3 py-1 rounded-full text-sm ${
              activeMetric === metric.key
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            {metric.name}
          </button>
        ))}
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
        >
          <XAxis
            dataKey="customer_name"
            angle={-35}
            textAnchor="end"
            height={60}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey={activeMetric}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TopCustomersChart;