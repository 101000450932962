import React, {useEffect, useMemo, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {ArrowLeft, ArrowRight, MessageSquare, Plus, Shield, X} from 'lucide-react';
import {useSelector} from 'react-redux';
import {authService} from "../utils/axios";

const initialFormValues = {
  email: '',
  phone: '',
  full_name: '',
  role: 'staff',
  password: ''
}

const WhatsAppAnalytics = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [connectedUsers, setConnectedUsers] = useState([]);
  const dropdownRef = useRef(null);
  const { user } = useSelector(state => state.user);
  const [userForm, setUserForm] = useState(initialFormValues);

  const canAddUsers = user.role === 'admin' || user.role === 'manager';

  const availableRoles = useMemo(() => {
    if (user.role === 'admin') {
      return ["staff", "manager", "admin"];
    } else if (user.role === 'manager') {
      return ["staff"];
    } else {
      return [];
    }
  }, [user.role]);

  const fetchConnectedUsers = async () => {
    try {
      const data = await authService.getManagedUsers()
      setConnectedUsers(data);
    } catch (error) {
      setError(error.detail || "Could not get organization users. Please try again in a while.");
      console.error('Error fetching connected users:', error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      fetchConnectedUsers();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setShowInput(false);
    setShowOtpInput(false);
    setUserForm(initialFormValues);
    setOtp('');
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const data = await authService.requestOTPForAddingUserToOrg({
        user_id: user.id,
        organization_id: user.organization_id,
        ...userForm
      });

      setShowOtpInput(true);
      setShowInput(false);
    } catch (error) {
      setError(error.detail || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const res = await authService.verifyOTPForAddingUserToOrg({
        user_id: user.id,
        otp_code: otp
      })

      setSuccess('WhatsApp connected successfully!');
      // Refresh connected users list
      fetchConnectedUsers()

      // Reset form and go back to main screen
      setTimeout(() => {
        setShowOtpInput(false);
        setOtp('');
        setSuccess('');
      }, 2000);
    } catch (error) {
      setError(error.detail || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (showOtpInput) {
      setShowOtpInput(false);
      setShowInput(true);
    } else {
      setShowInput(false);
      setUserForm(initialFormValues);
    }
    setError('');
    setSuccess('');
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 py-2 px-3 bg-gray-800/80 backdrop-blur-xl border border-gray-700/50 text-white rounded-xl shadow-lg hover:shadow-emerald-500/10 transition-all duration-300"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <MessageSquare className="w-4 h-4 text-emerald-400" />
        <span className="text-sm font-medium text-gray-300">WhatsApp</span>
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.95 }}
            style={{ position: 'absolute', top: '100%', right: 0 }}
            className="mt-2 w-72 bg-gray-900/95 backdrop-blur-xl border border-gray-800/50 rounded-xl shadow-xl"
          >
            <div className="px-4 py-3 border-b border-gray-800/50 flex justify-between items-center">
              <div className="flex items-center gap-2">
                <Shield className="w-4 h-4 text-emerald-400" />
                <span className="text-sm font-medium text-gray-200">
                  {showOtpInput ? 'Verify OTP' : showInput ? 'Connect Device' : 'Connected Devices'}
                </span>
              </div>
              <button onClick={handleClose} className="text-gray-500 hover:text-gray-400">
                <X className="w-4 h-4" />
              </button>
            </div>

            <div className="p-4">
              {error && (
                <div className="mb-3 p-2 bg-red-500/10 border border-red-500/20 rounded-lg">
                  <p className="text-sm text-red-400">{error}</p>
                </div>
              )}

              {success && (
                <div className="mb-3 p-2 bg-emerald-500/10 border border-emerald-500/20 rounded-lg">
                  <p className="text-sm text-emerald-400">{success}</p>
                </div>
              )}

              {showOtpInput ? (
                <motion.form
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onSubmit={handleOtpSubmit}
                  className="space-y-3"
                >
                  <div className="flex items-center gap-2 mb-3">
                    <button
                      type="button"
                      onClick={handleBack}
                      className="text-gray-400 hover:text-gray-300"
                    >
                      <ArrowLeft className="w-4 h-4" />
                    </button>
                    <span className="text-sm text-gray-300">Back</span>
                  </div>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="w-full bg-gray-800/50 border border-gray-800/50 focus:border-emerald-500/30 rounded-lg px-3 py-2 text-sm text-gray-300 placeholder-gray-500 focus:outline-none"
                    disabled={loading}
                  />
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-gray-800/50 text-gray-300 rounded-lg py-2 text-sm font-medium hover:bg-gray-800 transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50"
                  >
                    {loading ? 'Verifying...' : 'Verify OTP'}
                    <ArrowRight className="w-3 h-3" />
                  </button>
                </motion.form>
              ) : showInput ? (
                  <motion.form
                      initial={{opacity: 0}}
                      animate={{opacity: 1}}
                      exit={{opacity: 0}}
                      onSubmit={handleSubmit}
                      className="space-y-3"
                  >
                    <div className="flex items-center gap-2 mb-3">
                      <button
                          type="button"
                          onClick={handleBack}
                          className="text-gray-400 hover:text-gray-300"
                      >
                        <ArrowLeft className="w-4 h-4"/>
                      </button>
                      <span className="text-sm text-gray-300">Back to devices</span>
                    </div>

                    <input
                        type="text"
                        value={userForm.full_name}
                        onChange={(e) => setUserForm(prev => ({...prev, full_name: e.target.value}))}
                        placeholder="Full Name"
                        className="w-full bg-gray-800/50 border border-gray-800/50 focus:border-emerald-500/30 rounded-lg px-3 py-2 text-sm text-gray-300 placeholder-gray-500 focus:outline-none"
                        disabled={loading}
                        required
                    />

                    <input
                        type="email"
                        value={userForm.email}
                        onChange={(e) => setUserForm(prev => ({...prev, email: e.target.value}))}
                        placeholder="Email"
                        className="w-full bg-gray-800/50 border border-gray-800/50 focus:border-emerald-500/30 rounded-lg px-3 py-2 text-sm text-gray-300 placeholder-gray-500 focus:outline-none"
                        disabled={loading}
                        required
                    />

                    <input
                        type="tel"
                        value={userForm.phone}
                        onChange={(e) => setUserForm(prev => ({...prev, phone: e.target.value}))}
                        placeholder="Phone Number"
                        className="w-full bg-gray-800/50 border border-gray-800/50 focus:border-emerald-500/30 rounded-lg px-3 py-2 text-sm text-gray-300 placeholder-gray-500 focus:outline-none"
                        disabled={loading}
                        required
                    />

                    <select
                        value={userForm.role}
                        onChange={(e) => setUserForm(prev => ({...prev, role: e.target.value}))}
                        className="w-full bg-gray-800/50 border border-gray-800/50 focus:border-emerald-500/30 rounded-lg px-3 py-2 text-sm text-gray-300 focus:outline-none"
                        disabled={loading}
                        required
                    >
                      {availableRoles.map(role => (
                          <option key={role} value={role} className="bg-gray-800">
                            {role}
                          </option>
                      ))}
                    </select>

                    <input
                        type="password"
                        value={userForm.password}
                        onChange={(e) => setUserForm(prev => ({...prev, password: e.target.value}))}
                        placeholder="Password"
                        className="w-full bg-gray-800/50 border border-gray-800/50 focus:border-emerald-500/30 rounded-lg px-3 py-2 text-sm text-gray-300 placeholder-gray-500 focus:outline-none"
                        disabled={loading}
                        required
                    />

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-gray-800/50 text-gray-300 rounded-lg py-2 text-sm font-medium hover:bg-gray-800 transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50"
                    >
                      {loading ? 'Sending...' : 'Add User'}
                      <ArrowRight className="w-3 h-3"/>
                    </button>
                  </motion.form>
              ) : (
                  <div
                      className="space-y-2 max-h-[300px] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800/50">
                    {canAddUsers && (
                        <button
                            onClick={() => setShowInput(true)}
                            className="w-full flex items-center justify-center gap-2 py-2 text-sm text-gray-400 hover:text-gray-300"
                        >
                          <Plus className="w-4 h-4"/>
                          Add Device
                        </button>
                    )}

                    {connectedUsers?.users?.map((user) => (
                        <motion.div
                            key={user.id}
                            initial={{opacity: 0, y: 5}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: 0.1}}
                            className="p-3 rounded-lg bg-gray-800/50 border border-gray-800/50"
                        >
                          <div className="flex flex-col gap-1.5">
                            <div className="flex justify-between items-start">
                              <span className="text-sm font-medium text-gray-200">{user.full_name}</span>
                              <div className="flex items-center gap-2">
                                <span className="text-xs px-1.5 py-0.5 rounded-full bg-gray-700/50 text-gray-400">
                                  {user.role}
                                </span>
                                <span className={`text-xs px-1.5 py-0.5 rounded-full ${
                                    user.status === "active" ? "bg-emerald-500/10 text-emerald-400" :
                                        user.status === "pending" ? "bg-yellow-500/10 text-yellow-400" :
                                            user.status === "inactive" ? "bg-red-500/10 text-red-400" :
                                                "bg-gray-700/50 text-gray-400"
                                }`}>
                                  {user.status}
                                </span>
                              </div>
                            </div>
                            <span className="text-sm text-gray-400">{user.phone}</span>
                          </div>
                        </motion.div>
                    ))}
                  </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WhatsAppAnalytics;