import React, {useState} from 'react';
import {
    Bar,
    BarChart,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {COLORS} from "../../utils/constants";
import {formatLargeNumber} from "../../utils/functions";

const PackGradesChart = ({ data = {} }) => {
  const [activeMetric, setActiveMetric] = useState('total_revenue');
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [displayCount, setDisplayCount] = useState(12);

  if (!data.overall_metrics || data.overall_metrics.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
          <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  const metrics = [
    {
      key: 'total_revenue',
        name: 'Revenue (Lacs)',
      color: '#16a34a',
      format: (value) => `₹${value.toFixed(2)}L`
    },
    {
      key: 'avg_price_per_unit',
      name: 'Avg Price/Unit',
      color: '#7c3aed',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'units_sold',
      name: 'Units Sold',
      color: '#ea580c',
      format: (value) => value.toLocaleString()
    }
  ];

  const processedData = [...data.overall_metrics]
      .filter(item =>
          item.pack_grade !== '.' &&
          !item.pack_grade.includes('0') &&
          item.revenue_share_percentage >= 0.1
      )
      .map(item => ({
        ...item,
        total_revenue: item.total_revenue / 100000,
      }))
      .slice(0, displayCount);

  const barChartData = processedData.sort((a, b) => b[activeMetric] - a[activeMetric])

  const pieChartData = processedData.sort((a, b) => b.revenue_share_percentage - a.revenue_share_percentage)

    // Process monthly trends with revenue in Lacs
  const monthlyTrends = data.monthly_trends || [];
  const processedTrends = selectedGrade ?
    monthlyTrends
      .filter(trend => trend.pack_grade === selectedGrade)
      .sort((a, b) => new Date(a.bill_date) - new Date(b.bill_date))
      .map(trend => ({
        ...trend,
        date: new Date(trend.bill_date).toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
          net_amount: trend.net_amount / 100000, // Convert to Lacs
      })) : [];

  const summaryStats = {
    totalRevenue: data.overall_metrics.reduce((sum, item) => sum + item.total_revenue, 0),
    totalUnits: data.overall_metrics.reduce((sum, item) => sum + item.units_sold, 0)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const gradeData = processedData.find(d => d.pack_grade === label);
      if (!gradeData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">Pack Grade: {gradeData.pack_grade}</p>
          <div className="space-y-1">
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(gradeData[metric.key])}
              </p>
            ))}
            <div className="border-t border-gray-200 mt-2 pt-2">
              <p className="text-sm text-gray-600">
                Avg Transaction: ₹{gradeData.avg_transaction_value.toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                Sales Count: {gradeData.number_of_sales.toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                Unique Products: {gradeData.unique_products.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const TrendTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{label}</p>
          <div className="space-y-1">
            <p className="text-sm text-blue-600">
              Net Amount: ₹{payload[0].value.toFixed(2)}L
            </p>
            <p className="text-sm text-green-600">
              Quantity: {payload[1].value.toFixed(2)}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
      <div className="p-4 bg-white rounded-lg shadow-lg">
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div className="bg-blue-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-blue-800">Total Revenue</h3>
            <p className="text-lg font-bold text-blue-600">
              {formatLargeNumber(summaryStats.totalRevenue)}
            </p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-green-800">Total Units</h3>
            <p className="text-lg font-bold text-green-600">
              {summaryStats.totalUnits.toLocaleString()}
            </p>
          </div>
        </div>

        {/*Pie chart for revenue share %*/}
          <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Revenue Share Distribution</h3>
              <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                      <Pie
                          data={pieChartData}  // Use pieChartData instead of processedData
                          dataKey="revenue_share_percentage"
                          nameKey="pack_grade"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          fill="#8884d8"
                          label={({
                                      cx,
                                      cy,
                                      midAngle,
                                      innerRadius,
                                      outerRadius,
                                      value,
                                      name
                                  }) => {
                              const RADIAN = Math.PI / 180;
                              const radius = 25 + innerRadius + (outerRadius - innerRadius);
                              const x = cx + radius * Math.cos(-midAngle * RADIAN);
                              const y = cy + radius * Math.sin(-midAngle * RADIAN);

                              return value > 5 ? (
                                  <text
                                      x={x}
                                      y={y}
                                      fill="#000"
                                      textAnchor={x > cx ? 'start' : 'end'}
                                      dominantBaseline="central"
                                      className="text-xs"
                                  >
                                      {`${name} (${value.toFixed(1)}%)`}
                                  </text>
                              ) : null;
                          }}
                      >
                          {pieChartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                          ))}
                      </Pie>
                      <Tooltip
                          content={({active, payload}) => {
                              if (active && payload && payload.length) {
                                  return (
                                      <div className="bg-white p-2 border rounded shadow-lg">
                                          <p className="text-sm font-semibold">{`Grade ${payload[0].name}`}</p>
                                          <p className="text-sm text-gray-600">
                                              {`${payload[0].value.toFixed(2)}%`}
                                          </p>
                                      </div>
                                  );
                              }
                              return null;
                          }}
                      />
                  </PieChart>
              </ResponsiveContainer>
          </div>

          <div className="mb-4 flex flex-wrap gap-4 justify-between items-center">
              <div className="flex flex-wrap gap-2">
                  {metrics.map(metric => (
                      <button
                          key={metric.key}
                          onClick={() => setActiveMetric(metric.key)}
                          className={`px-3 py-1 rounded-full text-sm ${
                              activeMetric === metric.key
                                  ? 'bg-blue-600 text-white'
                                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                          }`}
                      >
                          {metric.name}
                      </button>
                  ))}
              </div>
              <div className="flex gap-2 items-center">
                  <span className="text-sm text-gray-600">Show:</span>
                  <select
                      value={displayCount}
                      onChange={(e) => setDisplayCount(Number(e.target.value))}
                      className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8 appearance-none w-24"
                      style={{
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 0.5rem center',
                          backgroundSize: '1.5em 1.5em'
                      }}
                  >
                      <option value={8}>Top 8</option>
                      <option value={12}>Top 12</option>
                      <option value={15}>Top 15</option>
                  </select>
              </div>
          </div>

          <ResponsiveContainer width="100%" height={400}>
              <BarChart
                  data={barChartData}
                  margin={{top: 20, right: 30, left: 40, bottom: 20}}
              onClick={(data) => {
                if (data && data.activePayload) {
                  setSelectedGrade(data.activePayload[0].payload.pack_grade);
                }
              }}
          >
            <XAxis
                dataKey="pack_grade"
                interval={0}
                tick={{fontSize: 12}}
                label={{
                    value: 'Pack Grades',
                    position: 'bottom',
                    offset: 0,
                    style: {textAnchor: 'middle'}
                }}
            />
            <YAxis/>
            <Tooltip content={<CustomTooltip/>}/>
            <Legend wrapperStyle={{
                paddingTop: "20px"
            }}/>
            <Bar
                dataKey={activeMetric}
                name={metrics.find(m => m.key === activeMetric)?.name}
                fill={metrics.find(m => m.key === activeMetric)?.color}
                radius={[4, 4, 0, 0]}
                cursor="pointer"
            />
          </BarChart>
        </ResponsiveContainer>

        {/* Monthly Trends Section */}
        {selectedGrade && processedTrends.length > 0 && (
            <div className="mt-8">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  Monthly Trends - Pack Grade {selectedGrade}
                </h3>
                <button
                    onClick={() => setSelectedGrade(null)}
                    className="text-sm text-gray-600 hover:text-gray-800"
                >
                  ✕ Close
                </button>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    data={processedTrends}
                    margin={{top: 20, right: 30, left: 40, bottom: 20}}
                >
                  <XAxis dataKey="date"/>
                  <YAxis yAxisId="left"/>
                  <YAxis yAxisId="right" orientation="right"/>
                  <Tooltip content={<TrendTooltip/>}/>
                  <Legend/>
                  <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="net_amount"
                      name="Net Amount (Lacs)"
                      stroke="#2563eb"
                      dot={false}
                  />
                  <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="total_quantity"
                      name="Quantity"
                      stroke="#16a34a"
                      dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
        )}
      </div>
  );
};

export default PackGradesChart;