import React, {useState} from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {formatLargeNumber} from "../../utils/functions";

const DeadStockChart = ({ data = [] }) => {
  const [activeMetric, setActiveMetric] = useState('stock_value');
  const [branchFilter, setBranchFilter] = useState('all');

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-white rounded-lg">
        <p className="text-gray-500">No data to show for the given date range</p>
      </div>
    );
  }

  // Calculate stock value for each item
  const enrichedData = data.map(item => ({
    ...item,
    stock_value: item.stock * item.basic_rate
  }));

  const metrics = [
    {
      key: 'stock_value',
      name: 'Stock Value',
      color: '#2563eb',
      format: (value) => `₹${value.toLocaleString()}`
    },
    {
      key: 'stock',
      name: 'Stock Units',
      color: '#7c3aed',
      format: (value) => value.toFixed(2)
    },
    {
      key: 'days_no_sale',
      name: 'Days Without Sale',
      color: '#dc2626',
      format: (value) => `${value} days`
    }
  ];

  // Get unique branches
  const branches = ['all', ...new Set(data.map(item => item.branch_name))];

  // Process and filter data
  const processedData = [...enrichedData]
    .filter(item => branchFilter === 'all' || item.branch_name === branchFilter)
    .sort((a, b) => b[activeMetric] - a[activeMetric])
    .slice(0, 10)
    .map(item => ({
      ...item,
      item_name: item.item_name.length > 20
        ? `${item.item_name.slice(0, 20)}...`
        : item.item_name,
      full_name: item.item_name
    }));

  // Calculate summary statistics
  const summaryStats = {
    totalStockValue: enrichedData.reduce((sum, item) => sum + (item.stock * item.basic_rate), 0),
    totalStock: enrichedData.reduce((sum, item) => sum + item.stock, 0),
    averageDaysNoSale: Math.round(enrichedData.reduce((sum, item) => sum + item.days_no_sale, 0) / enrichedData.length)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const itemData = processedData.find(d => d.item_name === label);
      if (!itemData) return null;

      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-semibold text-gray-800 mb-2">{itemData.full_name}</p>
          <div className="space-y-1">
            <p className="text-sm text-gray-600">Branch: {itemData.branch_name}</p>
            {metrics.map(metric => (
              <p
                key={metric.key}
                className="text-sm"
                style={{ color: metric.color }}
              >
                {metric.name}: {metric.format(itemData[metric.key])}
              </p>
            ))}
            <div className="border-t border-gray-200 mt-2 pt-2">
              <p className="text-sm text-gray-600">Basic Rate: ₹{itemData.basic_rate.toFixed(2)}</p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-blue-800">Total Stock Value</h3>
          <p className="text-2xl font-bold text-blue-600 break-words">
            {formatLargeNumber(summaryStats.totalStockValue)}
          </p>
        </div>
        <div className="bg-purple-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-purple-800">Total Stock Units</h3>
          <p className="text-2xl font-bold text-purple-600 break-words">
            {summaryStats.totalStock.toFixed(2)}
          </p>
        </div>
        <div className="bg-red-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-red-800">Avg. Days No Sale</h3>
          <p className="text-2xl font-bold text-red-600">
            {summaryStats.averageDaysNoSale} days
          </p>
        </div>
      </div>

      <div className="mb-4 flex flex-wrap gap-4 justify-between items-center">
        <div className="flex flex-wrap gap-2">
          {metrics.map(metric => (
            <button
              key={metric.key}
              onClick={() => setActiveMetric(metric.key)}
              className={`px-3 py-1 rounded-full text-sm ${
                activeMetric === metric.key
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {metric.name}
            </button>
          ))}
        </div>
        <div className="flex gap-2 items-center">
          <span className="text-sm text-gray-600">Branch:</span>
          <select
            value={branchFilter}
            onChange={(e) => setBranchFilter(e.target.value)}
            className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-1 pl-2 pr-8 appearance-none w-32"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 0.5rem center',
              backgroundSize: '1.5em 1.5em'
            }}
          >
            {branches.map(branch => (
              <option key={branch} value={branch}>
                {branch === 'all' ? 'All Branches' : branch}
              </option>
            ))}
          </select>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
        >
          <XAxis
            dataKey="item_name"
            angle={-35}
            textAnchor="end"
            height={100}
            interval={0}
            tick={{ fontSize: 12 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={activeMetric}
            name={metrics.find(m => m.key === activeMetric)?.name}
            fill={metrics.find(m => m.key === activeMetric)?.color}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DeadStockChart;