import React from 'react';
import HeroBanner from './BrandLandingPage/HeroBanner';
import JaanchCopilot from './JaanchCopilot';

const MainContent = () => {
  return (
    <main className="bg-gray-900 text-gray-300">
      <HeroBanner />
      <JaanchCopilot />
    </main>
  );
};

export default MainContent;
