import React, {useEffect, useRef, useState} from 'react';
import {Calendar, ChevronDown, ChevronLeft, ChevronRight} from 'lucide-react';
import {useDispatch, useSelector} from 'react-redux';
import {setDateRange} from "../../redux/features/dateRangeSlice";

const RELATIVE_RANGES = [
  {label: 'Today', value: 'today'},
  {label: 'Yesterday', value: 'yesterday'},
  {label: 'Last 7 days', value: 'last7days'},
  {label: 'Last 30 days', value: 'last30days'},
  {label: 'This month', value: 'thisMonth'},
  {label: 'Last month', value: 'lastMonth'},
  {label: 'This quarter', value: 'thisQuarter'},
  {label: 'Last quarter', value: 'lastQuarter'},
  {label: 'This year', value: 'thisYear'},
  {label: 'Last year', value: 'lastYear'},
];


const calculateRelativeRange = (type) => {
  const today = new Date();
  const from = new Date();
  const to = new Date();

  switch (type) {
    case 'today':
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'yesterday':
      from.setDate(today.getDate() - 1);
      to.setDate(today.getDate() - 1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'last7days':
      from.setDate(today.getDate() - 6);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'last30days':
      from.setDate(today.getDate() - 29);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'thisMonth':
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'lastMonth':
      from.setMonth(today.getMonth() - 1, 1);
      to.setMonth(today.getMonth(), 0);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'thisQuarter':
      from.setMonth(Math.floor(today.getMonth() / 3) * 3, 1);
      to.setHours(23, 59, 59, 999);
      break;
    case 'lastQuarter':
      from.setMonth(Math.floor(today.getMonth() / 3) * 3 - 3, 1);
      to.setMonth(Math.floor(today.getMonth() / 3) * 3, 0);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'thisYear':
      from.setMonth(0, 1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case 'lastYear':
      from.setFullYear(today.getFullYear() - 1, 0, 1);
      to.setFullYear(today.getFullYear() - 1, 11, 31);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    default:
      break;
  }

  return {from: from.toISOString(), to: to.toISOString()};
};

const EnhancedDateRangePicker = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState('relative');
  const dropdownRef = useRef(null);
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const [tempDateRange, setTempDateRange] = useState({from: null, to: null});
  const dateRange = useSelector(state => state.dateRange);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setTempDateRange({from: null, to: null}); // Reset temp range when closing
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const goToPreviousMonth = () => {
    setDisplayedDate(new Date(displayedDate.getFullYear(), displayedDate.getMonth() - 1));
  };

  const goToNextMonth = () => {
    setDisplayedDate(new Date(displayedDate.getFullYear(), displayedDate.getMonth() + 1));
  };

  const handleRelativeRangeSelect = (range) => {
    const {from, to} = calculateRelativeRange(range.value);
    dispatch(setDateRange({
      from,
      to,
      label: range.label,
      isRelative: true,
      relativeValue: range.value
    }));
    setIsOpen(false);
  };

  const handleAbsoluteDateSelect = (date) => {
    setTempDateRange((prevRange) => {
      if (!prevRange.from || (prevRange.from && prevRange.to)) {
        return { from: date, to: null };
      } else {
        if (date < prevRange.from) {
          return { from: date, to: prevRange.from };
        } else {
          return { ...prevRange, to: date };
        }
      }
    });
  };

  const applyAbsoluteDateRange = () => {
    if (tempDateRange.from && tempDateRange.to) {
      const {from, to} = validateAndFixDateRange(
          tempDateRange.from.toISOString(),
          tempDateRange.to.toISOString()
      );
      dispatch(setDateRange({
        from: from,
        to: to,
        label: 'Custom Range',
        isRelative: false,
      }));
      setIsOpen(false);
      setTempDateRange({ from: null, to: null });
    }
  };

  const generateCalendarDays = (year, month) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];

    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }

    for (let day = 1; day <= lastDay.getDate(); day++) {
      days.push(new Date(year, month, day));
    }

    return days;
  };

  const formatDateRange = () => {
    const formatDate = (date) => {
      if (!date) return '';
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    };

    if (dateRange.label !== 'Custom Range') {
      return dateRange.label;
    }

    const range = dateRange.from && dateRange.to ? dateRange : tempDateRange;

    if (!range.from) {
      return 'Select start date';
    } else if (!range.to) {
      return `From ${formatDate(range.from)} - Select end date`;
    }

    return `${formatDate(range.from)} - ${formatDate(range.to)}`;
  };

  const validateAndFixDateRange = (from, to) => {
    if (new Date(from) > new Date(to)) {
      return { from: to, to: from };
    }
    return { from, to };
  };

  const renderCalendar = (date, index) => {
    const range = tempDateRange.from || tempDateRange.to ? tempDateRange : dateRange;

    const isSelected = date && (
      (range.from && date.getTime() === new Date(range.from).getTime()) ||
      (range.to && date.getTime() === new Date(range.to).getTime())
    );

    const isInRange = date && range.from && range.to && (
      date >= new Date(range.from) && date <= new Date(range.to)
    );

    return (
      <button
        key={index}
        onClick={() => date && handleAbsoluteDateSelect(date)}
        className={`
          p-1 text-sm rounded-md transition-colors
          ${!date ? 'invisible' : 'hover:bg-blue-50'}
          ${isSelected ? 'bg-blue-600 text-white' : ''}
          ${isInRange && !isSelected ? 'bg-blue-50 text-blue-600' : ''}
          ${!isSelected && !isInRange ? 'text-gray-700' : ''}
        `}
        disabled={!date}
      >
        {date?.getDate()}
      </button>
    );
  };

  return (
      <div className="relative" ref={dropdownRef}>
        <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center gap-3 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors min-w-[300px]"
        >
          <Calendar className="w-5 h-5"/>
          <span className="flex-grow text-left">{formatDateRange()}</span>
          <ChevronDown className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`}/>
        </button>

        {isOpen && (
            <div className="absolute mt-2 p-4 bg-white rounded-xl shadow-lg border border-gray-200 z-50 w-[650px]">
              <div className="flex gap-4 mb-4 border-b border-gray-200">
                <button
                    onClick={() => setView('relative')}
                    className={`pb-2 px-2 text-sm font-medium transition-colors relative ${
                        view === 'relative' ? 'text-blue-600' : 'text-gray-600 hover:text-gray-900'
                    }`}
                >
                  Relative Range
                  {view === 'relative' && (
                      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600"></div>
                  )}
                </button>
                <button
                    onClick={() => setView('absolute')}
                    className={`pb-2 px-2 text-sm font-medium transition-colors relative ${
                        view === 'absolute' ? 'text-blue-600' : 'text-gray-600 hover:text-gray-900'
                    }`}
                >
                  Absolute Range
                  {view === 'absolute' && (
                      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600"></div>
                  )}
                </button>
              </div>

              {view === 'relative' && (
                  <div className="grid grid-cols-2 gap-2">
                    {RELATIVE_RANGES.map((range) => (
                        <button
                            key={range.value}
                            onClick={() => handleRelativeRangeSelect(range)}
                            className={`
                    p-2 text-sm rounded-lg text-left transition-colors
                    ${dateRange.label === range.label
                                ? 'bg-blue-50 text-blue-600'
                                : 'text-gray-700 hover:bg-gray-50'
                            }
                  `}
                        >
                          {range.label}
                        </button>
                    ))}
                  </div>
              )}

              {view === 'absolute' && (
                  <div className="space-y-4">
                    <div className="flex gap-8">
                      {[0, 1].map((offset) => {
                        const monthDate = new Date(
                            displayedDate.getFullYear(),
                            displayedDate.getMonth() + offset
                        );
                        const days = generateCalendarDays(
                            monthDate.getFullYear(),
                            monthDate.getMonth()
                        );

                        return (
                            <div key={offset} className="flex-1">
                              <div className="mb-4 flex items-center justify-between">
                                <button
                                    onClick={goToPreviousMonth}
                                    className="p-1 hover:bg-gray-100 rounded-full"
                                    disabled={offset === 1}
                                >
                                  <ChevronLeft className={`w-4 h-4 ${offset === 1 ? 'invisible' : ''}`}/>
                                </button>
                                <span className="font-medium">
                          {monthDate.toLocaleString('default', {
                            month: 'long',
                            year: 'numeric'
                          })}
                        </span>
                                <button
                                    onClick={goToNextMonth}
                                    className="p-1 hover:bg-gray-100 rounded-full"
                                    disabled={offset === 0}
                                >
                                  <ChevronRight className={`w-4 h-4 ${offset === 0 ? 'invisible' : ''}`}/>
                                </button>
                              </div>
                              <div className="grid grid-cols-7 gap-1">
                                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                                    <div key={day} className="text-center text-xs text-gray-500 py-1">
                                      {day}
                                    </div>
                                ))}
                                {days.map((date, index) => renderCalendar(date, index))}
                              </div>
                            </div>
                        );
                      })}
                    </div>

                    <div className="flex justify-end pt-2 border-t border-gray-200">
                      <button
                          onClick={applyAbsoluteDateRange}
                          disabled={!tempDateRange.from || !tempDateRange.to}
                          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                      >
                        Apply Range
                      </button>
                    </div>
                  </div>
              )}
            </div>
        )}
      </div>
  );
};

export default EnhancedDateRangePicker;